


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
class MainHeaderSearch extends Vue {
  @Prop() opened: boolean;

  isLoading: boolean = false;
  query: string = '';
  typingTimer: number;
  userList: any[] = [];
  focusTimeout: number;

  @Watch('opened')
  onOpenedChange(opened: boolean) {
    clearTimeout(this.focusTimeout);
    if (!opened) return;
    this.focusTimeout = window.setTimeout(() => {
      const inputEl = this.$el.querySelector('input');
      if (inputEl) inputEl.focus();
    }, 200);
  }

  mounted() {
    document.addEventListener('click', this.onDocumentClick);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e: any) {
    if (!this.opened) return;
    const el = this.$el;
    let targetEl = e.target;
    let targetElIsInEl = targetEl === el;
    while (targetEl.parentNode && !targetElIsInEl) {
      targetEl = targetEl.parentNode;
      targetElIsInEl = targetEl === el;
    }
    if (!targetElIsInEl) {
      this.$emit('close');
    }
  }

  // @ts-ignore
  customLabel({ username, fullname }) {
    if (typeof fullname === 'undefined' || fullname === 'undefined') {
      return username;
    }
    return `${fullname} (${username})`;
  }

  onSelect(opt: any) {
    const loginInfo = this.$store.state.isLoggedIn ? 'Member' : 'Visitor';
    this.$ga.event('profilePage', 'clickOnSearchResult', loginInfo);
    window.location.href = `/user/${opt.username}`;
  }

  onBlur() {
    this.query = '';
    this.userList = [];
  }

  onInput(query: string) {
    clearTimeout(this.typingTimer);
    if (query) {
      if (query.length === 1 && this.query.length === 0) {
        const loginInfo = this.$store.state.isLoggedIn ? 'Member' : 'Visitor';
        this.$ga.event('profilePage', 'searchStart', loginInfo);
      }
      this.typingTimer = window.setTimeout(this.searchQuery, 500 * 1);
    } else {
      this.userList = [];
    }
    this.query = query;
  }

  searchQuery() {
    const self = this;
    self.isLoading = true;
    this.$store
      .dispatch('findUsers', self.query)
      .then((res: any) => {
        if (res.data && res.data.results) {
          self.userList = res.data.results;
        }
      })
      .catch(() => {})
      .then(() => {
        self.isLoading = false;
      });
  }
}
export default MainHeaderSearch;
