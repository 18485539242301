/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
// @ts-ignore
import { set as gaSet, event as gaEvent } from 'vue-analytics'; // eslint-disable-line
import { gitlab } from './gitlab';
import { privaterepo } from './privaterepo';
import { workexperience, WorkExperience } from './workexperience';
import { dummyProfile } from '../utils/dummy-profile';
import { responseError, responseErrorCode, responseErrorMessage } from '../utils/response-error';
import storage from '../utils/storage';
import { cannyIdentify } from '../utils/canny-identify';
import { md5 } from '../utils/md5';

const baseGatewayUrl = process.env.GATEWAY_BASE_PATH;

export interface Provider {
  provider: string;
  host: string;
  userName: string;
  displayMessage?: boolean;
}

export interface Badge {
  rank: number;
  location: string;
  visibility: string;
  language?: string;
  type?: string;
  version?: string;
}
export interface Badges {
  [type: string]: {
    [language: string]: Badge;
  };
}
export interface BadgeV2 {
  BadgeFamily: string;
  BadgeType: string;
  id: string;
  values: object;
  visibility: string;
  version?: string;
}

export interface Profile {
  username: string;
  fullName: string;
  developerTitle: string;
  totalScore: number;
  avatar: string;
  city: string;
  country: string;
  positionWorldWide: number;
  worldWideAll: number;
  badges: Badges;
  badgesV2: BadgeV2[];
  scoreBySkills: {
    [language: string]: {
      score: number;
      topXPercent: number;
      worldWideAll: number;
      continent: number;
      country: number;
      countryAll: number;
      region: number;
      city: number;
      cityAll: number;
      isHidden: boolean;
    };
  };
  invisibleSkills: string[];
  skillOrder: string[];
  ownProfile: boolean;
  updatedProfileAt: string;
  status: string;
  about: string;
  languages: {
    code: string;
    proficiency: string;
  }[];
  techStack: any[];
  workExperience: WorkExperience[];
  githubRepositoryCount: number;
  scoreByLibraries: {
    [library: string]: {
      score: number;
      topXPercent: number;
      worldWideAll: number;
      technologies: string[];
    };
  };
  scoreByTechnologies: {
    [technology: string]: {
      core: number;
    };
  };
  screenshot: string;
  firstName: string;
  lastName: string;
}
export interface Account {
  userId?: string;
  sessionId?: string;
  username: string;
  avatar: string;
  about: string;
  languages: {
    code: string;
    proficiency: string;
  }[];
  developerTitle: string;
  /* Job Form Part */
  contactEmail?: string;
  contactPhone?: string;
  remote?: string;
  readyToRelocate?: string;
  jobSpecializations?: string[];
  industries?: string[];
  countriesToWork?: string[];
  jobType?: string[];
  euWorkPermit?: string;
  companyBlacklist?: string[];
  companySize?: string[];
  salary?: {
    currency: string;
    minimum: string;
  };
  linkedIn: string;
  cv: string;
  techNotInterested: string[];
  jobNotificationFrequency: number;
  jobComment: string;
  jobSkills: any[];
  /* Job Form End */
  invisibleSkills: string[];
  country: string;
  city: string;
  jobsMatchingSkills: string[];
  email: string;
  registrationDate: string;
  hideProfile: boolean;
  stackoverflowlinked: boolean;
  gitlablinked: boolean;
  skillOrder?: string[];
  workExperience?: WorkExperience[];
  badges: Badges;
  refreshJobId?: any;
}

export const InviteStatus = {
  NONE: 'NONE',
  OVERLAY_MESSAGE: 'OVERLAY_MESSAGE',
  EMAIL_FORM: 'EMAIL_FORM',
  SENT: 'SENT',
};

export interface ProfilePageError {
  errorCode: string;
  errorMessage: string;
}

export interface RootState {
  sessionId: string | null;
  shouldShowPrivateView: boolean;
  account: Account;
  profile: Profile;
  topSkills: string[];
  invisibleSkills: string[];
  isLoggedIn: boolean;
  showInvite: boolean;
  showAllBadges: boolean;
  showFindJob: boolean;
  notifyOptions: string[];
  jobOffers: any;
  totalOffers: number;
  inviteStatus: string;
  route?: any;
  publicProfileSources?: any;
  privateProfileSources?: any;

  isRefreshing: boolean;
  refreshComplete: boolean;
  refreshCompleteMessage: string;
  showReloadButton: boolean;
  showRefreshNotification: boolean;
  refreshProfileRefreshing: boolean;
  refreshProfileProgressCallbacks: ((progress: number) => void)[];
  refreshProfileProgress: number;
  refreshProfileProgressPrevValue: number | null;
  refreshProfileProgressPrevTime: number;
  refreshProfileUpdateStarted: boolean;
  refreshProfileRetryCount: number;
  refreshProfileRetryLimit: number;
  providers: Provider[];
  hideLinkedin: boolean;
  needsProfileRefresh: boolean;
  errorOnProfilePage: ProfilePageError;
}

function getInitialState(): RootState {
  return {
    sessionId: null,
    shouldShowPrivateView: false,
    publicProfileSources: {},
    privateProfileSources: [],
    account: {
      username: '',
      avatar: '',
      about: '',
      languages: [],
      developerTitle: '',
      invisibleSkills: [] as any[],
      country: '',
      city: '',
      /* Job Form Part */
      contactEmail: '',
      contactPhone: '',
      remote: '',
      readyToRelocate: '',
      jobSpecializations: [],
      industries: [],
      countriesToWork: [],
      jobType: [],
      euWorkPermit: '',
      companyBlacklist: [],
      companySize: [],
      salary: {
        currency: '',
        minimum: '',
      },
      linkedIn: '',
      cv: '',
      techNotInterested: [],
      jobNotificationFrequency: 0,
      jobComment: '',
      jobSkills: [],
      /* Job Form Part End */
      jobsMatchingSkills: [],
      email: '',
      registrationDate: '',
      hideProfile: false,
      stackoverflowlinked: false,
      gitlablinked: false,
      badges: {
        worldwide: {},
        continent: {},
        country: {},
        region: {},
        city: {},
      },
    },
    // @ts-ignore
    profile: {
      ownProfile: false,
      skillOrder: [],
      githubRepositoryCount: 0,
    },

    isRefreshing: false,
    refreshComplete: false,
    refreshCompleteMessage: '',
    showReloadButton: true,
    showRefreshNotification: false,

    refreshProfileRefreshing: false,
    refreshProfileProgressCallbacks: [],
    refreshProfileProgress: 0,
    refreshProfileProgressPrevValue: null,
    refreshProfileProgressPrevTime: Date.now(),
    refreshProfileUpdateStarted: false,
    refreshProfileRetryCount: 0,
    refreshProfileRetryLimit: 40, // We are making 2 requests in 1 second, so that means we have a 20 second timeout

    topSkills: [],
    invisibleSkills: [] as any[],
    isLoggedIn: false,
    showInvite: false,
    showAllBadges: false,
    showFindJob: false,
    notifyOptions: ['Once a week', 'Twice a week', 'Once a month', 'Twice a month'],
    jobOffers: [],
    totalOffers: 0,
    inviteStatus: InviteStatus.NONE,
    providers: [],
    hideLinkedin: false,
    needsProfileRefresh: false,
    errorOnProfilePage: {
      errorCode: '',
      errorMessage: '',
    },
  };
}

function getTopSkills({ profile, limit } = {} as { profile: Profile; limit: number }) {
  if (!profile.scoreBySkills) return {};
  return Object.keys(profile.scoreBySkills || {})
    .sort((s1, s2) => {
      if (profile.scoreBySkills[s1].score < profile.scoreBySkills[s2].score) {
        return -1;
      }
      if (profile.scoreBySkills[s1].score > profile.scoreBySkills[s2].score) {
        return 1;
      }
      return 0;
    })
    .reverse()
    .slice(0, limit);
}

export const strict = false;

export const state = () => getInitialState();

export const mutations: MutationTree<RootState> = {
  setSessionId(state, sessionId) {
    state.sessionId = sessionId;
  },
  setShouldShowPrivateView(state, value) {
    state.shouldShowPrivateView = value;
  },
  setPublicProfileSources(state, sources) {
    state.publicProfileSources = sources;
  },
  setPrivateProfileSources(state, sources) {
    state.privateProfileSources = sources;
  },
  errorOnProfileLoad(state, err: AxiosError) {
    state.errorOnProfilePage.errorCode = responseErrorCode(err);
    state.errorOnProfilePage.errorMessage = responseErrorMessage(err);
  },
  assignAccount(state, account) {
    if (!account.about) account.about = '';
    if (!account.languages) account.languages = [];
    if (!account.jobComment) account.jobComment = '';
    if (!account.jobSpecializations) account.jobSpecializations = [];
    if (!account.industries) account.industries = [];
    if (!account.readyToRelocate) account.readyToRelocate = '';
    if (!account.countriesToWork) account.countriesToWork = [];
    if (!account.remote) account.remote = '';
    if (!account.jobType) account.jobType = [];
    if (!account.salary) account.salary = { minimum: '', currency: '' };
    if (!account.euWorkPermit) account.euWorkPermit = '';
    if (!account.cv) account.cv = '';
    if (!account.companyBlacklist) account.companyBlacklist = [];
    if (!account.companySize) account.companySize = [];

    state.account = Object.assign(state.account, account);
    state.isLoggedIn = true;

    // @ts-ignore
    if (process.client && window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        Username: state.account.username,
        Email: state.account.email,
        RegistrationDate: state.account.registrationDate,
        InterestedInJobs: `|${state.account.jobsMatchingSkills.join('|')}|`,
      });
      // @ts-ignore
      window.dataLayer.push({ event: 'account-loaded' });
    }
    // @ts-ignore
    if (process.client) {
      cannyIdentify(state.account);
    }
    if (this.$sentry && state.account.userId) {
      // @ts-ignore
      this.$sentry.setUser({
        id: state.account.userId,
        username: state.account.username,
        email: state.account.email,
      });
    }
  },
  logOutSoft(state) {
    Cookies.remove('sessionId');
    state.sessionId = null;
  },
  logOut(state) {
    Cookies.remove('sessionId');
    state.sessionId = null;
    Object.assign(state, getInitialState());
    if (this.$sentry) {
      try {
        // @ts-ignore
        this.$sentry.configureScope((scope) => scope.clear());
      } catch (err) {
        // error
      }
    }
  },
  setProfile(state, profile) {
    state.profile = profile;
    if (state.profile && !state.profile.languages) {
      state.profile.languages = [];
    }
    if (state.profile && !state.profile.techStack) {
      state.profile.techStack = [];
    }
    if (!profile.badges || !state.account || !state.account.badges) return;
    Object.keys(profile.badges.worldwide || {}).forEach((key) => {
      state.account.badges.worldwide[key] = profile.badges.worldwide[key];
    });
    Object.keys(profile.badges.continent || {}).forEach((key) => {
      state.account.badges.continent[key] = profile.badges.continent[key];
    });
    Object.keys(profile.badges.country || {}).forEach((key) => {
      state.account.badges.country[key] = profile.badges.country[key];
    });
    Object.keys(profile.badges.region || {}).forEach((key) => {
      state.account.badges.region[key] = profile.badges.region[key];
    });
    Object.keys(profile.badges.city || {}).forEach((key) => {
      state.account.badges.city[key] = profile.badges.city[key];
    });
  },
  setTopSkills(state, topSkills) {
    state.topSkills = topSkills;
  },
  setInvisibleSkills(state, invisibleSkills) {
    state.account.invisibleSkills = [...invisibleSkills];
    state.invisibleSkills = [...invisibleSkills];
  },
  addJobsMatchingSkill(state, skill) {
    state.account.jobsMatchingSkills.push(skill);
  },
  removeJobsMatchingSkill(state, skill) {
    const skillIndex = state.account.jobsMatchingSkills.indexOf(skill);
    if (skillIndex > -1) {
      state.account.jobsMatchingSkills.splice(skillIndex, 1);
    }
  },
  setCV(state, { name }) {
    state.account.cv = name;
  },
  updateAccount(state, account) {
    state.account = Object.assign(state.account, account);
  },
  setBadgeVisibility(state, { badge, visibility }) {
    if (badge.version === 'v1') {
      state.account.badges[badge.type][badge.language].visibility = visibility;
    } else if (badge.version === 'v2') {
      badge.visibility = visibility;
      state.profile.badgesV2.filter((b) => b.id === badge.id)[0].visibility = visibility;
    }
  },
  setAvatar(state, avatar) {
    state.profile.avatar = avatar;
    state.account.avatar = avatar;
  },
  setAbout(state, { about, languages }) {
    state.account.about = about;
    state.account.languages = languages;
    state.profile.about = about;
    state.profile.languages = languages;
  },
  setHideLinkedin(state, hideLinkedin) {
    state.hideLinkedin = hideLinkedin;
  },
};

export const getters: GetterTree<RootState, RootState> = {
  sessionId: (state) => {
    let sessionId = state.sessionId;
    if (!sessionId) {
      sessionId = Cookies.get('sessionId');
    }
    return sessionId;
  },
  shouldShowPrivateView(state) {
    return state.shouldShowPrivateView;
  },
  publicProfileSources(state) {
    return state.publicProfileSources;
  },
  privateProfileSources(state) {
    return state.privateProfileSources;
  },
  getAllBadges: (state) => {
    const badges: (Badge | BadgeV2)[] = [];
    Object.keys(state.profile.badges || {}).forEach((badgeslocation) => {
      Object.keys(state.profile.badges[badgeslocation]).forEach((language) => {
        const badge = state.profile.badges[badgeslocation][language];
        badges.push({
          rank: badge.rank,
          location: badge.location,
          language,
          type: badgeslocation,
          visibility: badge.visibility,
          version: 'v1',
        });
      });
    });
    const badgesV2 = (state.profile.badgesV2 || [])
      .map((badge) => ({
        ...badge,
        version: 'v2',
      }))
      .filter((badge) => badge.BadgeFamily === 'Seniority' || badge.BadgeFamily === 'Streak');
    return [...badgesV2, ...badges];
  },
  shouldDisableHighlightedBadge(state, getters) {
    return getters.getAllBadges.filter((item: Badge) => item.visibility === 'highlighted').length === 3;
  },
  profile(state) {
    return state.profile;
  },
  account(state) {
    return state.account;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  // eslint-disable-next-line
  nuxtServerInit({ commit, state }, { req }) {
    if (req.headers.cookie && req.headers.cookie.includes('sessionId=')) {
      const sessionId = req.headers.cookie.split('sessionId=')[1].split(';')[0];
      if (sessionId) commit('setSessionId', sessionId);
      const hideLinkedin = process.env.HIDE_LINKEDIN === 'true' || false;
      commit('setHideLinkedin', hideLinkedin);
    }
  },
  registerWithEmail(ctx, { email, password } = {}) {
    const affiliate = Cookies.get('aff') || '';
    return axios
      .post('/user/user/Register', {
        email,
        password,
        affiliate,
        UTM: {
          source: Cookies.get('utm_source') || '',
          medium: Cookies.get('utm_medium') || '',
          campaign: Cookies.get('utm_campaign') || '',
        },
      })
      .then((res) => {
        Cookies.remove('aff', { domain: 'codersrank.io' });
        return res.data.sessionId;
      });
  },

  tryToGetAccount({ commit, getters, state, dispatch }) {
    const sessionId = getters.sessionId;
    if (sessionId) {
      return this.$axios
        .post('/app/candidate/GetAccount', {
          sessionId,
        })
        .then((r) => {
          commit('assignAccount', r.data);
          // @ts-ignore
          if (process.client) {
            if (state.account.refreshJobId) {
              state.isRefreshing = true;
              dispatch('updateRefreshProfileProgress');
            }
          }
        })
        .catch((err) => {
          const code = responseErrorCode(err);
          if (code === 401) {
            commit('logOutSoft');
          } else {
            commit('errorOnProfileLoad', err);
            this.$sentry.captureException(err);
          }
        });
    }
    return Promise.resolve();
  },
  startGitHubAuth() {
    axios
      .post('/user/user/GetGitHubAuthURL', {})
      .then((r) => {
        const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
        window.open(
          `${r.data.url}&redirect_uri=${process.env.PROFILE_PAGE_PATH}/github_callback/${r.data.jobID}`,
          'oauthpopup',
          strWindowFeatures,
        );
        // @ts-ignore
        const receiveMessage = (event) => {
          // For security
          if (event.origin !== process.env.PROFILE_PAGE_PATH) {
            return;
          }
          const { data } = event;
          this.$router.push(data.redirectTo);
        };
        window.addEventListener('message', (event) => receiveMessage(event), false);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  startStackoverflowAuth({ getters }) {
    const sessionId = getters.sessionId;
    axios
      .post('/user/user/GetStackoverflowAuthURL', {
        sessionId,
      })
      .then((r) => {
        window.location.href = r.data.url + r.data.jobID;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  authenticateStackoverflow({ getters }, { code, state, jobId } = {}) {
    const sessionId = getters.sessionId;
    return axios.post('/user/user/GetStackoverflowAccessToken', {
      sessionId,
      code,
      state,
      jobID: jobId,
    });
  },
  async linkGithubToAccount({ commit }, { sessionID, code, state, jobID } = {}) {
    const affiliate = Cookies.get('aff') || '';
    const r = await axios.post(`${baseGatewayUrl}/user/LinkGithubToAccount`, {
      sessionID,
      code,
      state,
      jobID,
      affiliate,
      utm: {
        source: Cookies.get('utm_source') || '',
        medium: Cookies.get('utm_medium') || '',
        campaign: Cookies.get('utm_campaign') || '',
      },
    });
    const { username } = r.data;
    const { shouldAskForLocation } = r.data;
    const { email } = r.data;
    if (shouldAskForLocation) {
      gaEvent('profilePage', 'register', 'With GitHub');
    } else {
      gaEvent('profilePage', 'login', 'With GitHub');
    }
    Cookies.set('sessionId', r.data.sessionID);
    commit('setSessionId', r.data.sessionID);

    Cookies.remove('aff', { domain: 'codersrank.io' });

    return { username, shouldAskForLocation, email };
  },
  async loginWithGitHub({ commit }, { code, state, jobID } = {}) {
    const affiliate = Cookies.get('aff') || '';
    const r = await axios.post('/user/user/LoginWithGitHub', {
      code,
      state,
      jobID,
      affiliate,
      UTM: {
        source: Cookies.get('utm_source') || '',
        medium: Cookies.get('utm_medium') || '',
        campaign: Cookies.get('utm_campaign') || '',
      },
    });
    const { username } = r.data;
    const { shouldAskForLocation } = r.data;
    const { email } = r.data;
    if (shouldAskForLocation) {
      gaEvent('profilePage', 'register', 'With GitHub');
    } else {
      gaEvent('profilePage', 'login', 'With GitHub');
    }
    Cookies.set('sessionId', r.data.sessionId);
    Cookies.remove('aff', { domain: 'codersrank.io' });
    commit('setSessionId', r.data.sessionId);

    return { username, shouldAskForLocation, email };
  },
  updateEmail({ getters }, { email }) {
    return axios
      .post(`${baseGatewayUrl}/user/UpdateEmail`, {
        sessionID: getters.sessionId,
        Email: email,
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },
  async checkGitHubUser(ctx, { username } = {}) {
    const r = await axios.post('/app/candidate/CheckGithubUser', {
      username,
    });

    return {
      country: r.data.country,
      city: r.data.city,
    };
  },

  async getPublicProfileSources({ commit }, username: string) {
    commit('setPublicProfileSources', {});
    const response = await axios.get(`${baseGatewayUrl}/candidate/sources/${username}`);
    commit('setPublicProfileSources', response.data);
  },

  // eslint-disable-next-line
  async getPrivateProfileSources({ getters, commit }) {
    commit('setPrivateProfileSources', []);
    const response = await axios.post(`${baseGatewayUrl}/candidate/privaterepo/GetFileChangesRepos`, {
      sessionId: getters.sessionId,
    });
    commit('setPrivateProfileSources', response.data.repositories || []);
  },

  logOutSoft({ commit }) {
    commit('logOutSoft');
    return Promise.resolve();
  },

  async logOut({ getters, commit }) {
    if (!getters.sessionId) {
      commit('logOut');
      return Promise.resolve();
    }
    return axios
      .post(`${baseGatewayUrl}/user/logout`, { sessionID: getters.sessionId })
      .then(() => {
        commit('logOut');
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },

  processGitlab({ dispatch, getters }, { code, state, router } = {}) {
    return axios
      .post(`${baseGatewayUrl}/oauth/gitlab.com/callback`, {
        sessionID: getters.sessionId,
        code,
        state,
      })
      .then(() => {
        try {
          storage.set('gitlabConnected', 'true');
        } catch (err) {
          // no local storage available
        }
        dispatch(gitlab.actions.StartPollingData);
        router.push('/onboarding/create');
      })
      .catch(() => {
        router.push({ name: 'login' });
      });
  },

  getUserFromGitHub(ctx, username: string) {
    return axios.get(`https://api.github.com/users/${username}`);
  },

  // eslint-disable-next-line consistent-return
  async getProfile({ state, commit, dispatch, getters }, { username, type }) {
    const sessionId = getters.sessionId;
    const url = type === 'user' ? '/app/candidate/GetScore' : '/app/candidate/GetScoreByHash';

    let r;
    try {
      r = await this.$axios.post(url, {
        sessionId,
        username,
      });
    } catch (err) {
      commit('errorOnProfileLoad', err);
      this.$sentry.captureException(err);
      return Promise.reject();
    }

    if (r.data.status === 'not_found') {
      try {
        const ghr = await dispatch('getUserFromGitHub', username);
        r.data.username = ghr.data.login;
        r.data.country = ghr.data.location;
        r.data.avatar = ghr.data.avatar_url;
      } catch (err) {
        // no such github user
      }
    }
    if (r.data.status !== 'registered') {
      Object.assign(r.data, dummyProfile);
      state.publicProfileSources = {};
    }
    if (sessionId) {
      Cookies.set('sessionId', sessionId, { expires: 14 });
    }
    if (r.data.error && r.data.error === 'Missing data for candidate.') {
      return Promise.reject(Error('missing_data'));
    }
    commit(
      'setTopSkills',
      getTopSkills({
        profile: r.data,
        limit: 3,
      }),
    );
    if (r.data.invisibleSkills) {
      commit('setInvisibleSkills', r.data.invisibleSkills);
    }
    if (r.data.workExperience) {
      commit(workexperience.mutations.SetWorkExperience, r.data.workExperience);
    } else {
      commit(workexperience.mutations.SetWorkExperience, []);
    }
    commit('setProfile', r.data);
    // @ts-ignore
    if (state.account.username === username && process.client && window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({ TotalScore: r.data.totalScore });
    }
    if (process.client) {
      gaSet('metric1', r.data.totalScore);
    }
    if (getters.sessionId && process.client) {
      this.$axios
        .post('/user/user/GetUserFromSession', {
          sessionId: getters.sessionId,
        })
        .then((res) => {
          if (res.data) {
            gaSet('dimension1', res.data.userId);
          }
        })
        .catch((err) => {
          const code = responseErrorCode(err);
          if (!code || code !== 401) {
            this.$sentry.captureException(err);
          }
        });
    }
  },

  mergePrivateRepo({ dispatch, getters }, { token, reponame }) {
    const url = `${baseGatewayUrl}/candidate/privaterepo/merge`;
    return axios
      .post(url, {
        sessionId: getters.sessionId,
        token,
        reponame,
      })
      .then(() => dispatch(privaterepo.actions.QueryRepoList))
      .catch((error) => {
        return Promise.reject(responseError(error));
      });
  },

  mergeMultiPrivateRepo({ dispatch, getters }, { token }) {
    const url = `${baseGatewayUrl}/candidate/privaterepo/merge/multi`;
    return axios
      .post(url, {
        sessionId: getters.sessionId,
        token,
      })
      .then(() => dispatch(privaterepo.actions.QueryRepoList))
      .catch((error) => {
        return Promise.reject(responseError(error));
      });
  },

  inviteMyFriend({ state, getters }, { username, email }) {
    return axios
      .post('/app/candidate/Invite', {
        sessionId: getters.sessionId,
        name: username,
        email,
      })
      .then(() => {
        gaEvent('profilePage', 'inviteUser', `${state.account.username} - invited: ${username}`);
      });
  },

  updateSkillOrder({ state, getters }, skills: string[]) {
    state.profile.skillOrder = skills;
    return axios.post(`${baseGatewayUrl}/account/skill/order`, {
      sessionId: getters.sessionId,
      skills,
    });
  },

  removeInvisibleSkill({ commit, state, getters }, skill: string) {
    const invisibleSkills = [...(state.account.invisibleSkills || [])];
    if (invisibleSkills.includes(skill)) {
      invisibleSkills.splice(invisibleSkills.indexOf(skill), 1);
    }

    // sort skillOrder as [...visible, currentSkill, ...invisible]
    const skillOrder: string[] = [...(state.account.skillOrder || [])]
      .filter((s) => s !== skill)
      .sort((a, b) => {
        if (state.invisibleSkills.includes(b) && !state.invisibleSkills.includes(a)) return -1;
        return 1;
      });
    const indexOfFirstInvisibleSkill = skillOrder.indexOf(
      skillOrder.filter((s) => state.invisibleSkills.includes(s))[0],
    );
    skillOrder.splice(indexOfFirstInvisibleSkill, 0, skill);

    state.account.skillOrder = skillOrder;
    state.profile.skillOrder = skillOrder;
    commit('setInvisibleSkills', invisibleSkills);

    return axios.post(`${baseGatewayUrl}/account/skill/visibility`, {
      sessionId: getters.sessionId,
      skill,
      visible: true,
    });
  },

  addInvisibleSkill({ commit, state, getters }, skill: string) {
    const invisibleSkills = [...(state.account.invisibleSkills || [])];
    if (!invisibleSkills.includes(skill)) {
      invisibleSkills.push(skill);
    }

    // Move invisibe skill to the end
    const skillOrder: string[] = [...(state.account.skillOrder || [])];
    if (skillOrder.includes(skill)) skillOrder.splice(skillOrder.indexOf(skill), 1);
    skillOrder.push(skill);

    state.account.skillOrder = skillOrder;
    state.profile.skillOrder = skillOrder;
    commit('setInvisibleSkills', invisibleSkills);

    return axios.post(`${baseGatewayUrl}/account/skill/visibility`, {
      sessionId: getters.sessionId,
      skill,
      visible: false,
    });
  },

  addJobInterest(ctx, skill: string) {
    gaEvent('profilePage', 'jobInterestAdd', skill);
  },

  updateJobInterest({ dispatch, getters }, { interest, days }) {
    return axios
      .post(`${baseGatewayUrl}/account/job/interest`, {
        sessionId: getters.sessionId,
        interest,
        days,
      })
      .then(() => {
        dispatch('tryToGetAccount');
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  removeJobInterest(ctx, skill: string) {
    gaEvent('profilePage', 'jobInterestRemove', skill);
  },

  updateProfileLocation({ state, commit, getters }, { country, city } = {}) {
    return axios
      .post(`${baseGatewayUrl}/account/location`, {
        sessionId: getters.sessionId,
        country,
        city,
      })
      .then(() => {
        const newAccount = {
          ...state.account,
          country,
          city,
        };
        commit('updateAccount', newAccount);
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },

  updateProfileLinkedIn({ state, commit, getters }, { linkedIn } = {}) {
    return axios
      .post(`${baseGatewayUrl}/account/linkedin`, {
        sessionId: getters.sessionId,
        linkedIn,
      })
      .then(() => {
        const newAccount = {
          ...state.account,
          linkedIn,
        };
        commit('updateAccount', newAccount);
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return Promise.reject(err);
      });
  },

  getLinkedInExperience({ getters }) {
    return axios
      .get(`${baseGatewayUrl}/account/linkedin/scrape`, {
        headers: {
          'X-SESSION-ID': getters.sessionId,
        },
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return Promise.reject(err);
      });
  },

  updateProfileSpecialization({ state, commit, getters }, { specialization } = {}) {
    return axios
      .post(`${baseGatewayUrl}/account/specialization`, {
        sessionId: getters.sessionId,
        specialization,
      })
      .then(() => {
        const newAccount = {
          ...state.account,
          developerTitle: specialization,
        };
        commit('updateAccount', newAccount);
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },

  updateProfileVisibility({ state, commit, getters }, { isHidden } = {}) {
    return axios
      .post(`${baseGatewayUrl}/account/hide`, {
        sessionId: getters.sessionId,
        hidden: isHidden,
      })
      .then(() => {
        const newAccount = {
          ...state.account,
          hideProfile: isHidden,
        };
        commit('updateAccount', newAccount);
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },

  updateProfile({ state, dispatch, getters }, profileData) {
    const {
      email,
      firstName,
      lastName,
      country,
      city,
      gender,
      DOB,
      contactEmail,
      contactPhone,
      linkedIn,
      website,
      twitter,
    } = profileData;
    return axios
      .post(`${baseGatewayUrl}/account/profile`, {
        sessionId: getters.sessionId,
        email,
        firstName,
        lastName,
        country,
        city,
        gender,
        DOB,
        contactEmail,
        contactPhone,
        linkedIn,
        website,
        twitter,
      })
      .then(() => {
        dispatch('tryToGetAccount');
        dispatch('getProfile', { username: state.account.username, type: 'user' });
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },

  getOnboardingProfile({ getters }) {
    return axios
      .get(`${baseGatewayUrl}/onboarding/profile`, {
        headers: {
          'X-SESSION-ID': getters.sessionId,
        },
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },

  updateOnboardingProfile({ getters }, profileData) {
    return axios
      .post(`${baseGatewayUrl}/onboarding/profile`, {
        sessionId: getters.sessionId,
        ...profileData,
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return Promise.reject(err);
      });
  },

  updateCv({ getters, commit }, { fileName, fileData } = {}) {
    const formData = new FormData();
    formData.append('cv', fileData);
    formData.set('sessionId', getters.sessionId);
    formData.set('name', fileName);
    return axios
      .post(`${baseGatewayUrl}/account/job/cv`, formData)
      .then(() => {
        commit('setCv', { name: fileName, data: fileData });
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },

  removeCv({ getters, commit }) {
    return axios
      .delete(`${baseGatewayUrl}/account/job/cv`, {
        headers: {
          'X-SESSION-ID': getters.sessionId,
        },
      })
      .then(() => {
        commit('setCv', { name: '', data: null });
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },

  sendApplication({ getters }, data = {}) {
    console.log(data);
    return axios
      .post(`${baseGatewayUrl}/user/JobBoard`, {
        sessionId: getters.sessionId,
        ...data,
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return err;
      });
  },

  updateJobPreferences({ getters }, data = {}) {
    return axios.post(`${baseGatewayUrl}/account/job`, {
      sessionId: getters.sessionId,
      ...data,
    });
  },

  updateJobTypes({ getters }, data = {}) {
    return axios.post(
      `${baseGatewayUrl}/account/job/type`,
      {
        ...data,
      },
      {
        headers: {
          'X-SESSION-ID': getters.sessionId,
        },
      },
    );
  },

  updateTechStack({ getters }, skills = []) {
    return axios.post(
      `${baseGatewayUrl}/account/techstack`,
      {
        skills,
      },
      {
        headers: {
          'X-SESSION-ID': getters.sessionId,
        },
      },
    );
  },

  updateAbout({ commit, getters }, { about, languages }) {
    return axios
      .post(`${baseGatewayUrl}/account/about`, {
        sessionId: getters.sessionId,
        about,
        languages,
      })
      .then(() => {
        commit('setAbout', { about, languages });
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return Promise.reject(err);
      });
  },

  changePassword(ctx, { email, oldPassword, newPassword } = {}) {
    return axios.post('/user/user/ChangePassword', {
      email,
      oldPassword,
      newPassword,
    });
  },

  findUsers(ctx, query: string) {
    return axios.post('/search/user/ByName', {
      query,
      n: 20,
    });
  },

  moveBadge({ commit, getters }, { badge, visibility, notify, notifyError } = {}) {
    const { version, visibility: oldVisibility, language, id } = badge;
    commit('setBadgeVisibility', { badge, visibility });

    const requestBody = {
      sessionId: getters.sessionId,
      version,
      id,
      language,
      visibility,
    };
    return axios
      .post(`${baseGatewayUrl}/account/badges`, requestBody)
      .then(notify)
      .catch(() => {
        commit('setBadgeVisibility', { badge, visibility: oldVisibility });
        notifyError();
      });
  },

  confirmBadgeEarned({ getters }) {
    const sessionId = getters.sessionId;
    this.$axios.get(`${baseGatewayUrl}/candidate/badgesEarned/confirmed`, {
      headers: {
        'X-SESSION-ID': sessionId,
      },
    });
  },

  async getTechnologies() {
    const response = await this.$axios.post('/scorestorage/export/GetTechnologies', {});
    return response.data.technologies.sort();
  },

  async getCountries() {
    const response = await this.$axios.post('/scorestorage/export/GetCountries', {});
    return response.data.countries.sort();
  },

  async getCities(ctx, country: string) {
    const response = await this.$axios.post('/scorestorage/export/GetCities', {
      country,
    });
    if (response.data.cities) {
      return response.data.cities.sort();
    }
    return [];
  },

  getSkillSuggestion(ctx, query: string) {
    return axios
      .post(`${baseGatewayUrl}/account/skillsuggest`, { prefix: query })
      .then((response) => {
        return Promise.resolve(response.data || []);
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return Promise.resolve([]);
      });
  },

  getRelatedSkillSuggestion(ctx, skills: string[]) {
    return axios
      .get(`${baseGatewayUrl}/technologies/recommendations`, { params: { technologies: skills.join('|') } })
      .then((response) => {
        return Promise.resolve(response.data || []);
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return Promise.resolve([]);
      });
  },

  getCompanySuggestion(ctx, companyName: string) {
    return axios
      .post(`${baseGatewayUrl}/leaderboard/company/suggestion`, { companyName })
      .then((response) => {
        if (response.data.companies) {
          return Promise.resolve({
            companies: response.data.companies,
          });
        }
        return Promise.resolve({
          companies: null,
        });
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return Promise.resolve({
          companies: null,
        });
      });
  },

  getLocationSuggestion(ctx, query: string) {
    return axios
      .get(`${baseGatewayUrl}/location/suggestion/${query}`)
      .then((response) => {
        if (response && response.data && response.data.suggestions) {
          return Promise.resolve({
            suggestions: response.data.suggestions,
          });
        }
        return Promise.resolve({
          suggestions: null,
        });
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return Promise.resolve({
          suggestions: null,
        });
      });
  },

  getSchoolSuggestion(ctx, schoolName: string) {
    return axios
      .post(`${baseGatewayUrl}/leaderboard/education/suggestion`, { schoolName })
      .then((response) => {
        if (response.data.schools) {
          return Promise.resolve({
            schools: response.data.schools,
          });
        }
        return Promise.resolve({
          schools: null,
        });
      })
      .catch((err) => {
        this.$sentry.captureException(err);
        return Promise.resolve({
          schools: null,
        });
      });
  },

  onRefreshProfileProgressUpdate({ state }) {
    state.refreshProfileProgressCallbacks.forEach((cb) => {
      cb(state.refreshProfileProgress);
    });
  },

  addRefreshProfileProgressCallback({ state }, callback) {
    state.refreshProfileProgressCallbacks.push(callback);
  },

  updateRefreshProfileProgress({ state, dispatch, getters }, bySelf?: boolean) {
    if (state.refreshProfileUpdateStarted && !bySelf) return;
    state.showReloadButton = true;
    state.refreshComplete = false;
    state.refreshProfileUpdateStarted = true;
    axios
      .post('app/candidate/GetSaveGithubUserProgress', { sessionId: getters.sessionId })
      .then((response: any) => {
        // @ts-ignore
        if (!response.data.progress && state.refreshProfileProgressPrevValue > 0) {
          state.refreshProfileRefreshing = false;
          state.refreshProfileUpdateStarted = false;
          state.isRefreshing = false;
          state.refreshComplete = true;
          dispatch('onRefreshProfileProgressUpdate');
          state.refreshProfileProgress = 0;
          state.refreshProfileProgressPrevValue = null;
          return;
        }
        // Reset retry counter to zero with every successful request
        state.refreshProfileRetryCount = 0;

        const p = response.data.progress || 0;
        state.refreshProfileProgress = p;

        const now = Date.now();
        if (p !== state.refreshProfileProgressPrevValue || state.refreshProfileProgressPrevTime - now > 20000) {
          gaEvent('profilePage', 'profileRefreshProgress', p);
          state.refreshProfileProgressPrevValue = p;
          state.refreshProfileProgressPrevTime = now;
        }

        if (p === 100) {
          state.refreshProfileRefreshing = false;
          state.refreshProfileUpdateStarted = false;
          state.refreshCompleteMessage = 'Your profile has been updated successfully.';
          state.isRefreshing = false;
          state.refreshComplete = true;
          state.showReloadButton = true;
          dispatch('onRefreshProfileProgressUpdate');
          state.refreshProfileProgress = 0;
          state.refreshProfileProgressPrevValue = null;
        } else {
          setTimeout(() => dispatch('updateRefreshProfileProgress', true), 1500);
          dispatch('onRefreshProfileProgressUpdate');
        }
      })
      .catch((e: any) => {
        if (state.refreshProfileRetryCount > state.refreshProfileRetryLimit) {
          state.refreshProfileRetryCount = 0;
          state.refreshProfileRefreshing = false;
          state.refreshProfileUpdateStarted = false;
          state.refreshCompleteMessage = 'Error while profile refresh. Please try again later.';
          state.isRefreshing = false;
          state.refreshComplete = false;
          state.refreshProfileProgress = 0;
          state.refreshProfileProgressPrevValue = null;
          state.showReloadButton = false;
          state.showRefreshNotification = true;

          gaEvent('profilePage', 'ProfileGeneration', 'ProfileGeneration');
          console.log('error getting save progress:', e);
        } else {
          state.refreshProfileRetryCount += 1;
          setTimeout(() => dispatch('updateRefreshProfileProgress', true), 1500);
          dispatch('onRefreshProfileProgressUpdate');
        }
      });
  },

  getRefreshProfileStatus({ state, dispatch }, forceUpdate: boolean) {
    axios
      .post('app/candidate/SaveGithubUser', {
        username: state.profile.username,
        forceUpdate,
      })
      .then((response: any) => {
        if (response.data.status === 'ok') {
          state.refreshProfileRefreshing = true;
          state.isRefreshing = true;
          dispatch('updateRefreshProfileProgress');
        }
      })
      .catch((e: any) => {
        state.refreshProfileRefreshing = false;
        console.log('error saving user:', e);
      });
  },

  refreshProfile({ dispatch, state, getters }, forceUpdate: boolean) {
    if (state.refreshProfileRefreshing) {
      return;
    }
    state.refreshProfileRefreshing = true;
    storage.set('needsProfileRefresh', false);
    axios
      .post('app/candidate/RefreshProfile', {
        sessionId: getters.sessionId,
      })
      .then(() => {
        dispatch('getRefreshProfileStatus', forceUpdate);
      })
      .catch((e: any) => {
        state.refreshProfileRefreshing = false;
        console.log('error refresh profile', e);
      });
  },

  fetchProviders({ state, getters }) {
    return axios
      .get(`${baseGatewayUrl}/user/providers`, {
        headers: {
          'X-SESSION-ID': getters.sessionId,
        },
      })
      .then((res) => {
        state.providers = res.data;
      });
  },

  extractJobSkills({ getters }) {
    return axios.get(`${baseGatewayUrl}/account/jobskills/extract`, {
      headers: {
        'X-SESSION-ID': getters.sessionId,
      },
    });
  },

  trackOnboardingStep({ getters }, { step_name, step_number, data = {} } = {}) {
    return axios.post('https://grpcgateway.codersrank.io/metrics/exp010', {
      step_name,
      step_number,
      sessMD5: md5(getters.sessionId || ''),
      data,
    });
  },
};
