





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
class CrProgress extends Vue {
  @Prop({ type: String, default: 'span' }) tag: string;

  @Prop({ type: Boolean }) xl: boolean;
  @Prop({ type: Boolean }) lg: boolean;
  @Prop({ type: Boolean }) md: boolean;
  @Prop({ type: Boolean }) sm: boolean;
  @Prop({ type: Boolean }) xs: boolean;

  @Prop({ type: [Number, String] }) label: string | number;
  @Prop({ type: Number, default: 0 }) value: number;
  @Prop({ type: Number, default: 0 }) min: number;
  @Prop({ type: Number, default: 1 }) max: number;
  @Prop({ type: Boolean }) animated: boolean;
  @Prop({ type: Boolean }) indeterminate: boolean;

  @Prop() color: string;

  get size() {
    const { xl, lg, md, sm, xs } = this;

    if (xl) return 'xl';
    if (lg) return 'lg';
    if (md) return 'md';
    if (sm) return 'sm';
    if (xs) return 'xs';

    return 'xs';
  }

  get progress(): number {
    const { value, max, min } = this;
    const progress = ((value - min) / (max - min)) * 100;
    return Math.min(Math.max(progress, 0), 100);
  }
}

export default CrProgress;
