












import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import notification from '../utils/notification';

@Component
class Notification extends Vue {
  @Prop({ default: '' }) text: string;
  @Prop({ default: 5000 }) hold: number;
  @Prop({ default: false }) closeOtherNotifications: boolean;
  @Prop({ default: true }) closeButton: boolean;
  @Prop({ default: false }) fixed: boolean;

  notification: any;

  mounted() {
    const el = this.$refs.el as HTMLElement;
    this.notification = notification({
      el,
      hold: this.hold,
      closeOtherNotifications: this.closeOtherNotifications,
      closeButton: this.closeButton,
      onOpen: this.onOpen,
      onClose: this.onClose,
    });
  }

  beforeDestroy() {
    if (this.notification && this.notification.close) {
      this.notification.close();
      this.notification = null;
      delete this.notification;
    }
  }

  onOpen() {
    this.$emit('open');
  }

  onClose() {
    this.$emit('close');
  }
}
export default Notification;
