
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class CrButton extends Vue {
  @Prop({ default: 'button' }) tag: string;
  @Prop({ type: Boolean }) lg: boolean;
  @Prop({ type: Boolean }) md: boolean;
  @Prop({ type: Boolean }) sm: boolean;
  @Prop({ type: Boolean }) xs: boolean;

  @Prop({ type: Boolean }) block: boolean;
  @Prop({ type: Boolean }) round: boolean;

  @Prop({ type: Boolean }) outline: boolean;
  @Prop({ type: Boolean }) light: boolean;
  @Prop({ type: Boolean }) lite: boolean;
  @Prop({ type: Boolean }) text: boolean;
  @Prop({ type: Boolean }) disabled: boolean;
  @Prop({ type: Boolean }) uppercase: boolean;
  @Prop({ type: Boolean }) thin: boolean;

  /** Value of `<button>`'s `type` attribute, e.g. "submit" or "button"  */
  @Prop({ type: String }) type: string;
  @Prop({ type: String }) color: string;
  /** Vue route to navigate to, e.g. `:to="{name: 'login'}` */
  @Prop({ type: [String, Object] }) to: any;
  @Prop({ type: String }) href: string;
  @Prop({ type: String }) target: string;
  @Prop({ type: String }) rel: string;

  /** Enables button with spinner that should be toggled with `loading` prop */
  @Prop({ type: Boolean }) spinner: boolean;
  @Prop({ type: Boolean }) loading: boolean;

  @Prop({ type: String }) ariaLabel: string;

  get isLink() {
    const { tag, href, target } = this;
    if (tag === 'a' || href || target) return true;
    return false;
  }

  get size() {
    const { lg, md, sm, xs } = this;

    if (lg) return 'lg';
    if (md) return 'md';
    if (sm) return 'sm';
    if (xs) return 'xs';

    return 'md';
  }

  get style() {
    const { outline, disabled, light, lite, text } = this;
    if (disabled) return 'disabled';
    if (outline) return 'outline';
    if (light) return 'lite';
    if (lite) return 'lite';
    if (text) return 'text';
    return 'fill';
  }

  onClick(e: any) {
    if (this.spinner && this.loading) {
      return;
    }
    if (this.to) {
      this.$router.push(this.to);
    }
    this.$emit('click', e);
  }
}

export default CrButton;
