








import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import tooltip from '../utils/tooltip';

@Component
class Tooltip extends Vue {
  @Prop({ type: String }) target: string;
  /** Container where to place/move tooltip element on open */
  @Prop({ type: String, default: '#app' }) container: string;
  @Prop({ type: String, default: 'hover' }) triggers: string;
  @Prop({ type: String, default: 'auto' }) position: string;
  @Prop({ type: Boolean }) disabled: boolean;

  tooltip: any = null;

  @Watch('disabled')
  onDisableChange() {
    if (!this.tooltip) return;
    if (this.disabled) this.tooltip.disable();
    else {
      setTimeout(() => {
        this.tooltip.enable();
      });
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.tooltip = tooltip({
        el: this.$refs.el as HTMLElement,
        target: this.target,
        container: this.container,
        triggers: this.triggers,
        position: this.position,
        disabled: this.disabled,
        onOpen: () => this.$emit('open'),
        onClose: () => this.$emit('close'),
      });
    });
  }

  updated() {
    if (this.tooltip && this.tooltip.update) {
      this.tooltip.update({
        triggers: this.triggers,
        position: this.position,
      });
    }
  }

  beforeDestroy() {
    if (this.tooltip && this.tooltip.close) {
      this.tooltip.close();
      this.tooltip.destroy();
      this.tooltip = null;
      delete this.tooltip;
    }
  }

  open() {
    if (this.tooltip) this.tooltip.open();
  }

  close() {
    if (this.tooltip) this.tooltip.close();
  }
}

export default Tooltip;
