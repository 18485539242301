
























import Vue from 'vue';
import Component from 'vue-class-component';

@Component
class RefreshNotification extends Vue {
  progress: number = 0;

  mounted() {
    this.$store.dispatch('addRefreshProfileProgressCallback', (progress: number) => {
      this.progress = progress;
    });
  }

  reloadProfilePage() {
    this.$store.state.isRefreshing = false;
    this.$store.state.refreshComplete = false;
    this.$store.state.showRefreshNotification = false;
    window.location.href = `/user/${this.$store.state.account.username}`;
  }

  closeNotification() {
    this.$store.state.isRefreshing = false;
    this.$store.state.refreshComplete = false;
    this.$store.state.showRefreshNotification = false;
  }
}
export default RefreshNotification;
