const storage = {
  set(key: string, value: any) {
    try {
      localStorage.setItem(key, value);
    } catch (err) {
      // no localStorage available
    }
  },
  get(key: string) {
    try {
      return localStorage.getItem(key);
    } catch (err) {
      // no localStorage available
    }
    return null;
  },
  remove(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      // no localStorage available
    }
  },
};

export default storage;
