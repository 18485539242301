























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
class CrSpinner extends Vue {
  @Prop({ type: String, default: 'span' }) tag: string;

  @Prop({ type: Boolean }) xl: boolean;
  @Prop({ type: Boolean }) lg: boolean;
  @Prop({ type: Boolean }) md: boolean;
  @Prop({ type: Boolean }) sm: boolean;
  @Prop({ type: Boolean }) xs: boolean;

  @Prop() color: string;

  get size() {
    const { xl, lg, md, sm, xs } = this;

    if (xl) return 'xl';
    if (lg) return 'lg';
    if (md) return 'md';
    if (sm) return 'sm';
    if (xs) return 'xs';

    return 'md';
  }
}

export default CrSpinner;
