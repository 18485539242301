
























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import tooltip from '../utils/tooltip';

@Component
class CrPieChart extends Vue {
  @Prop({ type: Array, default: () => [] }) labels: any[];
  @Prop({ type: Array, default: () => [] }) dataset: number[];
  @Prop({ type: Array, default: () => [] }) colors: string[];
  @Prop({ type: Boolean, default: false }) tooltip: boolean;
  @Prop({ type: Number, default: 320 }) size: number;
  @Prop({ type: Function }) formatTooltip: (data: any) => string;
  @Prop({ type: Function }) formatTooltipLabel: (label: any) => string;

  currentIndex: number | null = null;
  tooltipInstance: any = null;
  tooltipTimeout: any;

  get summValue(): number {
    let summ = 0;
    this.dataset.forEach((value) => {
      summ += value;
    });
    return summ;
  }

  get paths() {
    const { dataset, colors, labels, size, summValue } = this;

    const paths: any = [];

    let cumulativePercent = 0;

    function getCoordinatesForPercent(percent: number) {
      const x = Math.cos(2 * Math.PI * percent) * (size / 3);
      const y = Math.sin(2 * Math.PI * percent) * (size / 3);
      return [x, y];
    }

    dataset.forEach((value, index) => {
      const percent = value / summValue;

      const [startX, startY] = getCoordinatesForPercent(cumulativePercent);
      cumulativePercent += percent;
      const [endX, endY] = getCoordinatesForPercent(cumulativePercent);
      const largeArcFlag = percent > 0.5 ? 1 : 0;
      const points = [
        `M ${startX} ${startY}`, // Move
        `A ${size / 3} ${size / 3} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
        'L 0 0', // Line
      ].join(' ');

      paths.push({
        points,
        label: labels[index],
        color: colors[index],
      });
    });
    return paths;
  }

  @Watch('currentIndex')
  onCurrentIndexChange() {
    if (this.tooltipTimeout) return;
    clearTimeout(this.tooltipTimeout);
    this.tooltipTimeout = setTimeout(() => {
      this.tooltipTimeout = false;
      if (!this.tooltip) return;
      if (this.currentIndex !== null && !this.tooltipInstance) {
        // @ts-ignore
        this.tooltipInstance = tooltip({
          position: 'auto',
          container: this.$el as HTMLElement,
          triggers: 'manual',
          target: this.$el.querySelector(`path[data-index="${this.currentIndex}"]`) as HTMLElement,
          targetCenter: true,
          text: this.formatTooltipText(),
          className: 'cr-pie-chart-tooltip',
        });
        this.tooltipInstance.open();
        return;
      }
      if (!this.tooltipInstance) return;
      if (this.currentIndex !== null) {
        this.tooltipInstance.update({
          target: this.$el.querySelector(`path[data-index="${this.currentIndex}"]`) as HTMLElement,
          text: this.formatTooltipText(),
        });
        this.tooltipInstance.open();
      } else {
        this.tooltipInstance.close();
      }
    });
  }

  formatTooltipText() {
    const currentIndex = this.currentIndex;
    if (currentIndex === null) return '';
    const value = this.dataset[currentIndex];
    const label = this.labels[currentIndex];
    const color = this.colors[currentIndex];
    const percent = (value / this.summValue) * 100;

    if (this.formatTooltip) {
      return this.formatTooltip({
        index: currentIndex,
        value,
        label,
        color,
        percent,
      });
    }

    function round(v: number): number {
      // @ts-ignore
      if (parseInt(v, 10) === v) return v;
      return Math.round(v * 100) / 100;
    }

    const tootlipText = this.formatTooltipLabel
      ? this.formatTooltipLabel({
          index: currentIndex,
          value,
          label,
          color,
          percent,
        })
      : `${label}: ${round(value)} (${round(percent)}%)`;

    return `
      <div class="cr-pie-chart-tooltip-label">
        <span class="cr-pie-chart-tooltip-color" style="background-color: ${color};"></span> ${tootlipText}
      </div>
    `;
  }

  beforeDestroy() {
    if (this.tooltipInstance) {
      this.tooltipInstance.destroy();
    }
  }
}

export default CrPieChart;
