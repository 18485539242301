


















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LibraryIcon from './LibraryIcon.vue';
import notification from '../../ui/utils/notification';

@Component({
  components: {
    LibraryIcon,
  },
})
class Badge extends Vue {
  @Prop({ default: false }) leaderboardLink: boolean;
  @Prop() badge: any;
  @Prop() editable: boolean;
  @Prop() category: 'highlighted' | 'public' | 'hidden';

  dropdownOpened = false;

  move(newvisibility: string) {
    const notify = () => {
      notification({
        text: 'You have successfully updated badge visibility.',
      });
    };
    const notifyError = () => {
      notification({
        text: 'Could not update badge. Please try again later.',
      });
    };
    this.$store.dispatch('moveBadge', {
      badge: this.badge,
      visibility: newvisibility,
      notify,
      notifyError,
    });
  }

  get disableHighlighted() {
    return this.$store.getters.shouldDisableHighlightedBadge;
  }

  get leaderboardQuery() {
    const q: any = {
      technology: this.badge.language,
    };
    const { type } = this.badge;
    const { country, city } = this.$store.state.profile;
    if (type === 'country' || type === 'city') {
      q.country = country;
    }
    if (type === 'city') {
      q.city = city;
    }
    return q;
  }

  mounted() {
    if (this.editable) {
      document.addEventListener('click', this.onDocumentClick);
    }
  }

  beforeDestroy() {
    if (this.editable) {
      document.removeEventListener('click', this.onDocumentClick);
    }
  }

  onDocumentClick(e: any) {
    const targetEl = e.target;
    const { dropdownEl, dropdownButtonEl } = this.$refs;
    if (
      (dropdownEl as HTMLElement).contains(targetEl) ||
      (dropdownButtonEl as HTMLElement).contains(targetEl) ||
      dropdownEl === targetEl ||
      dropdownButtonEl === targetEl
    )
      return;
    this.dropdownOpened = false;
  }

  onLeaderboardClick() {
    this.$store.state.showAllBadges = false;
    setTimeout(() => {
      this.$router.push({
        name: 'developerLeaderboard',
        query: this.leaderboardQuery,
      });
    });
  }

  seniorityLabel(label: string) {
    return label
      .split('')
      .map((char, index) => {
        if (char === char.toUpperCase() && index !== 0) return `\n${char}`;
        return char;
      })
      .join('');
  }

  streakLabel(label: string) {
    return label
      .split('')
      .map((char, index) => {
        if (char === char.toUpperCase() && index !== 0) return ` ${char}`;
        return char;
      })
      .join('');
  }
}
export default Badge;
