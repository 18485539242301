/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';

enum Mutations {
  SetMessages = '[messages] SetMessages',
  SetMessagesLoading = '[messages] SetMessagesLoading',
  SetThreadsLoading = '[messages] SetThreadsLoading',
  SetThreadRejected = '[messages] SetThreadRejected',
  SetThread = '[messages] SetThread',
  SetThreads = '[messages] SetThreads',
  SetThreadsFilter = '[messages] SetThreadsFilter',
  SetThreadsCount = '[messages] SetThreadsCount',
}

enum Actions {
  SetThread = '[messages] set current thread',
  GetThreads = '[messages] get threads list',
  SetThreadsFilter = '[messages] set threads filter',
  GetThreadsCount = '[messages] get threads count',

  GetMessages = '[messages] get messages for thread',
  SendMessage = '[messages] send message',

  AcceptThread = '[messages] accept thread',
  RejectThread = '[messages] reject thread',
}

enum Getters {}

export const messages = {
  actions: prefixStoreEnums('messages', Actions),
  getters: prefixStoreEnums('messages', Getters),
};

export enum MessagesThreadStatus {
  Interested = 'interested',
  Archived = 'archived',
  Pending = 'pending',
  Rejected = 'rejected',
}

export interface Position {
  id?: string;
  company?: {
    logo?: string;
    name?: string;
    facebook?: string;
    linkedin?: string;
    twitter?: string;
    website?: string;
  };
  job_title?: string;
  location?: string;
  salary?: {
    range?: string;
    currency?: string;
    period?: string;
  };
  skills?: {
    name: string;
    experience?: number;
  }[];
  employment_type?: string;
  responsibilities?: string;
  notes?: string;
}

export interface MessagesThread {
  id: string;
  seen: boolean;
  candidate: {
    avatar: string;
    first_name: string;
    id: string;
    last_name: string;
    username: string;
  };
  created_at: string;
  last_message: MessagesMessage;
  position: Position;
  recruiter: {
    avatar: string;
    company: string;
    id: string;
    name: string;
    linkedin: string;
  };
  reminders: string[];
  // status
  status: string;
}

export interface MessagesMessage {
  seen: string;
  message: {
    body: string;
    sender: 'candidate' | 'recruiter';
  } | null;
  sent_at: string;
  system_message: {
    interested?: {
      body: string;
    };
    archived?: {
      body: string;
    };
    pending?: {
      body: string;
    };
    rejected?: {
      body: string;
    };
    type: 'interested' | 'archived' | 'pending' | 'rejected';
  } | null;
  type: 'message' | 'system_message';
}

export interface ThreadsCount {
  interested: number;
  archived: number;
  pending: number;
  rejected: number;
  unseen: number;
}

export interface MessagesState {
  messagesLoading: boolean;
  threadsLoading: boolean;
  currentThread: MessagesThread | null;
  messages: MessagesMessage[];
  threads: MessagesThread[] | null;
  threadsFilter: string;
  threadsCount: ThreadsCount;
}

const initialState: MessagesState = {
  messagesLoading: false,
  threadsLoading: false,
  currentThread: null,
  messages: [],
  threads: null,
  threadsFilter: 'all',
  threadsCount: {
    interested: 0,
    archived: 0,
    pending: 0,
    rejected: 0,
    unseen: 0,
  },
};

export const state = () => ({ ...initialState });

export const mutations: MutationTree<MessagesState> = {
  [Mutations.SetMessages](state, items) {
    state.messages = items;
  },
  [Mutations.SetMessagesLoading](state, loading) {
    state.messagesLoading = loading;
  },
  [Mutations.SetThreadsLoading](state, loading) {
    state.threadsLoading = loading;
  },
  [Mutations.SetThreadRejected](state, thread) {
    thread.status = MessagesThreadStatus.Rejected;
    state.threads = [...(state.threads || [])];
  },
  [Mutations.SetThread](state, thread) {
    state.currentThread = thread;
  },
  [Mutations.SetThreads](state, threads = []) {
    state.threads = threads;
  },
  [Mutations.SetThreadsFilter](state, status) {
    state.threadsFilter = status;
  },
  [Mutations.SetThreadsCount](state, data) {
    state.threadsCount = data;
  },
};

export const actions: ActionTree<MessagesState, RootState> = {
  [Actions.AcceptThread]({ rootGetters, dispatch, state }, { thread }) {
    return axios
      .put(
        `${(axios.defaults as any).gatewayBase}/communication/thread/${thread.id}/respond`,
        {
          reason: '',
          response: 'interested',
        },
        {
          headers: {
            'X-SESSION-ID': rootGetters.sessionId,
          },
        },
      )
      .then(() => {
        dispatch(Actions.GetThreads);
        dispatch(Actions.GetThreadsCount);
        dispatch(Actions.GetMessages, state.currentThread);
      });
  },
  [Actions.RejectThread]({ commit, rootGetters, dispatch, state }, { thread, reason }) {
    commit(Mutations.SetThreadRejected, thread);
    return axios
      .put(
        `${(axios.defaults as any).gatewayBase}/communication/thread/${thread.id}/respond`,
        {
          reason,
          response: 'rejected',
        },
        {
          headers: {
            'X-SESSION-ID': rootGetters.sessionId,
          },
        },
      )
      .then(() => {
        dispatch(Actions.GetThreads);
        dispatch(Actions.GetThreadsCount);
        dispatch(Actions.GetMessages, state.currentThread);
      });
  },
  [Actions.SetThread]({ commit, dispatch, state }, thread) {
    if (thread && state.currentThread && thread.id === state.currentThread.id) {
      return Promise.resolve();
    }
    commit(Mutations.SetThread, thread);
    commit(Mutations.SetMessages, []);
    return dispatch(Actions.GetMessages, thread).then(() => dispatch(Actions.GetThreads));
  },
  [Actions.GetThreads]({ commit, state, rootGetters }) {
    commit(Mutations.SetThreadsLoading, true);
    return axios
      .post(
        `${(axios.defaults as any).gatewayBase}/communication/threads`,
        {
          filter: {
            response: state.threadsFilter === 'all' ? ['interested', 'rejected', 'pending'] : [state.threadsFilter],
          },
          offset: 0,
          size: 100,
        },
        {
          headers: {
            'X-SESSION-ID': rootGetters.sessionId,
          },
        },
      )
      .then((res) => {
        commit(Mutations.SetThreads, res.data.threads || []);
        commit(Mutations.SetThreadsLoading, false);
      })
      .catch((err) => {
        commit(Mutations.SetThreadsLoading, false);
        return Promise.reject(err);
      });
  },
  [Actions.SetThreadsFilter]({ commit, dispatch }, { status } = {}) {
    commit(Mutations.SetThreadsFilter, status);
    dispatch(Actions.GetThreads);
  },
  [Actions.GetMessages]({ commit, rootGetters }, thread) {
    if (!thread) return Promise.resolve();
    commit(Mutations.SetMessagesLoading, true);
    return axios
      .get(`${(axios.defaults as any).gatewayBase}/communication/thread/${thread.id}/messages`, {
        headers: {
          'X-SESSION-ID': rootGetters.sessionId,
        },
      })
      .then((res) => {
        commit(Mutations.SetMessagesLoading, false);
        commit(Mutations.SetMessages, res.data);
      })
      .catch((err) => {
        commit(Mutations.SetMessagesLoading, false);
        return Promise.reject(err);
      });
  },

  [Actions.SendMessage]({ state, rootGetters, dispatch }, { message = '' } = {}) {
    const currentThread = state.currentThread;
    if (!currentThread) return Promise.reject();

    return axios
      .post(
        `${(axios.defaults as any).gatewayBase}/communication/send_message/`,
        {
          thread_id: currentThread.id,
          message,
        },
        {
          headers: {
            'X-SESSION-ID': rootGetters.sessionId,
          },
        },
      )
      .then(() => {
        dispatch(Actions.GetThreads);
        dispatch(Actions.GetMessages, currentThread);
      });
  },

  [Actions.GetThreadsCount]({ commit, rootGetters }) {
    return axios
      .get(`${(axios.defaults as any).gatewayBase}/communication/thread_count`, {
        headers: {
          'X-SESSION-ID': rootGetters.sessionId,
        },
      })
      .then((res) => {
        commit(Mutations.SetThreadsCount, res.data);
      });
  },
};

export const getters: GetterTree<MessagesState, RootState> = {};
