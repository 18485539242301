






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
// @ts-ignore
import twemoji from 'twemoji';

@Component({})
class CrEmojiFlag extends Vue {
  @Prop() name: string;
  @Prop({ default: 'i' }) tag: string;

  onClick(e: any) {
    this.$emit('click', e);
  }

  mounted() {
    twemoji.parse(this.$el, {
      folder: 'svg',
      ext: '.svg',
    });
  }
}
export default CrEmojiFlag;
