export const dummyProfile = {
  scoreBySkills: {
    C: {
      score: 9.918634,
      worldWide: 314661,
      worldWideAll: 1019347,
      continent: 43325,
      country: 469,
      countryAll: 1560,
      region: 5,
      city: 4,
      cityAll: 6,
      topXPercent: 12,
    },
    'C++': {
      score: 12.6012745,
      worldWide: 331201,
      worldWideAll: 1157323,
      continent: 44902,
      country: 543,
      countryAll: 1843,
      region: 4,
      city: 3,
      cityAll: 5,
      topXPercent: 10,
    },
    Dart: {
      score: 3.8729358,
      worldWide: 6320,
      worldWideAll: 23339,
      continent: 1107,
      country: 27,
      countryAll: 59,
      region: 2,
      city: 2,
      cityAll: 1,
      topXPercent: 12,
    },
    Go: {
      score: 2.5371842,
      worldWide: 313835,
      worldWideAll: 480510,
      continent: 46881,
      country: 452,
      countryAll: 818,
      region: 4,
      city: 4,
      cityAll: 5,
      topXPercent: 99,
    },
    Java: {
      score: 5.605249,
      worldWide: 933847,
      worldWideAll: 1978473,
      continent: 104392,
      country: 1395,
      countryAll: 2469,
      region: 5,
      city: 3,
      cityAll: 5,
      topXPercent: 44,
    },
    JavaScript: {
      score: 4.4508686,
      worldWide: 1517158,
      worldWideAll: 2609053,
      continent: 195091,
      country: 2106,
      countryAll: 3427,
      region: 10,
      city: 8,
      cityAll: 11,
      topXPercent: 12,
    },
    'Jupyter Notebook': {
      score: 0.80595696,
      worldWide: 268586,
      worldWideAll: 270501,
      continent: 30912,
      country: 258,
      countryAll: 258,
      region: 2,
      city: 2,
      cityAll: 1,
      topXPercent: 55,
    },
    'Objective-C': {
      score: 1.2934983,
      worldWide: 379621,
      worldWideAll: 508141,
      continent: 43682,
      country: 392,
      countryAll: 579,
      region: 5,
      city: 4,
      cityAll: 4,
      topXPercent: 56,
    },
    PHP: {
      score: 0.7647706,
      worldWide: 1065200,
      worldWideAll: 1098126,
      continent: 152800,
      country: 1760,
      countryAll: 1774,
      region: 14,
      city: 13,
      cityAll: 12,
      topXPercent: 89,
    },
    Shell: {
      score: 1.4828928,
      worldWide: 587238,
      worldWideAll: 785012,
      continent: 103052,
      country: 1128,
      countryAll: 1488,
      region: 8,
      city: 7,
      cityAll: 8,
      topXPercent: 14,
    },
    Swift: {
      score: 142.12434,
      worldWide: 12573,
      worldWideAll: 293636,
      continent: 2031,
      country: 16,
      countryAll: 357,
      region: 2,
      city: 2,
      cityAll: 4,
      topXPercent: 1,
    },
    'Vim script': {
      score: 2.5752132,
      worldWide: 86395,
      worldWideAll: 144190,
      continent: 13955,
      country: 133,
      countryAll: 249,
      region: 3,
      city: 3,
      cityAll: 2,
      topXPercent: 0.1,
    },
  },
  skillOrder: ['Swift', 'C++', 'Dart', 'Java', 'JavaScript', 'PHP'],
  invisibleSkills: [],
  updatedProfileAt: 'Aug 24, 2018',
};
