import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a9f5562 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _319536dc = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _1699a8da = () => interopDefault(import('../pages/account/edit-badges.vue' /* webpackChunkName: "pages/account/edit-badges" */))
const _7e9cfd54 = () => interopDefault(import('../pages/account/edit-profile.vue' /* webpackChunkName: "pages/account/edit-profile" */))
const _dbc85de2 = () => interopDefault(import('../pages/account/integrations.vue' /* webpackChunkName: "pages/account/integrations" */))
const _0b28f0b0 = () => interopDefault(import('../pages/account/job-preferences.vue' /* webpackChunkName: "pages/account/job-preferences" */))
const _66794bf1 = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _2f5b9e24 = () => interopDefault(import('../pages/gitlab_callback/index.vue' /* webpackChunkName: "pages/gitlab_callback/index" */))
const _58087e1a = () => interopDefault(import('../pages/invite/index.vue' /* webpackChunkName: "pages/invite/index" */))
const _b4c811ea = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5af3f2d8 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _73e6b9c6 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _41ba7c1e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _73347a40 = () => interopDefault(import('../pages/repo.vue' /* webpackChunkName: "pages/repo" */))
const _f182f4c4 = () => interopDefault(import('../pages/year-in-review-2020/index.vue' /* webpackChunkName: "pages/year-in-review-2020/index" */))
const _7faadde6 = () => interopDefault(import('../pages/leaderboard/company.vue' /* webpackChunkName: "pages/leaderboard/company" */))
const _067b8fda = () => interopDefault(import('../pages/leaderboard/developer.vue' /* webpackChunkName: "pages/leaderboard/developer" */))
const _92521e90 = () => interopDefault(import('../pages/leaderboard/education.vue' /* webpackChunkName: "pages/leaderboard/education" */))
const _2a200744 = () => interopDefault(import('../pages/onboarding/connect.vue' /* webpackChunkName: "pages/onboarding/connect" */))
const _bba816dc = () => interopDefault(import('../pages/onboarding/create.vue' /* webpackChunkName: "pages/onboarding/create" */))
const _59346b98 = () => interopDefault(import('../pages/onboarding/done.vue' /* webpackChunkName: "pages/onboarding/done" */))
const _1e9a36c4 = () => interopDefault(import('../pages/onboarding/info.vue' /* webpackChunkName: "pages/onboarding/info" */))
const _514afdde = () => interopDefault(import('../pages/onboarding/jobPreferences.vue' /* webpackChunkName: "pages/onboarding/jobPreferences" */))
const _22ba491e = () => interopDefault(import('../pages/registration/job-interests.vue' /* webpackChunkName: "pages/registration/job-interests" */))
const _0e048e16 = () => interopDefault(import('../pages/year-in-review-2020/facts/index.vue' /* webpackChunkName: "pages/year-in-review-2020/facts/index" */))
const _9da3556c = () => interopDefault(import('../pages/year-in-review-2020/global/index.vue' /* webpackChunkName: "pages/year-in-review-2020/global/index" */))
const _dfc7c2bc = () => interopDefault(import('../pages/year-in-review-2020/country/_code/index.vue' /* webpackChunkName: "pages/year-in-review-2020/country/_code/index" */))
const _31edeffb = () => interopDefault(import('../pages/year-in-review-2020/user/_userHash/index.vue' /* webpackChunkName: "pages/year-in-review-2020/user/_userHash/index" */))
const _03c3c888 = () => interopDefault(import('../pages/year-in-review-2020/user/_userHash/share.vue' /* webpackChunkName: "pages/year-in-review-2020/user/_userHash/share" */))
const _929f24a0 = () => interopDefault(import('../pages/github_callback/_job_id.vue' /* webpackChunkName: "pages/github_callback/_job_id" */))
const _15f898ba = () => interopDefault(import('../pages/reset_password/_token/index.vue' /* webpackChunkName: "pages/reset_password/_token/index" */))
const _039997d6 = () => interopDefault(import('../pages/stackoverflow_callback/_job_id.vue' /* webpackChunkName: "pages/stackoverflow_callback/_job_id" */))
const _03923ffc = () => interopDefault(import('../pages/user/_username/index.vue' /* webpackChunkName: "pages/user/_username/index" */))
const _21b045f6 = () => interopDefault(import('../pages/user/_username/index/index.vue' /* webpackChunkName: "pages/user/_username/index/index" */))
const _d01fd36a = () => interopDefault(import('../pages/user/_username/index/info.vue' /* webpackChunkName: "pages/user/_username/index/info" */))
const _66ce3436 = () => interopDefault(import('../pages/user/_userId/2019.vue' /* webpackChunkName: "pages/user/_userId/2019" */))
const _5ef56d0e = () => interopDefault(import('../pages/user/_username/asOthersSee.vue' /* webpackChunkName: "pages/user/_username/asOthersSee" */))
const _da591dde = () => interopDefault(import('../pages/user/_username/asOthersSee/index.vue' /* webpackChunkName: "pages/user/_username/asOthersSee/index" */))
const _a4885e82 = () => interopDefault(import('../pages/user/_username/asOthersSee/info.vue' /* webpackChunkName: "pages/user/_username/asOthersSee/info" */))
const _5fe68ee2 = () => interopDefault(import('../pages/user/_username/share.vue' /* webpackChunkName: "pages/user/_username/share" */))
const _24c8bd04 = () => interopDefault(import('../pages/job-board/_id/_name.vue' /* webpackChunkName: "pages/job-board/_id/_name" */))
const _48175818 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1bda1bfe = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _5a9f5562,
    children: [{
      path: "",
      component: _319536dc,
      name: "accountEditAccount"
    }, {
      path: "edit-badges",
      component: _1699a8da,
      name: "accountEditBadges"
    }, {
      path: "edit-profile",
      component: _7e9cfd54,
      name: "accountEditProfile"
    }, {
      path: "integrations",
      component: _dbc85de2,
      name: "accountIntegrations"
    }, {
      path: "job-preferences",
      component: _0b28f0b0,
      name: "accountEditJobPreferences"
    }]
  }, {
    path: "/forgot",
    component: _66794bf1,
    name: "forgot"
  }, {
    path: "/gitlab_callback",
    component: _2f5b9e24,
    name: "gitlabCallback"
  }, {
    path: "/invite",
    component: _58087e1a,
    name: "invite"
  }, {
    path: "/login",
    component: _b4c811ea,
    name: "login"
  }, {
    path: "/logout",
    component: _5af3f2d8,
    name: "logout"
  }, {
    path: "/messages",
    component: _73e6b9c6,
    name: "messages"
  }, {
    path: "/register",
    component: _41ba7c1e,
    name: "register"
  }, {
    path: "/repo",
    component: _73347a40,
    name: "repo"
  }, {
    path: "/year-in-review-2020",
    component: _f182f4c4,
    name: "year-in-review-2020"
  }, {
    path: "/leaderboard/company",
    component: _7faadde6,
    name: "companyLeaderboard"
  }, {
    path: "/leaderboard/developer",
    component: _067b8fda,
    name: "developerLeaderboard"
  }, {
    path: "/leaderboard/education",
    component: _92521e90,
    name: "educationLeaderboard"
  }, {
    path: "/onboarding/connect",
    component: _2a200744,
    name: "onboardingConnect"
  }, {
    path: "/onboarding/create",
    component: _bba816dc,
    name: "onboardingCreate"
  }, {
    path: "/onboarding/done",
    component: _59346b98,
    name: "onboardingDone"
  }, {
    path: "/onboarding/info",
    component: _1e9a36c4,
    name: "onboardingInfo"
  }, {
    path: "/onboarding/jobPreferences",
    component: _514afdde,
    name: "onboardingJobPreferences"
  }, {
    path: "/registration/job-interests",
    component: _22ba491e,
    name: "registration-job-interests"
  }, {
    path: "/year-in-review-2020/facts",
    component: _0e048e16,
    name: "year-in-review-2020-facts"
  }, {
    path: "/year-in-review-2020/global",
    component: _9da3556c,
    name: "year-in-review-2020-global"
  }, {
    path: "/year-in-review-2020/country/:code?",
    component: _dfc7c2bc,
    name: "year-in-review-2020-country-code"
  }, {
    path: "/year-in-review-2020/user/:userHash?",
    component: _31edeffb,
    name: "year-in-review-2020-user-userHash"
  }, {
    path: "/year-in-review-2020/user/:userHash?/share",
    component: _03c3c888,
    name: "year-in-review-2020-user-userHash-share"
  }, {
    path: "/github_callback/:job_id?",
    component: _929f24a0,
    name: "githubCallback"
  }, {
    path: "/reset_password/:token?",
    component: _15f898ba,
    name: "resetPassword"
  }, {
    path: "/stackoverflow_callback/:job_id?",
    component: _039997d6,
    name: "stackoverflowCallback"
  }, {
    path: "/user/:username?",
    component: _03923ffc,
    children: [{
      path: "",
      component: _21b045f6,
      name: "user"
    }, {
      path: "info",
      component: _d01fd36a,
      name: "userInfo"
    }]
  }, {
    path: "/user/:userId?/2019",
    component: _66ce3436,
    name: "yearSummary"
  }, {
    path: "/user/:username?/asOthersSee",
    component: _5ef56d0e,
    children: [{
      path: "",
      component: _da591dde,
      name: "userAsOthersSee"
    }, {
      path: "info",
      component: _a4885e82,
      name: "userInfoAsOthersSee"
    }]
  }, {
    path: "/user/:username?/share",
    component: _5fe68ee2,
    name: "userShare"
  }, {
    path: "/job-board/:id?/:name?",
    component: _24c8bd04,
    name: "jobboardIdName"
  }, {
    path: "/",
    component: _48175818,
    name: "index"
  }, {
    path: "/*",
    component: _1bda1bfe,
    name: "all"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
