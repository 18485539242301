


























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import CrInput from './Input.vue';

@Component({
  model: {
    prop: 'value',
    event: 'change',
  },
  components: {
    CrInput,
  },
})
class CrSelect extends Vue {
  @Prop({ default: null }) state: boolean | null;
  @Prop({ type: String, default: 'off' }) autocomplete: string;

  @Prop({ type: Boolean }) xs: boolean;
  @Prop({ type: Boolean }) sm: boolean;
  @Prop({ type: Boolean }) md: boolean;
  @Prop({ type: Boolean }) lg: boolean;
  @Prop({ type: Boolean }) xl: boolean;

  @Prop({ type: Function }) format: (option: any) => string;
  @Prop({ type: Boolean, default: true }) stringifyValue: boolean;

  @Prop({ type: Boolean, default: true }) input: boolean;
  @Prop({ type: String }) name: string;
  @Prop({ default: '' }) value: any;
  @Prop({ type: String }) placeholder: string;
  @Prop({ type: String, default: 'text' }) type: string;

  @Prop({ type: Boolean, default: false }) taggable: boolean;
  @Prop({ type: Boolean, default: true }) taggableKeepFocus: boolean;
  @Prop({ type: String, default: 'Add this item' }) tagPlaceholder: boolean;
  @Prop({ type: Boolean, default: true }) allowCustomTags: boolean;
  @Prop({ type: Boolean, default: true }) allowDeleteTags: boolean;

  @Prop({ type: Number, default: 0 }) maxTags: number;

  @Prop({ type: Boolean, default: false }) select: boolean;
  @Prop({ type: Boolean, default: false }) clearButton: boolean;
  @Prop({ type: Boolean, default: true }) selectDropdownIcon: boolean;

  @Prop({ type: Boolean, default: false }) dropdownPlaceholder: boolean;
  @Prop({ type: String, default: 'Sorry, no matching options' }) dropdownPlaceholderText: string;

  @Prop({ type: Boolean }) loading: boolean;
  @Prop({ type: Boolean }) disabled: boolean;

  @Prop({ type: Array, default: () => [] }) options: any[];
  @Prop({ type: Function, default: undefined }) fetchOptions: (query: string, callback: any) => any[];

  inputFocused = false;
  inputChanged = false;
  inputValue = this.formatOption(this.value, true);
  inputTaggableValue = '';
  highlightedDropdownIndex = 0;
  isTaggableWrap = false;
  keyboardSearch = '';

  fetchOptionsResults: any = null;
  fetchOptionsLoading = false;
  fetchOptionsTimeout: any = 0;

  @Watch('dropdownOpened')
  onDropdownToggle() {
    if (this.dropdownOpened) this.$emit('dropdownOpened');
    else this.$emit('dropdownClosed');
  }

  @Watch('value')
  onValueChange() {
    this.inputValue = this.formatOption(this.value, true);
    if (this.taggable && !this.taggableInputDisabled) {
      this.checkTaggableWrap();
    }
  }

  get size() {
    const { xs, sm, md, lg, xl } = this;
    if (xs) return 'xs';
    if (sm) return 'sm';
    if (md) return 'md';
    if (lg) return 'lg';
    if (xl) return 'xl';

    return 'md';
  }

  get hasTagToAdd() {
    const {
      taggable,
      allowCustomTags,
      inputTaggableValue,
      filteredOptions,
      value,
      fetchOptions,
      fetchOptionsResults,
    } = this;
    let hasDifferentCaseValue = false;
    if (taggable && inputTaggableValue.length && fetchOptions && fetchOptionsResults && fetchOptionsResults.length) {
      filteredOptions.forEach((option: string) => {
        if (option.toLowerCase && option.toLowerCase() === inputTaggableValue.toLowerCase()) {
          hasDifferentCaseValue = true;
        }
      });
    }
    if (hasDifferentCaseValue) return false;
    return (
      taggable &&
      allowCustomTags &&
      filteredOptions.indexOf(inputTaggableValue) < 0 &&
      inputTaggableValue.length &&
      value.indexOf(inputTaggableValue) < 0
    );
  }

  get maxTagsReached() {
    const { taggable, maxTags, value } = this;
    return taggable && maxTags && maxTags > 0 && value.length >= maxTags;
  }

  get dropdownOpened() {
    const {
      inputTaggableValue,
      inputFocused,
      taggable,
      filteredOptions,
      loading,
      dropdownPlaceholder,
      hasTagToAdd,
      maxTagsReached,
    } = this;
    if (maxTagsReached) return false;
    const hasDropdownSlots = this.$slots.dropdown && this.$slots.dropdown.length;
    return (
      inputFocused &&
      ((taggable && hasTagToAdd) ||
        (inputTaggableValue && hasDropdownSlots) ||
        filteredOptions.length > 0 ||
        loading ||
        (!filteredOptions.length && dropdownPlaceholder))
    );
  }

  get filteredOptions() {
    const {
      select,
      taggable,
      inputChanged,
      options,
      fetchOptionsResults,
      formatOption,
      inputValue,
      inputTaggableValue,
    } = this;

    if (!(select || taggable) || (select && !inputChanged)) return fetchOptionsResults || options;
    const value = taggable ? inputTaggableValue : inputValue;
    return (fetchOptionsResults || options).filter((option: any) => {
      const optionValue = formatOption(option);
      if (taggable && this.value.length && this.value.indexOf(optionValue) >= 0) return false;
      if (typeof optionValue !== 'string' && typeof optionValue !== 'number') return true;
      return (optionValue as string | number).toString().toLowerCase().indexOf(value.toLowerCase()) >= 0;
    });
  }

  get taggableInputDisabled(): boolean {
    const { maxTagsReached } = this;
    return !!maxTagsReached;
  }

  mounted() {
    if (this.taggable) {
      this.checkTaggableWrap();
    }
  }

  checkTaggableWrap() {
    this.isTaggableWrap = false;
    this.$nextTick(() => {
      const taggableInputEl = this.$el.querySelector('.cr-input input') as HTMLElement;
      if (!taggableInputEl) {
        if (this.maxTagsReached) this.isTaggableWrap = true;
        return;
      }
      if (taggableInputEl.offsetWidth < 180) {
        this.isTaggableWrap = true;
      }
    });
  }

  scrollDropdownToItem(dropdownContentEl: HTMLElement, listItemEl: HTMLElement) {
    const { offsetHeight, scrollTop } = dropdownContentEl;
    const { offsetTop } = listItemEl;
    if (scrollTop + offsetHeight < offsetTop) {
      dropdownContentEl.scrollTop = offsetTop;
    } else if (offsetTop < scrollTop) {
      dropdownContentEl.scrollTop = offsetTop - offsetHeight;
    }
  }

  searchDropdownValue() {
    const query = this.keyboardSearch;
    if (!query) return;
    const dropdownContentEl = this.$el.querySelector('.cr-dropdown-content') as HTMLElement;

    let listItemEl: HTMLElement;

    const items = dropdownContentEl.querySelectorAll('.cr-dropdown-list-item');

    const textContents = [];
    for (let i = 0; i < items.length; i += 1) {
      const textContent = (items[i].textContent || '').trim();
      textContents.push(textContent);
    }
    // Check for english names firstt
    textContents.forEach((textContent, index) => {
      if (listItemEl) return;
      const englishContent = textContent.replace(/[^a-z]/gi, '');
      if (englishContent.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        listItemEl = items[index] as HTMLElement;
        this.highlightedDropdownIndex = index;
      }
    });
    // Then match through whole string
    textContents.forEach((textContent, index) => {
      if (listItemEl) return;
      if (textContent.toLowerCase().includes(query.toLowerCase())) {
        listItemEl = items[index] as HTMLElement;
        this.highlightedDropdownIndex = index;
      }
    });

    // @ts-ignore
    if (dropdownContentEl && listItemEl) {
      this.scrollDropdownToItem(dropdownContentEl, listItemEl);
    }
  }

  onKeyDown(e: KeyboardEvent) {
    const keyCode = e.keyCode || e.code;
    if (keyCode === 27 || keyCode === 'Escape') {
      /* Handle Escape */
      e.preventDefault();
      const inputEl = this.$el.querySelector(this.taggable ? '.cr-input input' : '.cr-input') as HTMLInputElement;
      if (inputEl) inputEl.blur();
    } else if (keyCode === 40 || keyCode === 38 || keyCode === 'ArrowDown' || keyCode === 'ArrowUp') {
      /* Handle Arrows Up/Down */
      e.preventDefault();
      const optionsLength = this.filteredOptions.length + (this.hasTagToAdd ? 1 : 0);
      if (keyCode === 40 || keyCode === 'ArrowDown') {
        this.highlightedDropdownIndex += 1;
      } else {
        this.highlightedDropdownIndex -= 1;
      }
      if (this.highlightedDropdownIndex < 0) {
        this.highlightedDropdownIndex = optionsLength - 1;
      }
      if (this.highlightedDropdownIndex >= optionsLength) {
        this.highlightedDropdownIndex = 0;
      }
      const dropdownContentEl = this.$el.querySelector('.cr-dropdown-content') as HTMLElement;
      const listItemEl = this.$el.querySelector(
        `.cr-dropdown-list-item:nth-child(${this.highlightedDropdownIndex + 1})`,
      ) as HTMLElement;
      if (dropdownContentEl && listItemEl) {
        this.scrollDropdownToItem(dropdownContentEl, listItemEl);
      }
    } else if (keyCode === 13 || keyCode === 'Enter') {
      /* Handle Enter */
      e.preventDefault();
      if (this.hasTagToAdd && this.highlightedDropdownIndex === this.filteredOptions.length) {
        this.selectOption(this.inputTaggableValue);
      } else {
        const newOption = this.filteredOptions[this.highlightedDropdownIndex];
        if (typeof newOption !== 'undefined') {
          this.selectOption(newOption);
        }
      }
    } else if (this.select && !this.input) {
      /* Handle other keys */
      if (e.key && (!e.key.match(/\p{L}+/u) || e.key.length > 1)) return;
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      this.keyboardSearch += e.key;
      this.searchDropdownValue();
      setTimeout(() => {
        try {
          this.keyboardSearch = '';
        } catch (err) {
          // component was destroyed?
        }
      }, 3000);
    }
  }

  // Focus input on input wrap click
  onInputWrapClick(e: any) {
    if (!this.taggable) return;
    const targetEl = e.target;
    if (
      !targetEl.classList.contains('cr-select') &&
      !targetEl.classList.contains('cr-input') &&
      !targetEl.classList.contains('cr-select-tags')
    )
      return;
    const inputEl = this.$el.querySelector('.cr-input input') as HTMLInputElement;
    if (inputEl) {
      inputEl.focus();
    }
  }

  fetchOptionsLoad(query: string) {
    clearTimeout(this.fetchOptionsTimeout);
    this.fetchOptionsLoading = true;
    this.$emit('fetchOptions', true);
    this.fetchOptionsTimeout = setTimeout(() => {
      this.fetchOptions(query, (results: any[]) => {
        this.fetchOptionsResults = results;
        this.fetchOptionsLoading = false;
        this.$emit('fetchOptions', false);
      });
    }, 500);
  }

  onInputInput(value: any) {
    this.inputChanged = true;
    this.$emit('input', value);
    // @ts-ignore
    if (this.fetchOptions) {
      this.fetchOptionsLoad(value);
    }
    if (!this.select && !this.taggable) {
      this.$emit('change', value);
    }
  }

  onInputFocus(e: any) {
    this.inputChanged = false;
    this.inputFocused = true;
    this.highlightedDropdownIndex = 0;
    document.addEventListener('keydown', this.onKeyDown, true);
    this.$emit('focus', e);
  }

  onInputBlur(e: any) {
    this.inputChanged = false;
    this.inputFocused = false;
    if (this.select || this.taggable) {
      this.inputValue = this.formatOption(this.value);
    }
    if (this.taggable) {
      this.inputTaggableValue = '';
    }
    document.removeEventListener('keydown', this.onKeyDown, true);
    this.$emit('blur', e);
  }

  formatOption(option: any, forInput?: boolean) {
    let v = '';
    if (this.format) v = this.format(option);
    else v = option;
    if (forInput && typeof v !== 'string' && typeof v !== 'number' && this.stringifyValue) {
      if (typeof option === 'string') v = option;
      else v = '';
    }
    return v;
  }

  openDropdown() {
    const inputEl = this.$el.querySelector('.cr-input') as HTMLInputElement;
    if (inputEl) inputEl.focus();
  }

  clearValue() {
    this.selectOption('');
  }

  selectOption(option: any) {
    this.inputValue = this.formatOption(option);
    if (this.select) {
      this.$emit('change', option);
    } else if (this.taggable) {
      this.$emit('change', [...this.value, option]);
    }
    this.$emit('select', option);
    if (this.taggable) {
      this.$nextTick(() => {
        const inputEl = this.$el.querySelector('.cr-input input') as HTMLInputElement;
        if (inputEl) {
          inputEl.blur();
          if (this.taggableKeepFocus) inputEl.focus();
        }
      });
    } else {
      const inputEl = this.$el.querySelector('.cr-input') as HTMLInputElement;
      if (inputEl) inputEl.blur();
    }
  }

  removeTag(tag: any) {
    const newValue = [...this.value];
    if (newValue.indexOf(tag) >= 0) {
      newValue.splice(newValue.indexOf(tag), 1);
      this.$emit('change', newValue);
    }
  }
}
export default CrSelect;
