/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';

enum Mutations {
  OpenModal = '[detailed score] modal open',
  CloseModal = '[detailed score] close modal',
  AddScores = '[detailed score] add scores',
}

enum Actions {
  OpenModalWithLanguage = '[detailed score] query detailed score for language',
}

enum Getters {
  SortedScores = '[detailed score] sorted scores',
  ChartData = '[detailed score] chard data',
}

export const detailedscore = {
  mutations: prefixStoreEnums('detailedscore', Mutations),
  actions: prefixStoreEnums('detailedscore', Actions),
  getters: prefixStoreEnums('detailedscore', Getters),
};

interface DetailedScoreState {
  modalOpen: boolean;
  scores: { repo: string; source: string; score: number }[];
  language: string;
}

const initialState: DetailedScoreState = {
  modalOpen: false,
  scores: [],
  language: '',
};

export const state = () => ({ ...initialState });

export const mutations: MutationTree<DetailedScoreState> = {
  [Mutations.OpenModal](state, payload: string) {
    state.modalOpen = true;
    state.language = payload;
  },
  [Mutations.CloseModal](state) {
    state.modalOpen = false;
  },
  [Mutations.AddScores](state, payload: any) {
    state.scores = payload;
  },
};

export const actions: ActionTree<DetailedScoreState, RootState> = {
  async [Actions.OpenModalWithLanguage]({ commit, rootGetters }, payload: any) {
    const res = await axios.post(
      `${(axios.defaults as any).gatewayBase}/candidate/candidate/GetDetailedScoreForLanguage`,
      {
        sessionID: rootGetters.sessionId,
        language: payload,
      },
    );
    commit(Mutations.AddScores, res.data.repositories);
    commit(Mutations.OpenModal, payload);
  },
};

export const getters: GetterTree<DetailedScoreState, RootState> = {
  [Getters.SortedScores](state) {
    return state.scores
      .slice()
      .filter((a) => a.score && (a.repo || a.source === 'StackOverflow'))
      .sort((a, b) => b.score - a.score);
  },
  // eslint-disable-next-line
  [Getters.ChartData](state, getters) {
    const numbers: any[] = [];
    const labels: any[] = [];

    let i = 0;
    let countTowardsOther = false;

    let otherscore = 0;

    getters[Getters.SortedScores].forEach((repository: any) => {
      if (!countTowardsOther) {
        numbers.push(repository.score);
        labels.push(repository.repo || repository.source);
      } else {
        otherscore += repository.score;
      }
      i += 1;
      if (i >= 10) countTowardsOther = true;
    });

    if (i >= 11) {
      numbers.push(otherscore);
      labels.push('Other');
    }

    return { data: numbers, labels };
  },
};
