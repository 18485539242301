import tooltip from '../utils/tooltip';

const TOOLTIP_INSTANCE_PROP = '__cr__toltip__';

function getParameters(el: HTMLElement, binding: any) {
  let position;
  let triggers;
  const positionValues = ['auto', 'left', 'top', 'right', 'bottom'];
  const triggersValues = ['click', 'hover'];
  if (binding.modifiers) {
    Object.keys(binding.modifiers).forEach((key) => {
      if (positionValues.includes(key)) position = key;
      if (triggersValues.includes(key)) triggers = key;
    });
  }
  const text = binding.value || el.getAttribute('data-tooltip') || el.getAttribute('title');
  const container = binding.arg ? `#${binding.arg}` : undefined;

  const options: any = {
    target: el,
    text: text || '',
  };
  if (typeof position !== 'undefined') options.position = position;
  if (typeof triggers !== 'undefined') options.triggers = triggers;
  if (typeof container !== 'undefined') options.container = container;

  return options;
}

export default {
  bind(el: HTMLElement, binding: any) {
    if (!el) return;
    // @ts-ignore
    el[TOOLTIP_INSTANCE_PROP] = tooltip(getParameters(el, binding));
  },
  inserted(el: HTMLElement, binding: any) {
    if (!el) return;
    // @ts-ignore
    const t = el[TOOLTIP_INSTANCE_PROP];
    if (t) t.update(getParameters(el, binding));
    else {
      // @ts-ignore
      el[TOOLTIP_INSTANCE_PROP] = tooltip(getParameters(el, binding));
    }
  },
  update(el: HTMLElement, binding: any) {
    if (!el) return;
    // @ts-ignore
    const t = el[TOOLTIP_INSTANCE_PROP];
    if (t) t.update(getParameters(el, binding));
    else {
      // @ts-ignore
      el[TOOLTIP_INSTANCE_PROP] = tooltip(getParameters(el, binding));
    }
  },
  unbind(el: HTMLElement) {
    if (!el) return;
    // @ts-ignore
    const t = el[TOOLTIP_INSTANCE_PROP];

    if (t) {
      t.close();
      t.destroy();
      // @ts-ignore
      delete el[TOOLTIP_INSTANCE_PROP];
    }
  },
};
