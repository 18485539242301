





































import Vue from 'vue';

export default Vue.extend({
  props: {
    full: Boolean,
    color: String,
  },
});
