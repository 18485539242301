





import Vue from 'vue';
import Component from 'nuxt-class-component';

@Component
class Loading extends Vue {
  loading = false;

  get color() {
    if (
      (this.$route.name === 'user' || this.$route.name === 'userAsOthersSee') &&
      this.$store.state.profile.ownProfile
    ) {
      return 'hard-teal';
    }
    return 'hard-teal';
  }

  start() {
    this.loading = true;
  }

  finish() {
    this.loading = false;
  }
}
export default Loading;
