export const phoneCountryCodes = [
  ['Afghanistan (‫افغانستان‬‎)', '93', 0],
  ['Albania (Shqipëri)', '355', 0],
  ['Algeria (‫الجزائر‬‎)', '213', 0],
  ['American Samoa', '1', 5],
  ['Andorra', '376', 0],
  ['Angola', '244', 0],
  ['Anguilla', '1', 6],
  ['Antigua and Barbuda', '1', 7],
  ['Argentina', '54', 0],
  ['Armenia (Հայաստան)', '374', 0],
  ['Aruba', '297', 0],
  ['Australia', '61', 0],
  ['Austria (Österreich)', '43', 0],
  ['Azerbaijan (Azərbaycan)', '994', 0],
  ['Bahamas', '1', 8],
  ['Bahrain (‫البحرين‬‎)', '973', 0],
  ['Bangladesh (বাংলাদেশ)', '880', 0],
  ['Barbados', '1', 9],
  ['Belarus (Беларусь)', '375', 0],
  ['Belgium (België)', '32', 0],
  ['Belize', '501', 0],
  ['Benin (Bénin)', '229', 0],
  ['Bermuda', '1', 10],
  ['Bhutan (འབྲུག)', '975', 0],
  ['Bolivia', '591', 0],
  ['Bosnia and Herzegovina (Босна и Херцеговина)', '387', 0],
  ['Botswana', '267', 0],
  ['Brazil (Brasil)', '55', 0],
  ['British Indian Ocean Territory', '246', 0],
  ['British Virgin Islands', '1', 11],
  ['Brunei', '673', 0],
  ['Bulgaria (България)', '359', 0],
  ['Burkina Faso', '226', 0],
  ['Burundi (Uburundi)', '257', 0],
  ['Cambodia (កម្ពុជា)', '855', 0],
  ['Cameroon (Cameroun)', '237', 0],
  ['Canada', '1', 1],
  ['Cape Verde (Kabu Verdi)', '238', 0],
  ['Caribbean Netherlands', '599', 1],
  ['Cayman Islands', '1', 12],
  ['Central African Republic (République centrafricaine)', '236', 0],
  ['Chad (Tchad)', '235', 0],
  ['Chile', '56', 0],
  ['China (中国)', '86', 0],
  ['Christmas Island', '61', 2],
  ['Cocos (Keeling) Islands', '61', 1],
  ['Colombia', '57', 0],
  ['Comoros (‫جزر القمر‬‎)', '269', 0],
  ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', '243', 0],
  ['Congo (Republic) (Congo-Brazzaville)', '242', 0],
  ['Cook Islands', '682', 0],
  ['Costa Rica', '506', 0],
  ['Côte d’Ivoire', '225', 0],
  ['Croatia (Hrvatska)', '385', 0],
  ['Cuba', '53', 0],
  ['Curaçao', '599', 0],
  ['Cyprus (Κύπρος)', '357', 0],
  ['Czech Republic (Česká republika)', '420', 0],
  ['Denmark (Danmark)', '45', 0],
  ['Djibouti', '253', 0],
  ['Dominica', '1', 13],
  ['Dominican Republic (República Dominicana)', '1', 2],
  ['Ecuador', '593', 0],
  ['Egypt (‫مصر‬‎)', '20', 0],
  ['El Salvador', '503', 0],
  ['Equatorial Guinea (Guinea Ecuatorial)', '240', 0],
  ['Eritrea', '291', 0],
  ['Estonia (Eesti)', '372', 0],
  ['Ethiopia', '251', 0],
  ['Falkland Islands (Islas Malvinas)', '500', 0],
  ['Faroe Islands (Føroyar)', '298', 0],
  ['Fiji', '679', 0],
  ['Finland (Suomi)', '358', 0],
  ['France', '33', 0],
  ['French Guiana (Guyane française)', '594', 0],
  ['French Polynesia (Polynésie française)', '689', 0],
  ['Gabon', '241', 0],
  ['Gambia', '220', 0],
  ['Georgia (საქართველო)', '995', 0],
  ['Germany (Deutschland)', '49', 0],
  ['Ghana (Gaana)', '233', 0],
  ['Gibraltar', '350', 0],
  ['Greece (Ελλάδα)', '30', 0],
  ['Greenland (Kalaallit Nunaat)', '299', 0],
  ['Grenada', '1', 14],
  ['Guadeloupe', '590', 0],
  ['Guam', '1', 15],
  ['Guatemala', '502', 0],
  ['Guernsey', '44', 1],
  ['Guinea (Guinée)', '224', 0],
  ['Guinea-Bissau (Guiné Bissau)', '245', 0],
  ['Guyana', '592', 0],
  ['Haiti', '509', 0],
  ['Honduras', '504', 0],
  ['Hong Kong (香港)', '852', 0],
  ['Hungary (Magyarország)', '36', 0],
  ['Iceland (Ísland)', '354', 0],
  ['India (भारत)', '91', 0],
  ['Indonesia', '62', 0],
  ['Iran (‫ایران‬‎)', '98', 0],
  ['Iraq (‫العراق‬‎)', '964', 0],
  ['Ireland', '353', 0],
  ['Isle of Man', '44', 2],
  ['Israel (‫ישראל‬‎)', '972', 0],
  ['Italy (Italia)', '39', 0],
  ['Jamaica', '1', 4],
  ['Japan (日本)', '81', 0],
  ['Jersey', '44', 3],
  ['Jordan (‫الأردن‬‎)', '962', 0],
  ['Kazakhstan (Казахстан)', '7', 1],
  ['Kenya', '254', 0],
  ['Kiribati', '686', 0],
  ['Kosovo', '383', 0],
  ['Kuwait (‫الكويت‬‎)', '965', 0],
  ['Kyrgyzstan (Кыргызстан)', '996', 0],
  ['Laos (ລາວ)', '856', 0],
  ['Latvia (Latvija)', '371', 0],
  ['Lebanon (‫لبنان‬‎)', '961', 0],
  ['Lesotho', '266', 0],
  ['Liberia', '231', 0],
  ['Libya (‫ليبيا‬‎)', '218', 0],
  ['Liechtenstein', '423', 0],
  ['Lithuania (Lietuva)', '370', 0],
  ['Luxembourg', '352', 0],
  ['Macau (澳門)', '853', 0],
  ['Macedonia (FYROM) (Македонија)', '389', 0],
  ['Madagascar (Madagasikara)', '261', 0],
  ['Malawi', '265', 0],
  ['Malaysia', '60', 0],
  ['Maldives', '960', 0],
  ['Mali', '223', 0],
  ['Malta', '356', 0],
  ['Marshall Islands', '692', 0],
  ['Martinique', '596', 0],
  ['Mauritania (‫موريتانيا‬‎)', '222', 0],
  ['Mauritius (Moris)', '230', 0],
  ['Mayotte', '262', 1],
  ['Mexico (México)', '52', 0],
  ['Micronesia', '691', 0],
  ['Moldova (Republica Moldova)', '373', 0],
  ['Monaco', '377', 0],
  ['Mongolia (Монгол)', '976', 0],
  ['Montenegro (Crna Gora)', '382', 0],
  ['Montserrat', '1', 16],
  ['Morocco (‫المغرب‬‎)', '212', 0],
  ['Mozambique (Moçambique)', '258', 0],
  ['Myanmar (Burma) (မြန်မာ)', '95', 0],
  ['Namibia (Namibië)', '264', 0],
  ['Nauru', '674', 0],
  ['Nepal (नेपाल)', '977', 0],
  ['Netherlands (Nederland)', '31', 0],
  ['New Caledonia (Nouvelle-Calédonie)', '687', 0],
  ['New Zealand', '64', 0],
  ['Nicaragua', '505', 0],
  ['Niger (Nijar)', '227', 0],
  ['Nigeria', '234', 0],
  ['Niue', '683', 0],
  ['Norfolk Island', '672', 0],
  ['North Korea (조선 민주주의 인민 공화국)', '850', 0],
  ['Northern Mariana Islands', '1', 17],
  ['Norway (Norge)', '47', 0],
  ['Oman (‫عُمان‬‎)', '968', 0],
  ['Pakistan (‫پاکستان‬‎)', '92', 0],
  ['Palau', '680', 0],
  ['Palestine (‫فلسطين‬‎)', '970', 0],
  ['Panama (Panamá)', '507', 0],
  ['Papua New Guinea', '675', 0],
  ['Paraguay', '595', 0],
  ['Peru (Perú)', '51', 0],
  ['Philippines', '63', 0],
  ['Poland (Polska)', '48', 0],
  ['Portugal', '351', 0],
  ['Puerto Rico', '1', 3],
  ['Qatar (‫قطر‬‎)', '974', 0],
  ['Réunion (La Réunion)', '262', 0],
  ['Romania (România)', '40', 0],
  ['Russia (Россия)', '7', 0],
  ['Rwanda', '250', 0],
  ['Saint Barthélemy', '590', 1],
  ['Saint Helena', '290', 0],
  ['Saint Kitts and Nevis', '1', 18],
  ['Saint Lucia', '1', 19],
  ['Saint Martin (Saint-Martin (partie française))', '590', 2],
  ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', '508', 0],
  ['Saint Vincent and the Grenadines', '1', 20],
  ['Samoa', '685', 0],
  ['San Marino', '378', 0],
  ['São Tomé and Príncipe (São Tomé e Príncipe)', '239', 0],
  ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', '966', 0],
  ['Senegal (Sénégal)', '221', 0],
  ['Serbia (Србија)', '381', 0],
  ['Seychelles', '248', 0],
  ['Sierra Leone', '232', 0],
  ['Singapore', '65', 0],
  ['Sint Maarten', '1', 21],
  ['Slovakia (Slovensko)', '421', 0],
  ['Slovenia (Slovenija)', '386', 0],
  ['Solomon Islands', '677', 0],
  ['Somalia (Soomaaliya)', '252', 0],
  ['South Africa', '27', 0],
  ['South Korea (대한민국)', '82', 0],
  ['South Sudan (‫جنوب السودان‬‎)', '211', 0],
  ['Spain (España)', '34', 0],
  ['Sri Lanka (ශ්‍රී ලංකාව)', '94', 0],
  ['Sudan (‫السودان‬‎)', '249', 0],
  ['Suriname', '597', 0],
  ['Svalbard and Jan Mayen', '47', 1],
  ['Swaziland', '268', 0],
  ['Sweden (Sverige)', '46', 0],
  ['Switzerland (Schweiz)', '41', 0],
  ['Syria (‫سوريا‬‎)', '963', 0],
  ['Taiwan (台灣)', '886', 0],
  ['Tajikistan', '992', 0],
  ['Tanzania', '255', 0],
  ['Thailand (ไทย)', '66', 0],
  ['Timor-Leste', '670', 0],
  ['Togo', '228', 0],
  ['Tokelau', '690', 0],
  ['Tonga', '676', 0],
  ['Trinidad and Tobago', '1', 22],
  ['Tunisia (‫تونس‬‎)', '216', 0],
  ['Turkey (Türkiye)', '90', 0],
  ['Turkmenistan', '993', 0],
  ['Turks and Caicos Islands', '1', 23],
  ['Tuvalu', '688', 0],
  ['U.S. Virgin Islands', '1', 24],
  ['Uganda', '256', 0],
  ['Ukraine (Україна)', '380', 0],
  ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', '971', 0],
  ['United Kingdom', '44', 0],
  ['United States', '1', 0],
  ['Uruguay', '598', 0],
  ['Uzbekistan (Oʻzbekiston)', '998', 0],
  ['Vanuatu', '678', 0],
  ['Vatican City (Città del Vaticano)', '39', 1],
  ['Venezuela', '58', 0],
  ['Vietnam (Việt Nam)', '84', 0],
  ['Wallis and Futuna (Wallis-et-Futuna)', '681', 0],
  ['Western Sahara (‫الصحراء الغربية‬‎)', '212', 1],
  ['Yemen (‫اليمن‬‎)', '967', 0],
  ['Zambia', '260', 0],
  ['Zimbabwe', '263', 0],
  ['Åland Islands', '358', 1],
];
