















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import RankingBadge from '../shared/RankingBadge.vue';

@Component({
  components: { RankingBadge },
})
class ProfileEditModal extends Vue {
  modalVisible = true;

  get hasBadgeEarned() {
    return !!this.$store.state.profile.badgeEarned;
  }

  get badgeEarned() {
    const badge = this.$store.state.profile.badgeEarned;
    if (badge) {
      return {
        version: 'v2',
        ...badge,
      };
    }
    return null;
  }

  close() {
    this.modalVisible = false;
    this.$store.dispatch('confirmBadgeEarned');
  }

  onButtonClick() {
    this.close();
    this.$router.push('/account/edit-badges');
  }
}
export default ProfileEditModal;
