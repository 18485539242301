


































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import tooltip from '../utils/tooltip';

interface DatasetItem {
  color: string;
  label: string;
  values: number[];
}

@Component
class CrAreaChart extends Vue {
  @Prop({ type: Array, default: () => [] }) labels: any[];
  @Prop({ type: Array, default: () => [] }) datasets: DatasetItem[];
  @Prop({ type: Boolean, default: false }) tooltip: boolean;
  @Prop({ type: Boolean, default: false }) toolbar: boolean;
  @Prop({ type: Number, default: 640 }) width: number;
  @Prop({ type: Number, default: 320 }) height: number;
  @Prop() formatLabel: (label: any) => string;
  @Prop() formatTooltip: (data: any) => string;
  @Prop() formatTooltipLabel: (label: any) => string;
  @Prop() formatTooltipTotal: (total: number) => string;
  @Prop() formatTooltipDatasets: (datasets: any) => string;
  @Prop() formatTooltipDataset: (label: string, value: number, color: string) => string;
  @Prop({ type: Number, default: 8 }) maxLabels: number;

  linesOffsets: number[] = [];
  currentIndex: number | null = null;
  tooltipInstance: any = null;
  hiddenDatasets: string[] = [];
  highlightedDatasetLabel: string | null = null;

  get visibleLabels() {
    if (!this.maxLabels || this.labels.length <= this.maxLabels) return this.labels;
    const skipStep = Math.ceil(this.labels.length / this.maxLabels);
    const filtered = this.labels.filter((label, index) => index % skipStep === 0);
    return filtered;
  }

  get summValues(): number[] {
    const summValues: number[] = [];
    this.datasets
      .filter((dataset) => !this.hiddenDatasets.includes(dataset.label))
      .forEach(({ values }) => {
        values.forEach((value, valueIndex) => {
          if (!summValues[valueIndex]) summValues[valueIndex] = 0;
          summValues[valueIndex] += value;
        });
      });
    return summValues;
  }

  get circles() {
    const { width, height, summValues } = this;
    const maxValue = Math.max(...summValues);
    return summValues.map((value, valueIndex) => {
      const x = (valueIndex / (summValues.length - 1)) * width;
      const y = height - (value / maxValue) * height;
      return [x, y];
    });
  }

  get polygons() {
    const { datasets, width, height, summValues, hiddenDatasets } = this;
    const polygons: any = [];
    if (!datasets.length) {
      return polygons;
    }
    const lastValues = datasets[0].values.map(() => 0);

    const maxValue = Math.max(...summValues);
    datasets
      .filter((dataset) => !hiddenDatasets.includes(dataset.label))
      .forEach(({ label, values, color }) => {
        const points = values.map((originalValue, valueIndex) => {
          lastValues[valueIndex] += originalValue;
          const value = lastValues[valueIndex];
          const x = (valueIndex / (values.length - 1)) * width;
          const y = height - (value / maxValue) * height;
          return `${x} ${y}`;
        });
        points.push(`${width} ${height} 0 ${height}`);

        polygons.push({
          label,
          points: points.join(' '),
          color,
        });
      });
    return polygons.reverse();
  }

  get lines() {
    const { datasets, width } = this;
    const lines: number[] = [];
    if (!datasets.length) {
      return lines;
    }
    const values = datasets[0].values;
    values.forEach((value, valueIndex) => {
      const x = (valueIndex / (values.length - 1)) * width;
      lines.push(x);
    });
    return lines;
  }

  toggleDataset(label: string) {
    if (this.hiddenDatasets.includes(label)) {
      this.hiddenDatasets.splice(this.hiddenDatasets.indexOf(label), 1);
    } else {
      this.hiddenDatasets.push(label);
      this.highlightedDatasetLabel = null;
    }
  }

  @Watch('currentIndex')
  onCurrentIndexChange() {
    if (!this.tooltip) return;
    const visibleDataSets = this.datasets.filter((dataset) => !this.hiddenDatasets.includes(dataset.label)).length;
    if (!visibleDataSets) {
      if (this.tooltipInstance && this.tooltipInstance.close) this.tooltipInstance.close();
      return;
    }
    if (this.currentIndex !== null && !this.tooltipInstance) {
      // @ts-ignore
      this.tooltipInstance = tooltip({
        position: 'left',
        triggers: 'manual',
        container: this.$el as HTMLElement,
        target: this.$el.querySelector(`line[data-index="${this.currentIndex}"]`) as HTMLElement,
        targetCenter: true,
        text: this.formatTooltipText(),
        className: 'cr-area-chart-tooltip',
      });
      if (this.tooltipInstance && this.tooltipInstance.open) {
        this.tooltipInstance.open();
      }
      return;
    }
    if (
      !this.tooltipInstance ||
      !this.tooltipInstance.update ||
      !this.tooltipInstance.close ||
      !this.tooltipInstance.open
    ) {
      return;
    }
    if (this.currentIndex !== null) {
      this.tooltipInstance.update({
        target: this.$el.querySelector(`line[data-index="${this.currentIndex}"]`) as HTMLElement,
        text: this.formatTooltipText(),
      });
      this.tooltipInstance.open();
    } else {
      this.tooltipInstance.close();
    }
  }

  formatTooltipText() {
    const currentIndex = this.currentIndex;
    if (currentIndex === null) return '';
    let total = 0;
    const currentValues = this.datasets
      .filter((dataset) => !this.hiddenDatasets.includes(dataset.label) && dataset.values[currentIndex])
      .map((dataset) => ({
        color: dataset.color,
        label: dataset.label,
        value: dataset.values[currentIndex],
      }));
    currentValues.forEach((dataset) => {
      total += dataset.value;
    });
    if (this.formatTooltip) {
      return this.formatTooltip({
        index: currentIndex,
        total,
        datasets: currentValues,
      });
    }

    const labelText = this.formatTooltipLabel
      ? this.formatTooltipLabel(this.labels[currentIndex])
      : this.formatAxisLabel(this.labels[currentIndex]);
    const totalText = this.formatTooltipTotal ? this.formatTooltipTotal(total) : total;
    const datasetsText = this.formatTooltipDatasets
      ? this.formatTooltipDatasets(currentValues)
      : `
      ${
        currentValues.length > 0
          ? `
      <ul class="cr-area-chart-tooltip-list">
        ${currentValues
          .map(({ label, color, value }) => {
            const valueText = this.formatTooltipDataset
              ? this.formatTooltipDataset(label, value, color)
              : `${label}: ${value}`;
            return `
          <li><span style="background-color: ${color};"></span>${valueText}</li>
          `;
          })
          .join('')}
      </ul>
      `
          : ''
      }
    `;
    return `
      <div class="cr-area-chart-tooltip-label">${labelText}</div>
      <div class="cr-area-chart-tooltip-total">${totalText}</div>
      ${datasetsText}
    `;
  }

  formatAxisLabel(label: any) {
    if (this.formatLabel) return this.formatLabel(label);
    return label;
  }

  calcLinesOffsets() {
    const lines = this.$el.querySelectorAll('line');
    this.linesOffsets = [];
    for (let i = 0; i < lines.length; i += 1) {
      // @ts-ignore
      this.linesOffsets.push(lines[i].getBoundingClientRect().left);
    }
  }

  onMouseEnter() {
    this.calcLinesOffsets();
  }

  onMouseMove(e: any) {
    let currentLeft = e.pageX;
    if (typeof currentLeft === 'undefined') currentLeft = 0;
    const distances = this.linesOffsets.map((left) => Math.abs(currentLeft - left));
    const minDistance = Math.min(...distances);
    const closestIndex = distances.indexOf(minDistance);
    this.currentIndex = closestIndex;
  }

  onMouseLeave() {
    this.currentIndex = null;
  }

  mounted() {
    const svgEl = this.$el.querySelector('svg');
    if (!svgEl) return;
    svgEl.addEventListener('mouseenter', this.onMouseEnter);
    svgEl.addEventListener('mousemove', this.onMouseMove);
    svgEl.addEventListener('mouseleave', this.onMouseLeave);
  }

  beforeDestroy() {
    if (this.tooltipInstance && this.tooltipInstance.destroy) {
      this.tooltipInstance.destroy();
    }
    const svgEl = this.$el.querySelector('svg');
    if (!svgEl) return;
    svgEl.addEventListener('mouseenter', this.onMouseEnter);
    svgEl.addEventListener('mousemove', this.onMouseMove);
    svgEl.addEventListener('mouseleave', this.onMouseLeave);
  }
}

export default CrAreaChart;
