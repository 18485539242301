/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';
import { responseError } from '../utils/response-error';

enum Mutations {
  SetState = '[education] set state',
}

enum Actions {
  OpenAddEducationModal = '[education] open add education modal',
  OpenAddCertificateModal = '[education] open add certificate modal',
  OpenErrorModal = '[education] open error modal',

  OpenEditEducationModal = '[education] open edit education modal',
  OpenEditCertificateModal = '[education] open edit certificate modal',

  CloseEducationModal = '[education] close education modal',
  CloseCertificateModal = '[education] close certificate modal',
  CloseErrorModal = '[education] close error modal',

  CreateEducation = '[education] create education',
  CreateCertificate = '[education] create certificate',

  EditEducation = '[education] edit education',
  EditCertificate = '[education] edit certificate',

  DeleteEducation = '[education] delete education',
  DeleteCertificate = '[education] delete certificate',

  AssignEducation = '[education] assign education and certificates',
}

enum Getters {
  SelectedEducation = '[education] selected education',
  SelectedCertificate = '[education] selected certification',
}

export const education = {
  actions: prefixStoreEnums('education', Actions),
  getters: prefixStoreEnums('education', Getters),
};

export interface Education {
  id: number;
  school: string;
  degree: string;
  field: string;
  dateFrom: string;
  dateTo: string;
  currentlyStudyingHere: boolean;
  description: string;
  grade: number;
  location: string;
}

export interface Certificate {
  id: number;
  name: string;
  organization: string;
  field: string;
  date: string;
  certificationID: string;
  certificationURL: string;
}

interface EducationState {
  educationModalOpen: boolean;
  certificateModalOpen: boolean;
  errorModalOpen: boolean;
  errorMessage: string;
  educations: Education[];
  certificates: Certificate[];
  selectedIndex: number;
  modalEducation: 'add' | 'edit';
  modalCertificate: 'add' | 'edit';
}

const initialState: EducationState = {
  educationModalOpen: false,
  certificateModalOpen: false,
  errorModalOpen: false,
  errorMessage: '',
  educations: [],
  certificates: [],
  selectedIndex: -1,
  modalEducation: 'add',
  modalCertificate: 'add',
};

function setDates(edu: any) {
  const { startYear, startMonth, endYear, endMonth, grade, dateFrom, dateTo } = edu;
  if (!dateFrom && startYear && startMonth) {
    edu.dateFrom = startYear && startMonth ? `${startYear}-${startMonth.toString().padStart(2, '0')}` : undefined;
  }
  if (!dateTo && endYear && endMonth) {
    edu.dateTo = endYear && endMonth ? `${endYear}-${endMonth.toString().padStart(2, '0')}` : undefined;
  }

  edu.grade = grade ? parseFloat(grade) : undefined;
  delete edu.startYear;
  delete edu.startMonth;
  delete edu.endYear;
  delete edu.endMonth;
}

export const state = () => ({ ...initialState });

export const mutations: MutationTree<EducationState> = {
  [Mutations.SetState]: (state, payload: EducationState) => {
    state = { ...payload };
  },
};

export const actions: ActionTree<EducationState, RootState> = {
  [Actions.OpenAddEducationModal]({ state, commit }) {
    state.educationModalOpen = true;
    state.selectedIndex = -1;
    commit(Mutations.SetState, state);
  },
  [Actions.OpenEditEducationModal]({ state, commit }, index) {
    state.modalEducation = 'edit';
    state.selectedIndex = index;
    commit(Mutations.SetState, state);
    state.educationModalOpen = true;
    commit(Mutations.SetState, state);
  },
  [Actions.CloseEducationModal]({ state, commit }) {
    state.educationModalOpen = false;
    state.modalEducation = 'add';
    state.selectedIndex = -1;
    commit(Mutations.SetState, state);
  },
  [Actions.OpenAddCertificateModal]({ state, commit }) {
    state.certificateModalOpen = true;
    state.selectedIndex = -1;
    commit(Mutations.SetState, state);
  },
  [Actions.OpenEditCertificateModal]({ state, commit }, index) {
    state.modalCertificate = 'edit';
    state.selectedIndex = index;
    commit(Mutations.SetState, state);
    state.certificateModalOpen = true;
    commit(Mutations.SetState, state);
  },
  [Actions.CloseCertificateModal]({ state, commit }) {
    state.certificateModalOpen = false;
    state.modalCertificate = 'add';
    state.selectedIndex = -1;
    commit(Mutations.SetState, state);
  },
  [Actions.OpenErrorModal]({ state, commit }, payload) {
    state.errorMessage = payload;
    state.errorModalOpen = true;
    commit(Mutations.SetState, state);
  },
  [Actions.CloseErrorModal]({ state, commit }) {
    state.errorMessage = '';
    state.errorModalOpen = false;
    commit(Mutations.SetState, state);
  },

  [Actions.AssignEducation]({ state, commit, dispatch }, username) {
    state.educations = [];
    state.certificates = [];
    commit(Mutations.SetState, state);

    const getEducation = () => {
      return this.$axios
        .get(`${(axios.defaults as any).gatewayBase}/account/education`, {
          params: {
            username,
          },
        })
        .then((res) => {
          state.educations = res.data.map((elem: any, index: number) => ({
            ...elem,
            id: index,
          }));
          commit(Mutations.SetState, state);
        })
        .catch((err) => {
          this.$sentry.captureException(err);
        });
    };

    const getCertificates = () => {
      return this.$axios
        .get(`${(axios.defaults as any).gatewayBase}/candidate/candidate/Certificate`, {
          params: {
            username,
          },
        })
        .then((res) => {
          state.certificates = res.data.map((elem: any, index: number) => ({ ...elem, id: index }));
          commit(Mutations.SetState, state);
        })
        .catch((err) => {
          dispatch(Actions.OpenErrorModal, responseError(err));
          this.$sentry.captureException(err);
        });
    };

    return Promise.all([getEducation(), getCertificates()]);
  },

  async [Actions.CreateEducation]({ state, commit, dispatch, rootGetters }, edu) {
    setDates(edu);
    state.educations.push({ ...edu, id: state.educations.length });
    commit(Mutations.SetState, state);
    dispatch(Actions.CloseEducationModal);
    await axios
      .post(`${(axios.defaults as any).gatewayBase}/account/education`, {
        sessionID: rootGetters.sessionId,
        data: edu,
      })
      .catch((err) => {
        state.educations.pop();
        commit(Mutations.SetState);
        dispatch(Actions.OpenErrorModal, responseError(err));
      });
  },
  async [Actions.EditEducation]({ state, commit, dispatch, rootGetters }, edu) {
    const oldstate = cloneDeep(state);

    setDates(edu);
    state.educations[edu.id] = edu;
    commit(Mutations.SetState, state);
    dispatch(Actions.CloseEducationModal);
    await axios
      .put(`${(axios.defaults as any).gatewayBase}/account/education`, {
        sessionID: rootGetters.sessionId,
        data: edu,
      })
      .catch((err) => {
        state = oldstate;
        commit(Mutations.SetState, state);
        dispatch(Actions.OpenErrorModal, responseError(err));
      });
  },
  async [Actions.DeleteEducation]({ state, commit, dispatch, rootGetters }, id) {
    const oldstate = cloneDeep(state);

    state.educations.splice(id, 1);
    state.educations = state.educations.map((elem: any, index: number) => ({ ...elem, id: index }));
    commit(Mutations.SetState, state);
    dispatch(Actions.CloseEducationModal);
    await axios
      .delete(`${(axios.defaults as any).gatewayBase}/account/education`, {
        headers: {
          'X-SESSION-ID': rootGetters.sessionId,
        },
        params: {
          id,
        },
      })
      .catch((err) => {
        state = { ...oldstate };
        commit(Mutations.SetState, state);
        dispatch(Actions.OpenErrorModal, responseError(err));
      });
  },

  async [Actions.CreateCertificate]({ state, commit, dispatch, rootGetters }, cert) {
    state.certificates.push({ ...cert, id: state.certificates.length });
    commit(Mutations.SetState, state);
    dispatch(Actions.CloseCertificateModal);
    await axios
      .post(`${(axios.defaults as any).gatewayBase}/candidate/candidate/Certificate`, {
        sessionID: rootGetters.sessionId,
        data: cert,
      })
      .catch((err) => {
        state.certificates.pop();
        commit(Mutations.SetState);
        dispatch(Actions.OpenErrorModal, responseError(err));
      });
  },
  async [Actions.EditCertificate]({ state, commit, dispatch, rootGetters }, cert) {
    const oldstate = cloneDeep(state);

    Object.assign(state.certificates[cert.id], cert);
    commit(Mutations.SetState, state);
    dispatch(Actions.CloseCertificateModal);
    await axios
      .put(`${(axios.defaults as any).gatewayBase}/candidate/candidate/Certificate`, {
        sessionID: rootGetters.sessionId,
        data: cert,
      })
      .catch((err) => {
        state = oldstate;
        commit(Mutations.SetState, state);
        dispatch(Actions.OpenErrorModal, responseError(err));
      });
  },
  async [Actions.DeleteCertificate]({ state, commit, dispatch, rootGetters }, id) {
    const oldstate = cloneDeep(state);

    state.certificates.splice(id, 1);
    state.certificates = state.certificates.map((elem: any, index: number) => ({ ...elem, id: index }));
    commit(Mutations.SetState, state);
    dispatch(Actions.CloseCertificateModal);
    await axios
      .delete(`${(axios.defaults as any).gatewayBase}/candidate/candidate/Certificate`, {
        params: {
          sessionID: rootGetters.sessionId,
          id,
        },
      })
      .catch((err) => {
        state = { ...oldstate };
        commit(Mutations.SetState, state);
        dispatch(Actions.OpenErrorModal, responseError(err));
      });
  },
};

export const getters: GetterTree<EducationState, RootState> = {
  [Getters.SelectedEducation](state) {
    if (state.selectedIndex === -1) return {};
    return state.educations[state.selectedIndex];
  },
  [Getters.SelectedCertificate](state) {
    if (state.selectedIndex === -1) return {};
    return state.certificates[state.selectedIndex];
  },
};
