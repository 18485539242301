
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
// @ts-ignore
import twemoji from 'twemoji';
import emojiFlag from '../utils/emoji-flag';

@Component({
  methods: {
    emojiFlag,
  },
})
class CrEmojiFlag extends Vue {
  @Prop() name: string;
  @Prop({ default: 'i' }) tag: string;
  @Prop() size: string;

  @Prop({ type: Boolean }) xs: boolean;
  @Prop({ type: Boolean }) sm: boolean;
  @Prop({ type: Boolean }) md: boolean;
  @Prop({ type: Boolean }) lg: boolean;

  onClick(e: any) {
    this.$emit('click', e);
  }

  get sizeComputed() {
    const { xs, sm, md, lg } = this;
    if (xs) return 'xs';
    if (sm) return 'sm';
    if (md) return 'md';
    if (lg) return 'lg';
    return 'md';
  }

  mounted() {
    twemoji.parse(this.$el, {
      folder: 'svg',
      ext: '.svg',
    });
  }
}
export default CrEmojiFlag;
