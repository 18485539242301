/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';
import { responseError } from '../utils/response-error';

enum Mutations {
  SetState = '[friends] set state',
  SetError = '[friends] set error',
  SetWrongEmails = '[friends] set wrong emails',
}

enum Actions {
  GetFriendsToInvite = '[friends] get friends to invite',
  SendInvites = '[friends] send invites',
  UnsetWrongEmails = '[friends] unset wrong emails',
}

enum Getters {
  FriendsToInvite = '[friends] friends to invite',
}

export interface FriendToInviteRepo {
  name: string;
  score: number;
  updatedAt: string;
}
export interface FriendToInvite {
  id: string;
  name: string;
  email?: string;
  avatar: string;
  sources: string[]; // "gitgub" or "gitlab"
  invitedBefore?: boolean;
  canInvite?: boolean;
  country?: string;
  developerTitle?: string;
  username?: string;
  commonRepos: FriendToInviteRepo[];

  editing?: boolean;
  changedEmail?: string;
}

export const friends = {
  actions: prefixStoreEnums('friends', Actions),
  getters: prefixStoreEnums('friends', Getters),
  mutations: prefixStoreEnums('friends', Mutations),
};

interface FriendsState {
  errorMessage: string;
  wrongEmails: any[];
  friendsToInvite: FriendToInvite[];
}

const initialState: FriendsState = {
  errorMessage: '',
  wrongEmails: [],
  friendsToInvite: [],
};

export const state = () => ({ ...initialState });

export const mutations: MutationTree<FriendsState> = {
  [Mutations.SetState]: (state, payload: FriendsState) => {
    state = { ...payload };
  },
  [Mutations.SetError]: (state, payload) => {
    state.errorMessage = payload;
  },
  [Mutations.SetWrongEmails]: (state, wrongEmails: any[]) => {
    state.wrongEmails = wrongEmails;
  },
};

export const actions: ActionTree<FriendsState, RootState> = {
  [Actions.UnsetWrongEmails]({ commit }) {
    commit(Mutations.SetWrongEmails, []);
  },
  [Actions.GetFriendsToInvite]({ state, commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${(axios.defaults as any).gatewayBase}/friends/invite`, {
          params: {
            sid: rootGetters.sessionId,
          },
        })
        .then((res) => {
          const friendInvites: any[] = [];
          res.data.friends.forEach((friend: any) => {
            const sourceRepos: FriendToInviteRepo[] = [];
            friend.sources.forEach((source: any) => {
              const r: FriendToInviteRepo = {
                name: source.reponame,
                score: source.score,
                updatedAt: new Date().toJSON(),
              };
              sourceRepos.push(r);
            });
            const friendInvite: FriendToInvite = {
              id: friend.email,
              name: friend.name,
              email: friend.email,
              avatar: friend.avatar,
              sources: friend.sources.map((s: any) => s.provider),
              invitedBefore: friend.invitedBefore,
              canInvite: friend.canInvite,
              username: friend.username,
              country: friend.country,
              developerTitle: friend.DevTitle,
              commonRepos: sourceRepos,
            };
            friendInvites.push(friendInvite);
          });
          state.friendsToInvite = friendInvites;
          commit(Mutations.SetState, state);
          resolve();
        })
        .catch((err) => {
          this.$sentry.captureException(err);
          commit(Mutations.SetError, responseError(err));
          reject();
        });
    });
  },
  [Actions.SendInvites]({ state, commit, rootGetters }, { emails } = {}) {
    state.friendsToInvite.forEach((friend) => {
      if (emails.filter((f: any) => f.email === friend.email).length) {
        friend.invitedBefore = true;
        friend.canInvite = false;
      }
    });
    commit(Mutations.SetState, state);

    const data = {
      emails,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`${(axios.defaults as any).gatewayBase}/friends/invite`, data, {
          params: {
            sid: rootGetters.sessionId,
          },
        })
        .then((res) => {
          if (res.data && res.data.wrongEmails && res.data.wrongEmails.length) {
            commit(Mutations.SetWrongEmails, res.data.wrongEmails);
            reject();
            return;
          }
          resolve();
        })
        .catch((err) => {
          commit(Mutations.SetError, responseError(err));
          reject();
        });
    });
  },
};

export const getters: GetterTree<FriendsState, RootState> = {
  [Getters.FriendsToInvite](state) {
    return state.friendsToInvite;
  },
};
