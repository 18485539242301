import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

localize({
  en: {
    messages: {
      required: 'The {_field_} field is required',
      url: 'The {_field_} field must be a valid URL',
      linkedin: 'The {_field_} field must be a valid linkedin URL',
      email: 'The {_field_} field must be a valid email',
      // confirmed: '', // ???
      // @ts-ignore
      min: (name, { length }) => `The ${name} field must have at least ${length} characters`,
      // @ts-ignore
      max: (name, { length }) => `The ${name} field must have no more than ${length} characters`,
    },
  },
});

Object.keys(rules).forEach((rule: any) => {
  // @ts-ignore
  extend(rule, rules[rule]);
});

const pattern = new RegExp(
  '(https?:\\/\\/)' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
); // fragment locator

const patternWithProtocolEnforced = new RegExp(
  '(https:\\/\\/)' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
); // fragment locator

extend('url', {
  validate: (str) => {
    return !!pattern.test(str);
  },
});

extend('linkedin', {
  validate: (str) => {
    return str.includes('linkedin.com') && !!patternWithProtocolEnforced.test(str);
  },
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
