import { AxiosError } from 'axios';

export function responseError(err: AxiosError, defaultMessage?: string) {
  let errMessage;
  if (err && err.response && err.response.data) {
    const { detail, details, message } = err.response.data;
    if (detail) {
      if (typeof detail === 'string' && detail.includes('{') && detail.includes('}')) {
        try {
          const detailObj = JSON.parse(detail);
          if (detailObj.detail) {
            errMessage = `The following error occurred: ${detailObj.detail}`;
          } else {
            errMessage = detail;
          }
        } catch (_err) {
          errMessage = detail;
        }
      } else {
        errMessage = detail;
      }
    } else if (details) errMessage = details;
    else if (message) errMessage = message;
  }
  if (!errMessage && defaultMessage) {
    errMessage = defaultMessage;
  }
  if (!errMessage && err && err.message) {
    errMessage = err.message;
  }
  if (!errMessage) {
    errMessage = err;
  }
  return errMessage;
}

export function responseErrorMessage(err: AxiosError) {
  return err.message;
}

export function responseErrorCode(err: AxiosError) {
  let code;
  if (err && err.response && err.response.status) {
    code = err.response.status;
  } else if (err && err.code) {
    code = err.code;
    // @ts-ignore
  } else if (err && err.response && err.response.code) {
    // @ts-ignore
    code = err.response.code;
  }
  if (code && typeof code === 'string') {
    code = parseInt(code, 10);
  }
  return code;
}

export function responseErrorMicroCode(error: AxiosError): number {
  if (error && error.response && error.response.data && error.response.data.detail) {
    const { code } = JSON.parse(error.response.data.detail);
    return code;
  }

  return -1;
}
