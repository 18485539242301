/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';
import { responseError, responseErrorMicroCode } from '../utils/response-error';

enum Mutations {
  OpenModal = '[gitlab] open modal',
  CloseModal = '[gitlab] close modal',
  OpenErrorModal = '[gitlab] open error modal',
  CloseErrorModal = '[gitlab] close error modal',

  SetRepos = '[gitlab] set repos',
  SetStep = '[gitlab] set step',
  SetSelected = '[gitlab] set selected',

  SetLoading = '[gitlab] set loading',
}

enum Actions {
  OpenModal = '[gitlab] open modal action',
  CloseModal = '[gitlab] close modal action',
  OpenErrorModal = '[gitlab] open error modal action',
  CloseErrorModal = '[gitlab] close error modal action',

  StartGitlabAuth = '[gitlab] start gitlab auth action',
  StartGitlabSelfHostedAuth = '[gitlab] start gitlab self-hosted auth action',
  SendCallbackToServer = '[gitlab] send callback to server action',

  StartPollingData = '[gitlab] start polling data action',
  PollOnce = '[gitlab] poll once action',
  StopPollingData = '[gitlab] stop polling data action',

  SetStep = '[gitlab] set step action',
  SetSelected = '[gitlab] set selected action',
}

enum Getters {
  IsFinished = '[gitlab] is finished getter',
  IsGitlabComLinked = '[gitlab] is gitlab.com linked getter',
  SelectedProgress = '[gitlab] selected progress getter',
}

export const gitlab = {
  actions: prefixStoreEnums('gitlab', Actions),
  getters: prefixStoreEnums('gitlab', Getters),
};

interface GitlabState {
  modalOpen: boolean;
  errorModalOpen: boolean;
  error: string;
  polling: number | undefined;
  step: number;
  selected: string;
  loading: boolean;
  repos: {
    totalRepos: number;
    completedRepos: number;
    provider: string;
    jobId: string;
    host: string;
  }[];
}

const initialState: GitlabState = {
  modalOpen: false,
  errorModalOpen: false,
  error: '',
  polling: undefined,
  step: 0,
  selected: '',
  loading: false,
  repos: [],
};

export const state = () => ({ ...initialState });

export const mutations: MutationTree<GitlabState> = {
  [Mutations.OpenModal](state) {
    state.modalOpen = true;
  },
  [Mutations.CloseModal](state) {
    state.modalOpen = false;
  },
  [Mutations.OpenErrorModal](state, payload: string) {
    state.errorModalOpen = true;
    state.error = payload;
  },
  [Mutations.CloseErrorModal](state) {
    state.errorModalOpen = false;
    state.error = '';
  },
  [Mutations.SetRepos](state, payload: any) {
    state.repos = payload;
  },
  [Mutations.SetStep](state, payload: number) {
    state.step = payload;
  },
  [Mutations.SetSelected](state, payload: string) {
    state.selected = payload;
  },
  [Mutations.SetLoading](state, payload: boolean) {
    state.loading = payload;
  },
};

export const actions: ActionTree<GitlabState, RootState> = {
  [Actions.OpenModal]({ commit, dispatch, getters: rootGetters, state }) {
    commit(Mutations.OpenModal);
    if (!rootGetters[Getters.IsFinished] && !state.polling) dispatch(Actions.StartPollingData);
  },
  [Actions.CloseModal]({ commit }) {
    commit(Mutations.CloseModal);
  },
  [Actions.OpenErrorModal]({ commit }, payload: string) {
    commit(Mutations.OpenErrorModal, payload);
  },
  [Actions.CloseErrorModal]({ commit }) {
    commit(Mutations.CloseErrorModal);
  },
  [Actions.StartGitlabAuth]({ dispatch }) {
    axios
      .get(`${(axios.defaults as any).gatewayBase}/oauth/gitlab.com`)
      .then((r) => {
        window.location.href = r.data.url;
      })
      .catch((err) => {
        dispatch(Actions.OpenErrorModal, responseError(err));
        // @ts-ignore
        this.$sentry.captureException(err);
      });
  },
  async [Actions.StartGitlabSelfHostedAuth](
    { dispatch, commit, rootGetters },
    { url, token }: { url: string; token: string },
  ) {
    commit(Mutations.SetLoading, true);
    await axios
      .post(`${(axios.defaults as any).gatewayBase}/oauth/gitlab.com/selfhosted`, {
        sessionID: rootGetters.sessionId,
        url,
        token,
      })
      .then((res) => {
        commit(Mutations.SetLoading, false);
        dispatch(Actions.StartPollingData);
        dispatch(Actions.SetSelected, res.data.jobId);
        dispatch(Actions.SetStep, 2);
      })
      .catch((err) => {
        commit(Mutations.SetLoading, false);
        if (responseErrorMicroCode(err) === 408) {
          dispatch(Actions.OpenErrorModal, 'Adding personal access token timed out. We will now attempt to retry it.');
          dispatch(Actions.CloseModal);
          dispatch('refreshProfile', true, { root: true });
        } else {
          dispatch(Actions.OpenErrorModal, responseError(err, 'Request failed'));
        }
        // @ts-ignore
        this.$sentry.captureException(err);
      });
  },
  [Actions.SendCallbackToServer]({ dispatch, rootGetters }, { code, state, router }) {
    axios
      .post(`${(axios.defaults as any).gatewayBase}/oauth/gitlab.com/callback`, {
        sessionID: rootGetters.sessionId,
        code,
        state,
      })
      .then((res) => {
        dispatch(Actions.SetSelected, res.data.jobId);
        dispatch(Actions.SetStep, 2);
        dispatch(Actions.StartPollingData);
        dispatch(Actions.OpenModal);
        router.push({ name: 'login' });
      })
      .catch((err) => {
        dispatch(Actions.OpenErrorModal, responseError(err));
        router.push({ name: 'login' });
        // @ts-ignore
        this.$sentry.captureException(err);
      });
  },
  // eslint-disable-next-line
  [Actions.StartPollingData]({ state, dispatch, commit, getters, rootGetters }) {
    if (state.polling) return;
    state.polling = window.setInterval(() => {
      axios
        .post(`${(axios.defaults as any).gatewayBase}/job`, {
          sessionID: rootGetters.sessionId,
        })
        .then((res) => {
          const repos = res.data.jobStatus.filter((d: any) => d.provider === 'gitlab.com');
          commit(Mutations.SetRepos, repos);
          if (getters[Getters.IsFinished]) dispatch(Actions.StopPollingData);
        })
        .catch((err) => {
          dispatch(Actions.StopPollingData);
          dispatch(Actions.OpenErrorModal, responseError(err, 'Request failed'));
          // @ts-ignore
          this.$sentry.captureException(err);
        });
    }, 3000);
  },
  [Actions.PollOnce]({ commit, rootGetters }) {
    return axios
      .post(`${(axios.defaults as any).gatewayBase}/job`, {
        sessionID: rootGetters.sessionId,
      })
      .then((res) => {
        const repos = res.data.jobStatus.filter((d: any) => d.provider === 'gitlab.com');
        commit(Mutations.SetRepos, repos);
      });
  },
  [Actions.StopPollingData]({ state }) {
    clearInterval(state.polling);
    state.polling = undefined;
  },
  [Actions.SetStep]({ commit }, payload: number) {
    commit(Mutations.SetStep, payload);
  },
  [Actions.SetSelected]({ commit }, payload: string) {
    commit(Mutations.SetSelected, payload);
  },
};

export const getters: GetterTree<GitlabState, RootState> = {
  [Getters.IsFinished](state) {
    let done = true;
    state.repos.forEach((repo) => {
      if (repo.totalRepos !== repo.completedRepos) done = false;
    });
    return done;
  },
  [Getters.IsGitlabComLinked](state) {
    let found = false;
    state.repos.forEach((repo) => {
      if (repo.host === 'gitlab.com') found = true;
    });
    return found;
  },
  [Getters.SelectedProgress](state) {
    let found = {
      completedRepos: 0,
      totalRepos: 1,
    };
    state.repos.forEach((repo) => {
      if (repo.jobId === state.selected) found = repo;
    });
    return found;
  },
};
