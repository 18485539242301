/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';
import { responseError } from '../utils/response-error';

enum Mutations {
  SetState = '[portfolio] set state',
}

enum Actions {
  OpenAddPortfolioModal = '[portfolio] open add portfolio modal',
  OpenErrorModal = '[portfolio] open error modal',

  OpenEditPortfolioModal = '[portfolio] open edit portfolio modal',

  ClosePortfolioModal = '[portfolio] close portfolio modal',
  CloseErrorModal = '[portfolio] close error modal',

  CreatePortfolio = '[portfolio] create portfolio',

  GetPortfolio = '[portfolio] get portfolio',

  EditPortfolio = '[portfolio] edit portfolio',

  DeletePortfolio = '[portfolio] delete portfolio',
}

enum Getters {
  SelectedPortfolio = '[portfolio] selected portfolio',
}

export const portfolio = {
  actions: prefixStoreEnums('portfolio', Actions),
  getters: prefixStoreEnums('portfolio', Getters),
};

export interface Portfolio {
  id: number;
  title: string;
  role: string;
  description: string;
  picturePreview?: string;
  picture?: string;
  companyName?: string;
  startDate?: string;
  endDate?: string;
  currentlyWorkingHere?: boolean;
  skills?: string[];
  highlightedSkills?: string[];
  demoUrl?: string;
  sourceUrl?: string;
}

interface PortfolioState {
  modalOpen: boolean;
  errorModalOpen: boolean;
  errorMessage: string;
  portfolios: Portfolio[];
  selectedIndex: number;
  mode: 'add' | 'edit';
}

const initialState: PortfolioState = {
  modalOpen: false,
  errorModalOpen: false,
  errorMessage: '',
  portfolios: [],
  selectedIndex: -1,
  mode: 'add',
};

function setDates(portfolio: any) {
  const { startDateMonth, startDateYear, endDateMonth, endDateYear, startDate, endDate } = portfolio;
  if (startDateMonth && startDateYear) {
    portfolio.startDate =
      startDateYear && startDateMonth ? `${startDateYear}-${startDateMonth.toString().padStart(2, '0')}` : undefined;
  } else if (startDate && typeof startDate === 'string' && startDate.length > 7) {
    const [year, month] = startDate.split('-');
    portfolio.startDate = `${year}-${month}`;
  }
  if (endDateMonth && endDateYear) {
    portfolio.endDate =
      endDateYear && endDateMonth ? `${endDateYear}-${endDateMonth.toString().padStart(2, '0')}` : undefined;
  } else if (endDate && typeof endDate === 'string' && endDate.length > 7) {
    const [year, month] = endDate.split('-');
    portfolio.endDate = `${year}-${month}`;
  }

  delete portfolio.startDateMonth;
  delete portfolio.startDateYear;
  delete portfolio.endDateMonth;
  delete portfolio.endDateYear;
}

export const state = () => ({ ...initialState });

export const mutations: MutationTree<PortfolioState> = {
  [Mutations.SetState]: (state, payload: PortfolioState) => {
    state = { ...payload };
  },
};

export const actions: ActionTree<PortfolioState, RootState> = {
  [Actions.OpenAddPortfolioModal]({ state, commit }) {
    state.modalOpen = true;
    state.selectedIndex = -1;
    commit(Mutations.SetState, state);
  },
  [Actions.OpenEditPortfolioModal]({ state, commit }, index) {
    state.mode = 'edit';
    state.selectedIndex = index;
    commit(Mutations.SetState, state);
    state.modalOpen = true;
    commit(Mutations.SetState, state);
  },
  [Actions.ClosePortfolioModal]({ state, commit }) {
    state.modalOpen = false;
    state.mode = 'add';
    state.selectedIndex = -1;
    commit(Mutations.SetState, state);
  },
  [Actions.OpenErrorModal]({ state, commit }, payload) {
    state.errorMessage = payload;
    state.errorModalOpen = true;
    commit(Mutations.SetState, state);
  },
  [Actions.CloseErrorModal]({ state, commit }) {
    state.errorMessage = '';
    state.errorModalOpen = false;
    commit(Mutations.SetState, state);
  },

  [Actions.GetPortfolio]({ state, commit }, username) {
    state.portfolios = [];
    commit(Mutations.SetState, state);

    return this.$axios
      .get(`${(axios.defaults as any).gatewayBase}/account/portfolio`, {
        params: {
          username,
        },
      })
      .then((res) => {
        state.portfolios = res.data.map((elem: Portfolio, index: number) => ({ ...elem, id: index }));
        commit(Mutations.SetState, state);
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      });
  },

  async [Actions.CreatePortfolio]({ state, commit, dispatch, rootGetters }, portfolio) {
    const id = state.portfolios.length;
    setDates(portfolio);
    state.portfolios.push({ ...portfolio, id });
    commit(Mutations.SetState, state);
    dispatch(Actions.ClosePortfolioModal);

    const data = new FormData();
    Object.keys(portfolio).forEach((key) => {
      if (key === 'picturePreview') return;
      data.set(key, portfolio[key]);
    });
    data.set('sessionId', rootGetters.sessionId);

    await axios
      .post(`${(axios.defaults as any).gatewayBase}/account/portfolio`, data)
      .then((res) => {
        if (!res.data || !res.data.picture || !state.portfolios[id]) return;
        state.portfolios[id].picture = res.data.picture;
        commit(Mutations.SetState, state);
      })
      .catch((err) => {
        state.portfolios.pop();
        commit(Mutations.SetState);
        dispatch(Actions.OpenErrorModal, responseError(err));
      });
  },
  async [Actions.EditPortfolio]({ state, commit, dispatch, rootGetters }, portfolio) {
    const oldstate = cloneDeep(state);
    setDates(portfolio);
    const id = portfolio.id;
    Object.assign(state.portfolios[id], portfolio);
    commit(Mutations.SetState, state);
    dispatch(Actions.ClosePortfolioModal);

    const data = new FormData();
    Object.keys(portfolio).forEach((key) => {
      if (key === 'picture' && typeof portfolio[key] === 'string') return;
      data.set(key, portfolio[key]);
    });
    data.set('sessionId', rootGetters.sessionId);

    await axios
      .put(`${(axios.defaults as any).gatewayBase}/account/portfolio`, data)
      .then((res) => {
        if (!res.data || !res.data.picture || !state.portfolios[id]) return;
        state.portfolios[id].picture = res.data.picture;
        commit(Mutations.SetState, state);
      })
      .catch((err) => {
        state = oldstate;
        commit(Mutations.SetState, state);
        dispatch(Actions.OpenErrorModal, responseError(err));
      });
  },
  async [Actions.DeletePortfolio]({ state, commit, dispatch, rootGetters }, id) {
    const oldstate = cloneDeep(state);

    state.portfolios.splice(id, 1);
    state.portfolios = state.portfolios.map((elem: any, index: number) => ({ ...elem, id: index }));
    commit(Mutations.SetState, state);
    dispatch(Actions.ClosePortfolioModal);
    await axios
      .delete(`${(axios.defaults as any).gatewayBase}/account/portfolio`, {
        headers: {
          'X-SESSION-ID': rootGetters.sessionId,
        },
        params: {
          id,
        },
      })
      .catch((err) => {
        state = { ...oldstate };
        commit(Mutations.SetState, state);
        dispatch(Actions.OpenErrorModal, responseError(err));
      });
  },
};

export const getters: GetterTree<PortfolioState, RootState> = {
  [Getters.SelectedPortfolio](state) {
    if (state.selectedIndex === -1) return {};
    return state.portfolios[state.selectedIndex];
  },
};
