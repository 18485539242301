import Vue from 'vue';
import Component from 'vue-class-component';
import { cannyIdentify } from '../utils/canny-identify';

@Component
class RootMounted extends Vue {
  mounted() {
    if (this.$root !== this) return;
    const account = this.$store.state.account;
    if (account) {
      if (account.refreshJobId) {
        this.$store.state.isRefreshing = true;
        this.$store.dispatch('updateRefreshProfileProgress');
      }
      // @ts-ignore
      if (account.userId && window.dataLayer) {
        this.$ga.set('dimension1', account.userId);
        // @ts-ignore
        window.dataLayer.push({
          Username: account.username,
          Email: account.email,
          RegistrationDate: account.registrationDate,
          InterestedInJobs: `|${account.jobsMatchingSkills.join('|')}|`,
        });
        // @ts-ignore
        window.dataLayer.push({ event: 'account-loaded' });
      }
      if (account.userId) {
        cannyIdentify(account);

        // @ts-ignore
        this.$sentry.setUser({
          id: account.userId,
          username: account.username,
          email: account.email,
        });
      }
    }
  }
}

Vue.mixin(RootMounted);
