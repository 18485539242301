/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { differenceInMonths } from 'date-fns';
import { getUTCDate } from '../utils/date';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';

enum Mutations {
  OpenAddModal = '[work experience] modal add open',
  OpenEditModal = '[work experience] modal edit open',
  CloseModal = '[work experience] close modal',
  SetWorkExperience = '[work experience] set work experience',

  ModifyModalForm = '[work experience] modify modal form',

  ShowErrorModal = '[work experience] show error modal',
  CloseErrorModal = '[work experience] close error modal',
}

enum Actions {
  CreateWorkExperience = '[work experience] create action',
  EditWorkExperience = '[work experience] edit action',
  DeleteWorkExperience = '[work experience] delete action',
}

enum Getters {
  WorkExperience = '[work experience] work experience getter',
}

export const workexperience = {
  mutations: prefixStoreEnums('workexperience', Mutations),
  actions: prefixStoreEnums('workexperience', Actions),
  getters: prefixStoreEnums('workexperience', Getters),
};

export interface WorkExperience {
  id?: number;
  companyName: string;
  companyLogoURL: string;
  currentlyWorkingHere: boolean;
  description: string;
  endDate: string;
  location: string;
  startDate: string;
  title: string;
  highlightedTechnologies?: string[];
  otherTechnologies?: string[];
  source?: string;
}

export interface DisplayableWorkExperience {
  companyName: string;
  companyLogoURL: string;
  totalMonths?: number;
  titles: {
    originalIndex: number;
    title: string;
    description: string;
    startDate: string;
    endDate: string;
    location: string;
    currentlyWorkingHere: boolean;
    highlightedTechnologies?: string[];
    otherTechnologies?: string[];
  }[];
}

interface WorkExperienceModalState {
  type: 'add' | 'edit';
  selectedID?: number; // defined if type === 'edit'
  [fields: string]: any;
}

interface WorkExperienceState {
  modalOpen: boolean;
  errorModalOpen: boolean;
  errorMessage: string;
  workExperiences: WorkExperience[];
  displayableWorkExperiences: DisplayableWorkExperience[];
  companyMapping: {
    // this is used to map a company to a displayableWorkExperience item by index
    [companyName: string]: number;
  };
  modal: WorkExperienceModalState;
}

const initialModalState: WorkExperienceModalState = {
  type: 'add',
  title: '',
  companyLogo: '',
  companyName: '',
  location: '',
  startDateMonth: undefined,
  startDateYear: undefined,
  endDateMonth: undefined,
  endDateYear: undefined,
  currentlyWorkingHere: false,
  description: '',
  highlightedTechnologies: [],
  otherTechnologies: [],
};

const initialState: WorkExperienceState = {
  modalOpen: false,
  errorModalOpen: false,
  errorMessage: '',
  workExperiences: [],
  displayableWorkExperiences: [],
  companyMapping: {},
  modal: {
    type: 'add',
    title: '',
    companyLogo: '',
    companyName: '',
    location: '',
    startDateMonth: '',
    startDateYear: '',
    endDateMonth: '',
    endDateYear: '',
    currentlyWorkingHere: '',
    description: '',
    highlightedTechnologies: [],
    otherTechnologies: [],
  },
};

export const state = () => ({ ...initialState });

export const mutations: MutationTree<WorkExperienceState> = {
  [Mutations.SetWorkExperience](state, payload: WorkExperience[]) {
    state.workExperiences = payload;
    state.displayableWorkExperiences = [];
    state.companyMapping = {};

    let originalIndex = 0;
    payload.forEach((job) => {
      if (job.companyName in state.companyMapping) {
        state.displayableWorkExperiences[state.companyMapping[job.companyName]] = {
          ...state.displayableWorkExperiences[state.companyMapping[job.companyName]],
          titles: [
            ...state.displayableWorkExperiences[state.companyMapping[job.companyName]].titles,
            {
              originalIndex: originalIndex += 1,
              title: job.title,
              description: job.description,
              startDate: job.startDate,
              endDate: job.endDate,
              location: job.location,
              currentlyWorkingHere: job.currentlyWorkingHere,
              highlightedTechnologies: job.highlightedTechnologies,
              otherTechnologies: job.otherTechnologies,
            },
          ],
        };
      } else {
        state.displayableWorkExperiences = [
          ...state.displayableWorkExperiences,
          {
            companyName: job.companyName,
            companyLogoURL: job.companyLogoURL,
            titles: [
              {
                originalIndex: originalIndex += 1,
                title: job.title,
                description: job.description,
                startDate: job.startDate,
                endDate: job.endDate,
                location: job.location,
                currentlyWorkingHere: job.currentlyWorkingHere,
                highlightedTechnologies: job.highlightedTechnologies,
                otherTechnologies: job.otherTechnologies,
              },
            ],
          },
        ];
        state.companyMapping[job.companyName] = state.displayableWorkExperiences.length - 1;
      }
    });
  },
  [Mutations.OpenAddModal](state) {
    state.modalOpen = true;
    state.modal = {
      ...initialModalState,
    };
  },
  [Mutations.OpenEditModal](state, payload: number) {
    state.modalOpen = true;
    state.modal = {
      type: 'edit',
      selectedID: payload,
      companyName: state.workExperiences[payload].companyName,
      companyLogo: state.workExperiences[payload].companyLogoURL,
      title: state.workExperiences[payload].title,
      currentlyWorkingHere: state.workExperiences[payload].currentlyWorkingHere,
      description: state.workExperiences[payload].description,
      location: state.workExperiences[payload].location,
      startDateMonth: getUTCDate(state.workExperiences[payload].startDate).getMonth() + 1,
      startDateYear: getUTCDate(state.workExperiences[payload].startDate).getFullYear(),
      endDateMonth: getUTCDate(state.workExperiences[payload].endDate).getMonth() + 1,
      endDateYear: getUTCDate(state.workExperiences[payload].endDate).getFullYear(),
      highlightedTechnologies: state.workExperiences[payload].highlightedTechnologies,
      otherTechnologies: state.workExperiences[payload].otherTechnologies,
    };
  },
  [Mutations.CloseModal](state) {
    state.modalOpen = false;
  },
  [Mutations.ModifyModalForm](state, payload: { field: string; value: any }) {
    state.modal[payload.field] = payload.value;
  },
  [Mutations.ShowErrorModal](state, payload: string) {
    state.errorMessage = payload;
    state.errorModalOpen = true;
  },
  [Mutations.CloseErrorModal](state) {
    state.errorMessage = '';
    state.errorModalOpen = false;
  },
};

function getExperienceFromModalState(modalState: WorkExperienceModalState): WorkExperience {
  let startDate = modalState.startDate;
  let endDate = modalState.endDate;
  if (typeof startDate === 'undefined') {
    startDate = new Date(
      `${modalState.startDateYear}-${modalState.startDateMonth.toString().padStart(2, '0')}`,
    ).toISOString();
  } else if (!startDate) {
    startDate = new Date();
    startDate = startDate.toISOString();
  }
  if (!endDate) {
    endDate = new Date();
    if (!modalState.currentlyWorkingHere && modalState.endDateYear && modalState.endDateMonth) {
      endDate = new Date(`${modalState.endDateYear}-${modalState.endDateMonth.toString().padStart(2, '0')}`);
    }
    endDate = endDate.toISOString();
  }
  const workExperience = {
    currentlyWorkingHere: modalState.currentlyWorkingHere,
    companyName: modalState.companyName,
    location: modalState.location,
    startDate,
    endDate,
    companyLogoURL: modalState.companyLogo,
    description: modalState.description,
    title: modalState.title,
    highlightedTechnologies: modalState.highlightedTechnologies,
    otherTechnologies: modalState.otherTechnologies,
    source: modalState.source,
  };
  return workExperience;
}

export const actions: ActionTree<WorkExperienceState, RootState> = {
  [Actions.CreateWorkExperience]({ state, commit, rootGetters }, customWorkExperience) {
    const workExperience = getExperienceFromModalState(customWorkExperience || state.modal);
    if (!customWorkExperience) {
      state.workExperiences.push(workExperience);
      commit(Mutations.SetWorkExperience, state.workExperiences);
      commit(Mutations.CloseModal);
    }
    return axios
      .post(`${(axios.defaults as any).gatewayBase}/account/workexperience`, {
        sessionID: rootGetters.sessionId,
        workExperience,
      })
      .catch((err) => {
        state.workExperiences.pop();
        commit(Mutations.SetWorkExperience, state.workExperiences);
        commit(Mutations.ShowErrorModal, 'Unable to update this work experience, please try again later.');
        this.$sentry.captureException(err);
        return Promise.reject(err);
      });
  },
  async [Actions.EditWorkExperience]({ state, commit, rootGetters }) {
    const workExperience = getExperienceFromModalState(state.modal);
    const selectedID = state.modal.selectedID as number;
    const oldWorkExperience = { ...state.workExperiences[selectedID] };
    state.workExperiences[selectedID] = workExperience;
    commit(Mutations.SetWorkExperience, state.workExperiences);
    commit(Mutations.CloseModal);
    await axios
      .put(`${(axios.defaults as any).gatewayBase}/account/workexperience`, {
        sessionID: rootGetters.sessionId,
        index: selectedID,
        workExperience,
      })
      .catch((err) => {
        state.workExperiences[selectedID] = oldWorkExperience;
        commit(Mutations.SetWorkExperience, state.workExperiences);
        commit(Mutations.ShowErrorModal, 'Unable to update this work experience, please try again later.');
        this.$sentry.captureException(err);
      });
  },

  async [Actions.DeleteWorkExperience]({ state, commit, rootGetters }, customId: number) {
    const id = typeof customId === 'undefined' ? state.modal.selectedID : customId;
    const isCustomId = typeof customId !== 'undefined';
    const oldWorkExperience = JSON.parse(JSON.stringify([...state.workExperiences]));
    let oldModal: any;
    if (!isCustomId) {
      oldModal = JSON.parse(JSON.stringify(state.modal));
      commit(Mutations.CloseModal);
    }
    if (id !== undefined) state.workExperiences.splice(id, 1);
    commit(Mutations.SetWorkExperience, state.workExperiences);

    await axios
      .delete(`${(axios.defaults as any).gatewayBase}/account/workexperience`, {
        headers: {
          'X-SESSION-ID': rootGetters.sessionId,
        },
        params: {
          index: id,
        },
      })
      .catch((err) => {
        if (!isCustomId) {
          state.modalOpen = true;
          state.modal = oldModal;
        }
        commit(Mutations.SetWorkExperience, oldWorkExperience);
        commit(Mutations.ShowErrorModal, 'Unable to delete this work experience, please try again later.');
        this.$sentry.captureException(err);
      });
  },
};

export const getters: GetterTree<WorkExperienceState, RootState> = {
  [Getters.WorkExperience](state) {
    return state.displayableWorkExperiences
      .map((item) => ({
        ...item,
        titles: item.titles.slice().sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()),
      }))
      .sort((a, b) => new Date(b.titles[0].startDate).getTime() - new Date(a.titles[0].startDate).getTime())
      .map((item) => {
        let totalMonths = 0;
        const length = item.titles.length;
        let endDate = item.titles[length - 1].currentlyWorkingHere
          ? new Date()
          : new Date(item.titles[length - 1].endDate);
        totalMonths += differenceInMonths(endDate, new Date(item.titles[length - 1].startDate));
        for (let i = length - 2; i >= 0; i -= 1) {
          const lastDate = item.titles[i].currentlyWorkingHere ? new Date() : new Date(item.titles[i].endDate);
          if (new Date(item.titles[i].startDate) <= endDate) {
            if (lastDate > endDate) {
              totalMonths += differenceInMonths(lastDate, endDate);
              endDate = lastDate;
            }
          } else {
            totalMonths += differenceInMonths(lastDate, new Date(item.titles[i].startDate));
            endDate = lastDate;
          }
        }
        return { ...item, totalMonths };
      });
  },
};
