interface TooltipParameters {
  /** Tooltip element */
  el?: HTMLElement;
  /** Tooltip text */
  text?: string;
  /** Tooltip className */
  className?: string;

  /** Position */
  position?: string;
  /** Parent container */
  container?: HTMLElement | string;
  /** Target element */
  target?: HTMLElement | string;
  /** Position tooltip based on target element center */
  targetCenter?: boolean;
  /** Triggers, default 'hover' */
  triggers?: string;

  /** Is it disabled */
  disabled?: boolean;

  /** On open callback */
  onOpen?: () => void;
  /** On close callback */
  onClose?: () => void;
}

const defaultParameters: TooltipParameters = {
  text: '',
  position: 'auto',
  container: '#app',
  triggers: 'hover',
};

function setPosition(
  el: HTMLElement,
  targetEl: HTMLElement,
  containerEl: HTMLElement,
  position = 'auto',
  targetCenter = false,
) {
  const arrowEl = el.querySelector('.cr-tooltip-arrow') as HTMLElement;
  el.style.left = '';
  el.style.top = '';
  const { offsetWidth: width, offsetHeight: height } = el;

  const arrowSize = arrowEl.offsetWidth / 2;
  let arrowLeft;
  let arrowTop;

  el.classList.remove('cr-tooltip-left', 'cr-tooltip-right', 'cr-tooltip-top', 'cr-tooltip-bottom');
  arrowEl.classList.remove(
    'cr-tooltip-arrow-left',
    'cr-tooltip-arrow-right',
    'cr-tooltip-arrow-top',
    'cr-tooltip-arrow-bottom',
  );

  const targetRect = targetEl.getBoundingClientRect();

  let { offsetWidth: targetWidth = 0, offsetHeight: targetHeight = 0 } = targetEl;
  if (targetWidth === 0 && targetHeight === 0) {
    targetWidth = targetRect.width;
    targetHeight = targetRect.height;
  }
  let { left: targetLeft, top: targetTop } = targetRect;
  const { offsetWidth: containerWidth, offsetHeight: containerHeight } = containerEl;
  const { left: containerLeft, top: containerTop } = containerEl.getBoundingClientRect();

  if (targetCenter) {
    targetLeft += targetWidth / 2;
    targetTop += targetHeight / 2;
    targetWidth = 0;
    targetHeight = 0;
  }

  const targetOffsetLeft = targetLeft - containerLeft;
  const targetOffsetTop = targetTop - containerTop;

  let [left, top, diff] = [0, 0, 0];

  // Position
  if (position === 'auto') {
    position = 'top';
    if (height + arrowSize < targetOffsetTop) {
      // On top
      top = targetOffsetTop - height - arrowSize;
    } else if (height + arrowSize < containerHeight - targetOffsetTop - targetHeight) {
      // On bottom
      position = 'bottom';
      top = targetOffsetTop + targetHeight + arrowSize;
    } else {
      // On left or right
      position = 'left';
      top = targetHeight / 2 + targetOffsetTop - height / 2;
      diff = top;
      top = Math.max(5, Math.min(top, containerHeight - height - 5));
      diff -= top;
    }
  } else if (position === 'top') {
    top = targetOffsetTop - height - arrowSize;
  } else if (position === 'bottom') {
    top = targetOffsetTop + targetHeight + arrowSize;
  } else {
    top = targetOffsetTop + targetHeight / 2 - height / 2;
    diff = top;
    top = Math.max(5, Math.min(top, containerHeight - height - 5));
    diff -= top;
  }

  // Horizontal Position
  if (position === 'top' || position === 'bottom') {
    left = targetWidth / 2 + targetOffsetLeft - width / 2;
    diff = left;
    left = Math.max(5, Math.min(left, containerWidth - width - 5));
    if (position === 'top') {
      arrowEl.classList.add('cr-tooltip-arrow-bottom');
      el.classList.add('cr-tooltip-top');
    }
    if (position === 'bottom') {
      arrowEl.classList.add('cr-tooltip-arrow-top');
      el.classList.add('cr-tooltip-bottom');
    }
    diff -= left;
    arrowLeft = width / 2 - arrowSize + diff;
    arrowLeft = Math.max(Math.min(arrowLeft, width - arrowSize * 2 - 13), 13);
    arrowEl.style.top = '';
    arrowEl.style.left = `${arrowLeft}px`;
  } else {
    left = targetOffsetLeft - width - arrowSize;
    arrowEl.classList.add('cr-tooltip-arrow-right');
    el.classList.add('cr-tooltip-left');

    if (left < 5 || left + width > containerWidth || position === 'right') {
      if (left < 5 || position === 'right') left = targetOffsetLeft + targetWidth + arrowSize;
      if (left + width > containerWidth) left = containerWidth - width - 5;
      arrowEl.classList.remove('cr-tooltip-arrow-right');
      el.classList.remove('cr-tooltip-left');
      arrowEl.classList.add('cr-tooltip-arrow-left');
      el.classList.add('cr-tooltip-right');
    }
    arrowTop = height / 2 - arrowSize + diff;
    arrowTop = Math.max(Math.min(arrowTop, height - arrowSize * 2 - 13), 13);
    arrowEl.style.top = `${arrowTop}px`;
    arrowEl.style.left = '';
  }

  // Apply Styles
  el.style.top = `${top}px`;
  el.style.left = `${left}px`;
}

export default function tooltip(parameters = {} as TooltipParameters) {
  const params = { ...defaultParameters, ...parameters } as TooltipParameters;
  Object.keys(params).forEach((key: string) => {
    // @ts-ignore
    if (typeof params[key] === 'undefined' && defaultParameters[key]) {
      // @ts-ignore
      params[key] = defaultParameters[key];
    }
  });
  const { el, container, target, targetCenter, onOpen, onClose, className = '' } = params;

  let { triggers, text, position } = params;

  let disabled = params.disabled;

  if (!target) return {};
  let targetEl = typeof target === 'string' ? (document.querySelector(target) as HTMLElement) : target;
  if (!targetEl) return {};

  // Create tooltip Element
  let parentEl: HTMLElement | null;
  let tooltipEl: HTMLElement;
  if (el) {
    tooltipEl = el;
    parentEl = tooltipEl.parentNode as HTMLElement;
  } else {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('cr-tooltip');
    if (className) {
      tooltipEl.classList.add(className);
    }
    tooltipEl.innerHTML = `
      <div class="cr-tooltip-arrow"></div>
      <div class="cr-tooltip-content">${text}</div>
    `;
  }
  tooltipEl.setAttribute('aria-hidden', 'true');

  let opened = false;
  let closeTimeout: number;

  function getContainerEl() {
    let containerEl: HTMLElement =
      typeof container === 'string' ? (document.querySelector(container) as HTMLElement) : (container as HTMLElement);
    // @ts-ignore
    if (!containerEl) containerEl = document.getElementById('app') as HTMLElement;
    // fallback to body
    if (!containerEl) containerEl = document.body;
    return containerEl;
  }

  function open() {
    if (opened || disabled) return;
    opened = true;
    getContainerEl().appendChild(tooltipEl);
    tooltipEl.removeAttribute('aria-hidden');
    tooltipEl.classList.add('cr-tooltip-in');
    setPosition(tooltipEl, targetEl, getContainerEl(), position, targetCenter);
    // eslint-disable-next-line
    window.addEventListener('resize', onWindowResize);
    if (onOpen) onOpen();
  }

  function close() {
    if (!opened) return;
    opened = false;
    tooltipEl.setAttribute('aria-hidden', 'true');
    tooltipEl.classList.remove('cr-tooltip-in');
    const containerEl = getContainerEl();
    if (parentEl) {
      parentEl.appendChild(tooltipEl);
    } else if (containerEl && tooltipEl.parentNode === containerEl) {
      containerEl.removeChild(tooltipEl);
    }
    // eslint-disable-next-line
    window.removeEventListener('resize', onWindowResize);
    if (onClose) onClose();
  }

  function enable() {
    disabled = false;
  }

  function disable() {
    disabled = true;
  }

  function onWindowResize() {
    setPosition(tooltipEl, targetEl, getContainerEl(), position, targetCenter);
  }

  function onMouseEnter() {
    clearTimeout(closeTimeout);
    open();
  }
  function onMouseLeave() {
    // closeTimeout = window.setTimeout(() => {
    close();
    // }, 100);
  }

  function onDocumentClick(e: MouseEvent) {
    if (!opened) return;
    const eventTargetEl = e.target as HTMLElement;
    if (eventTargetEl === targetEl || targetEl.contains(eventTargetEl)) return;
    if (eventTargetEl === tooltipEl || tooltipEl.contains(eventTargetEl)) return;
    if (!opened) return;
    close();
  }

  function onClick() {
    if (opened) close();
    else open();
  }

  function attachEvents() {
    if (triggers === 'manual') return;
    if (triggers === 'hover') {
      targetEl.addEventListener('mouseenter', onMouseEnter);
      targetEl.addEventListener('mouseleave', onMouseLeave);
    }
    if (triggers === 'click') {
      targetEl.addEventListener('click', onClick);
      document.addEventListener('click', onDocumentClick);
    }
  }
  function detachEvents() {
    if (triggers === 'manual') return;
    targetEl.removeEventListener('mouseenter', onMouseEnter);
    targetEl.removeEventListener('mouseleave', onMouseLeave);
    targetEl.removeEventListener('click', onClick);
    document.removeEventListener('click', onDocumentClick);
  }

  function update(updateParams = {} as TooltipParameters) {
    if (updateParams.position) {
      position = updateParams.position;
    }
    if (typeof updateParams.text !== 'undefined') {
      text = updateParams.text;
      if (tooltipEl && !params.el) {
        // @ts-ignore
        tooltipEl.querySelector('.cr-tooltip-content').innerHTML = text;
      }
    }
    if (updateParams.triggers && updateParams.triggers !== triggers) {
      triggers = updateParams.triggers;
      detachEvents();
      attachEvents();
    }
    if (updateParams.target && updateParams.target !== targetEl) {
      // @ts-ignore
      targetEl = updateParams.target;
    }
    if (opened) {
      setPosition(tooltipEl, targetEl, getContainerEl(), position, targetCenter);
    }
  }

  function destroy() {
    detachEvents();
  }

  attachEvents();

  // Return notification object
  return {
    el: tooltipEl,
    open,
    close,
    destroy,
    update,
    enable,
    disable,
  };
}
