/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';
import { responseError, responseErrorMicroCode } from '../utils/response-error';

enum Mutations {
  OpenModal = '[bitbucket] open modal',
  CloseModal = '[bitbucket] close modal',
  OpenErrorModal = '[bitbucket] open error modal',
  CloseErrorModal = '[bitbucket] close error modal',

  SetRepos = '[bitbucket] set repos',
  SetStep = '[bitbucket] set step',
  SetSelected = '[bitbucket] set selected',

  SetLoading = '[bitbucket] set loading',
}

enum Actions {
  OpenModal = '[bitbucket] open modal action',
  CloseModal = '[bitbucket] close modal action',
  OpenErrorModal = '[bitbucket] open error modal action',
  CloseErrorModal = '[bitbucket] close error modal action',

  StartBitbucketAuth = '[bitbucket] start bitbucket auth action',
  SendCallbackToServer = '[bitbucket] send callback to server action',

  StartPollingData = '[bitbucket] start polling data action',
  PollOnce = '[bitbucket] poll once action',
  StopPollingData = '[bitbucket] stop polling data action',

  SetStep = '[bitbucket] set step action',
  SetSelected = '[bitbucket] set selected action',
}

enum Getters {
  IsFinished = '[bitbucket] is finished getter',
}

export const bitbucket = {
  actions: prefixStoreEnums('bitbucket', Actions),
  getters: prefixStoreEnums('bitbucket', Getters),
};

interface BitbucketState {
  modalOpen: boolean;
  errorModalOpen: boolean;
  error: string;
  polling: number | undefined;
  step: number;
  selected: string;
  loading: boolean;
  repos: {
    totalRepos: number;
    completedRepos: number;
    provider: string;
    jobId: string;
    host: string;
  };
}

const initialState: BitbucketState = {
  modalOpen: false,
  errorModalOpen: false,
  error: '',
  polling: undefined,
  step: 0,
  selected: '',
  loading: false,
  repos: {
    totalRepos: 0,
    completedRepos: 0,
    provider: 'bitbucket',
    jobId: '',
    host: 'bitbucket.org',
  },
};

export const state = () => ({ ...initialState });

export const mutations: MutationTree<BitbucketState> = {
  [Mutations.OpenModal](state) {
    state.modalOpen = true;
  },
  [Mutations.CloseModal](state) {
    state.modalOpen = false;
  },
  [Mutations.OpenErrorModal](state, payload: string) {
    state.errorModalOpen = true;
    state.error = payload;
  },
  [Mutations.CloseErrorModal](state) {
    state.errorModalOpen = false;
    state.error = '';
  },
  [Mutations.SetRepos](state, payload: any) {
    state.repos = payload;
  },
  [Mutations.SetStep](state, payload: number) {
    state.step = payload;
  },
  [Mutations.SetSelected](state, payload: string) {
    state.selected = payload;
  },
  [Mutations.SetLoading](state, payload: boolean) {
    state.loading = payload;
  },
};

export const actions: ActionTree<BitbucketState, RootState> = {
  [Actions.OpenModal]({ commit, dispatch, getters: rootGetters, state }) {
    commit(Mutations.OpenModal);
    if (!rootGetters[Getters.IsFinished] && !state.polling) dispatch(Actions.StartPollingData);
  },
  [Actions.CloseModal]({ commit }) {
    commit(Mutations.CloseModal);
  },
  [Actions.OpenErrorModal]({ commit }, payload: string) {
    commit(Mutations.OpenErrorModal, payload);
  },
  [Actions.CloseErrorModal]({ commit }) {
    commit(Mutations.CloseErrorModal);
  },
  async [Actions.StartBitbucketAuth]({ dispatch, commit, rootGetters }, { username, appPassword }) {
    commit(Mutations.SetLoading, true);
    await axios
      .post(`${(axios.defaults as any).gatewayBase}/oauth/bitbucket/selfhosted`, {
        sessionID: rootGetters.sessionId,
        url: 'https://bitbucket.org',
        token: `${username}:${appPassword}`,
      })
      .then((res) => {
        commit(Mutations.SetLoading, false);
        dispatch(Actions.SetSelected, res.data.jobId);
        dispatch(Actions.StartPollingData);
        dispatch(Actions.SetStep, 2);
      })
      .catch((err) => {
        commit(Mutations.SetLoading, false);
        if (responseErrorMicroCode(err) === 408) {
          dispatch(
            Actions.OpenErrorModal,
            responseError(err, 'Adding bitbucket request timed out. We will now attempt to retry it.'),
          );
          dispatch(Actions.CloseModal);
          dispatch('refreshProfile', true, { root: true });
        } else {
          dispatch(Actions.OpenErrorModal, responseError(err, 'Request failed'));
        }
        // @ts-ignore
        this.$sentry.captureException(err);
      });
  },
  // eslint-disable-next-line
  [Actions.StartPollingData]({ state, dispatch, commit, getters, rootGetters }) {
    if (state.polling) return;
    commit(Mutations.SetRepos, {
      totalRepos: 1,
      completedRepos: 0,
      provider: 'bitbucket',
      jobId: '',
      host: 'bitbucket.org',
    });
    state.polling = window.setInterval(() => {
      axios
        .post(`${(axios.defaults as any).gatewayBase}/job`, {
          sessionID: rootGetters.sessionId,
        })
        .then((res) => {
          const repos = res.data.jobStatus.filter((d: any) => d.provider === 'bitbucket');
          if (repos.length > 0) {
            commit(Mutations.SetRepos, repos[0]);
          } else {
            dispatch(Actions.StopPollingData);
          }
          if (getters[Getters.IsFinished]) dispatch(Actions.StopPollingData);
        })
        .catch((err) => {
          dispatch(Actions.StopPollingData);
          dispatch(Actions.OpenErrorModal, responseError(err, 'Request failed'));
          // @ts-ignore
          this.$sentry.captureException(err);
        });
    }, 3000);
  },
  [Actions.StopPollingData]({ state }) {
    clearInterval(state.polling);
    state.polling = undefined;
  },
  [Actions.SetStep]({ commit }, payload: number) {
    commit(Mutations.SetStep, payload);
  },
  [Actions.SetSelected]({ commit }, payload: string) {
    commit(Mutations.SetSelected, payload);
  },
};

export const getters: GetterTree<BitbucketState, RootState> = {
  [Getters.IsFinished](state) {
    return state.repos.completedRepos === state.repos.totalRepos;
  },
};
