const CLICK_HANDLER_PROP = '__cr_collapse_toggle_click__';
const STATE_HANDLER_PROP = '__cr_collapse_toggle_state__';

export default {
  bind(el: HTMLElement, binding: any, vnode: any) {
    const ids = [] as string[];
    if (binding.modifiers) {
      Object.keys(binding.modifiers).forEach((id) => {
        ids.push(id);
      });
    }
    if (binding.value) {
      ids.push(binding.value);
    }
    // @ts-ignore
    el[CLICK_HANDLER_PROP] = () => {
      ids.forEach((id) => {
        if (vnode && vnode.context && vnode.context.$root) {
          vnode.context.$root.$emit('cr::collapse::toggle', id);
        }
      });
    };
    // @ts-ignore
    el[STATE_HANDLER_PROP] = (id, state) => {
      if (ids.includes(id)) {
        if (state !== 'opened') {
          el.classList.add('cr-collapse-closed');
        } else {
          el.classList.remove('cr-collapse-closed');
        }
      }
    };
    if (vnode && vnode.context && vnode.context.$root) {
      // @ts-ignore
      vnode.context.$root.$on('cr::collapse::state', el[STATE_HANDLER_PROP]);
    }

    // @ts-ignore
    el.addEventListener('click', el[CLICK_HANDLER_PROP]);
  },
  unbind(el: HTMLElement, binding: any, vnode: any) {
    // @ts-ignore
    if (el[CLICK_HANDLER_PROP]) {
      // @ts-ignore
      el.removeEventListener('click', el[CLICK_HANDLER_PROP]);
    }
    if (vnode && vnode.context && vnode.context.$root) {
      // @ts-ignore
      vnode.context.$root.$off('cr::collapse::state', el[STATE_HANDLER_PROP]);
    }
  },
};
