/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';
import { responseError, responseErrorMicroCode } from '../utils/response-error';

enum Mutations {
  OpenModal = '[github] open modal',
  CloseModal = '[github] close modal',
  SetError = '[github] set error',

  SetRepos = '[github] set repos',
  SetStep = '[github] set step',
  SetSelected = '[github] set selected',
  SetLoading = '[github] set loading',
}

enum Actions {
  OpenModal = '[github] open modal action',
  CloseModal = '[github] close modal action',
  SetError = '[github] set error action',

  StartGitHubAuth = '[github] start github auth action',

  StartPollingData = '[github] start polling data action',
  StopPollingData = '[github] stop polling data action',

  SetStep = '[github] set step action',
  SetSelected = '[github] set selected action',
}

enum Getters {
  IsFinished = '[github] is finished getter',
}

export const github = {
  actions: prefixStoreEnums('github', Actions),
  getters: prefixStoreEnums('github', Getters),
};

interface GithubState {
  modalOpen: boolean;
  error: string;
  polling: number | undefined;
  step: number;
  selected: string;
  loading: boolean;
  repos: {
    totalRepos: number;
    completedRepos: number;
    provider: string;
    jobId: string;
    host: string;
  };
}

const initialState: GithubState = {
  modalOpen: false,
  error: '',
  polling: undefined,
  step: 0,
  selected: '',
  loading: false,
  repos: {
    totalRepos: 0,
    completedRepos: 0,
    provider: 'github.com',
    jobId: '',
    host: 'github.com',
  },
};

export const state = () => ({ ...initialState });

export const mutations: MutationTree<GithubState> = {
  [Mutations.OpenModal](state) {
    state.modalOpen = true;
  },
  [Mutations.CloseModal](state) {
    state.modalOpen = false;
  },
  [Mutations.SetError](state, payload: any) {
    state.error = payload;
  },
  [Mutations.SetRepos](state, payload: any) {
    state.repos = payload;
  },
  [Mutations.SetStep](state, payload: number) {
    state.step = payload;
  },
  [Mutations.SetSelected](state, payload: string) {
    state.selected = payload;
  },
  [Mutations.SetLoading](state, payload: boolean) {
    state.loading = payload;
  },
};

export const actions: ActionTree<GithubState, RootState> = {
  [Actions.OpenModal]({ commit, dispatch, getters: rootGetters, state }) {
    commit(Mutations.OpenModal);
    if (!rootGetters[Getters.IsFinished] && !state.polling) dispatch(Actions.StartPollingData);
  },
  [Actions.CloseModal]({ commit }) {
    commit(Mutations.CloseModal);
  },
  [Actions.SetError]({ commit }, payload: string) {
    commit(Mutations.SetError, payload);
  },
  async [Actions.StartGitHubAuth]({ dispatch, commit, rootGetters }, { token }: { token: string }) {
    commit(Mutations.SetLoading, true);
    await axios
      .post(`${(axios.defaults as any).gatewayBase}/oauth/github_private/selfhosted`, {
        sessionID: rootGetters.sessionId,
        url: 'https://github.com',
        token,
      })
      .then((res) => {
        commit(Mutations.SetLoading, false);
        dispatch(Actions.SetSelected, res.data.jobId);
        dispatch(Actions.StartPollingData);
        dispatch(Actions.SetStep, 2);
      })
      .catch((err) => {
        commit(Mutations.SetLoading, false);
        if (responseErrorMicroCode(err) === 408) {
          dispatch(Actions.SetError, 'Adding personal access token timed out. We will now attempt to retry it.');
          dispatch(Actions.CloseModal);
          dispatch('refreshProfile', true, { root: true });
        } else {
          dispatch(Actions.SetError, responseError(err, 'Request failed'));
        }
        // @ts-ignore
        this.$sentry.captureException(err);
      });
  },
  // eslint-disable-next-line
  [Actions.StartPollingData]({ state, dispatch, commit, getters, rootGetters }) {
    if (state.polling) return;
    commit(Mutations.SetRepos, {
      totalRepos: 1,
      completedRepos: 0,
      provider: 'github.com',
      jobId: '',
      host: 'github.com',
    });
    state.polling = window.setInterval(() => {
      axios
        .post(`${(axios.defaults as any).gatewayBase}/job`, {
          sessionID: rootGetters.sessionId,
        })
        .then((res) => {
          const repos = res.data.jobStatus.filter((d: any) => d.jobId === state.selected);
          if (repos.length) commit(Mutations.SetRepos, repos[0]);
          if (getters[Getters.IsFinished]) dispatch(Actions.StopPollingData);
        })
        .catch((err) => {
          dispatch(Actions.StopPollingData);
          dispatch(Actions.SetError, responseError(err, 'Request failed'));
          // @ts-ignore
          this.$sentry.captureException(err);
        });
    }, 3000);
  },
  [Actions.StopPollingData]({ state }) {
    clearInterval(state.polling);
    state.polling = undefined;
  },
  [Actions.SetStep]({ commit }, payload: number) {
    commit(Mutations.SetStep, payload);
  },
  [Actions.SetSelected]({ commit }, payload: string) {
    commit(Mutations.SetSelected, payload);
  },
};

export const getters: GetterTree<GithubState, RootState> = {
  [Getters.IsFinished](state) {
    return state.repos.completedRepos === state.repos.totalRepos;
  },
};
