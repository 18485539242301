<template>
  <div id="app" class="app-empty">
    <nuxt />
    <portal-target name="root" />
    <refresh-notification />
    <span style="display: none;">
      <span class="beamerTrigger"></span>
    </span>
  </div>
</template>
<script>
  import RefreshNotification from '../components/shared/RefreshNotification.vue';

  export default {
    components: {
      RefreshNotification,
    },
  };
</script>
