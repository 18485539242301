/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import Cookies from 'js-cookie';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';

enum Mutations {
  SetCurrentLeaderboard = '[leaderboard] set current leaderboard',
  SetCountry = '[leaderboard] set country',
  SetCity = '[leaderboard] set city',
  SetTechnology = '[leaderboard] set technology',
  SetCompanies = '[leaderboard] set companies',
  SetSchools = '[leaderboard] set schools',
  SetDevelopers = '[leaderboard] set developers',
  SetAllPages = '[leaderboard] set all pages',
  SetAllResults = '[leaderboard] set all results',
  SetUsersPerPage = '[leaderboard] set usersPerPage',
  SetDevelopersPerPage = '[leaderboard] set developersPerPage',
  SetCurrentPage = '[leaderboard] set currentPage',
  SetLoading = '[leaderboard] set loading',
  SetHasCurrentRecord = '[leaderboard] set current record',
  SetDevelopersHasCurrentRecord = '[leaderboard] set developers current record',
  SetInitialState = '[leaderboard] reset leaderboard',
  SetCompanyUsers = '[leaderboard] set users for company leaderbaord detail modal',
}

enum Actions {
  GetCompanies = '[leaderboard] get all companies from backend',
  GetSchools = '[leaderboard] get all schools from backend',
  GetCurrentCompany = '[leaderboard] get current companies of user from backend',
  GetCurrentSchool = '[leaderboard] get current schools of user from backend',
  StartLoading = '[leaderboard] set loading true',
  EndLoading = '[leaderboard] set loading false',
  HasCurrentRecord = '[leaderboard] has current',
  GetCompanyUsers = '[leaderboard] get users of a company',
  GetDevelopers = '[leaderboard] get developers leaderboard',
  GetGlobalPosition = '[leaderboard] get global position',
  GetGlobalPositionBySkill = '[leaderboard] get global position by skill',
  GetCountryPosition = '[leaderboard] get country position',
  GetCountryPositionBySkill = '[leaderboard] get country position by skill',
  GetCityPosition = '[leaderboard] get city position',
  GetCityPositionBySkill = '[leaderboard] get city position by skill',
}

enum Getters {
  Developers = '[leaderboard] developers getter',
  Companies = '[leaderboard] companies getter',
  Schools = '[leaderboard] schools getter',
  IsLoading = '[leaderboard] loading getter',
  HasCurrentRecord = '[leaderboard] has current getter',
  DevelopersHasCurrentRecord = '[leaderboard] developers has current record',
  UsersPerPage = '[leaderboard] usersPerPage getter',
  DevelopersPerPage = '[leaderboard] developersPerPage getter',
  AllPages = '[leaderboard] allPages getter',
  AllResults = '[leaderboard] allResults getter',
  CurrentPage = '[leaderboard] current page getter',
  CompanyUsers = '[leaderboard] company modal user getter',
  Country = '[leaderboard] developers country',
  City = '[leaderboard] developers city',
  Technology = '[leaderboard] developers technology',
}

export const leaderboard = {
  mutations: prefixStoreEnums('leaderboard', Mutations),
  actions: prefixStoreEnums('leaderboard', Actions),
  getters: prefixStoreEnums('leaderboard', Getters),
};

interface Developer {
  username: string;
  fullname?: string;
  city?: string;
  country?: string;
  score: number;
  isRegistered: boolean;
  rank?: number;
  highlight?: boolean;
  stackoverflow?: string;
}

interface School {
  rank: number;
  name: string;
  members: number;
  average: number;
  total: number;
  highlight: boolean;
  addedTop: boolean;
}

interface Company {
  rank: number;
  name: string;
  logo: string;
  members: number;
  average: number;
  total: number;
  country: string;
  highlight: boolean;
  addedTop: boolean;
}

interface CompanyUser {
  rank: number;
  username: string;
  title: string;
  country: string;
  score: number;
  firstname: string;
  lastname: string;
}

interface LeaderboardState {
  currentLeaderboard: string;
  schools: School[];
  companies: Company[];
  companyUsers: CompanyUser[];
  developers: Developer[];
  country: string | undefined;
  city: string | undefined;
  technology: string | undefined;
  allPages: number;
  allResults: number;
  usersPerPage: number;
  developersPerPage: number;
  currentPage: number;
  hasCurrentRecord: boolean;
  loading: boolean;
  developersHasCurrentRecord: boolean;
}

const initialState: LeaderboardState = {
  currentLeaderboard: '',
  schools: [],
  companies: [],
  companyUsers: [],
  developers: [],
  country: undefined,
  city: undefined,
  technology: undefined,
  allPages: 1,
  allResults: 0,
  usersPerPage: 20,
  developersPerPage: 20,
  currentPage: 1,
  hasCurrentRecord: false,
  loading: false,
  developersHasCurrentRecord: false,
};

export const state = () => ({ ...initialState });

export const mutations: MutationTree<LeaderboardState> = {
  [Mutations.SetCurrentLeaderboard](state, leaderboard: string) {
    state.currentLeaderboard = leaderboard;
  },
  [Mutations.SetCompanies](state, companies: Company[]) {
    state.companies = companies;
  },
  [Mutations.SetSchools](state, schools: School[]) {
    state.schools = schools;
  },
  [Mutations.SetDevelopers](state, developers: Developer[]) {
    state.developers = developers;
  },
  [Mutations.SetCountry](state, country: string | undefined) {
    state.country = country;
  },
  [Mutations.SetCity](state, city: string | undefined) {
    state.city = city;
  },
  [Mutations.SetTechnology](state, technology: string | undefined) {
    state.technology = technology;
  },
  [Mutations.SetAllPages](state, allPages: number) {
    state.allPages = allPages;
  },
  [Mutations.SetAllResults](state, allResults: number) {
    state.allResults = allResults;
  },
  [Mutations.SetUsersPerPage](state, usersPerPage: number) {
    state.usersPerPage = usersPerPage;
  },
  [Mutations.SetDevelopersPerPage](state, developersPerPage: number) {
    state.developersPerPage = developersPerPage;
  },
  [Mutations.SetCurrentPage](state, currentPage: number) {
    state.currentPage = currentPage;
  },
  [Mutations.SetLoading](state, loading: boolean) {
    state.loading = loading;
  },
  [Mutations.SetHasCurrentRecord](state, record: boolean) {
    state.hasCurrentRecord = record;
  },
  [Mutations.SetDevelopersHasCurrentRecord](state, record: boolean) {
    state.developersHasCurrentRecord = record;
  },
  [Mutations.SetCompanyUsers](state, users: CompanyUser[]) {
    state.companyUsers = users;
  },
  // eslint-disable-next-line
  [Mutations.SetInitialState]() {
    Object.assign(state, initialState);
  },
};

export const actions: ActionTree<LeaderboardState, RootState> = {
  async [Actions.GetCompanies](
    { state, commit, dispatch, rootState },
    { page, perpage } = {} as { page?: string; perpage?: string },
  ) {
    // Set leaderboard
    if (state.currentLeaderboard !== 'company') {
      commit(Mutations.SetInitialState);
      commit(Mutations.SetCurrentLeaderboard, 'company');
    }
    // Set store per page
    const perPage = perpage ? Math.max(Math.min(perpage, 50), 20) : 20;
    commit(Mutations.SetUsersPerPage, perPage);
    commit(Mutations.SetCurrentPage, page ? parseInt(page, 10) : 1);

    commit(Mutations.SetLoading, true);

    const response = await this.$axios.post(`${(this.$axios.defaults as any).gatewayBase}/leaderboard/company`, {
      size: state.usersPerPage,
      offset: (state.currentPage - 1) * state.usersPerPage,
    });
    if (!response.data.companies) {
      commit(Mutations.SetCompanies, []);
      commit(Mutations.SetAllPages, 1);
    } else {
      const companies = response.data.companies;
      for (let ui = 0; ui < companies.length; ui += 1) {
        if (!companies[ui].total) {
          companies[ui].total = 0;
        }
        if (!companies[ui].average) {
          companies[ui].average = 0;
        }
        companies[ui].rank = (state.currentPage - 1) * state.usersPerPage + ui + 1;
      }
      commit(Mutations.SetCompanies, companies);
      commit(Mutations.SetAllPages, Math.ceil(response.data.totalCompanies / state.usersPerPage));
    }

    if (rootState.account.username) {
      // @ts-ignore
      if (rootState.workexperience.workExperiences.length > 0) {
        // @ts-ignore
        rootState.workexperience.workExperiences.forEach((workExperience: any) => {
          if (workExperience.currentlyWorkingHere === true) {
            commit(Mutations.SetHasCurrentRecord, true);
          }
        });
      }
      if (state.hasCurrentRecord) {
        // Find companies user has and is in this page of the leaderboard
        state.companies.forEach((company: any) => {
          // @ts-ignore
          rootState.workexperience.workExperiences.forEach((workExperience: any) => {
            if (workExperience.currentlyWorkingHere === true && workExperience.companyName === company.name) {
              company.highlight = true;
            }
          });
        });
        // Find companies user has and not in this page of leaderboard (to put them on the top of the page)
        const sentPreviously: string[] = [];
        // @ts-ignore
        rootState.workexperience.workExperiences.forEach((workExperience: any) => {
          let isOnPage: boolean = false;
          state.companies.forEach((company: any) => {
            if (workExperience.companyName === company.name) {
              isOnPage = true;
            }
          });
          if (!isOnPage && workExperience.currentlyWorkingHere === true) {
            if (sentPreviously.indexOf(workExperience.companyName) < 0) {
              sentPreviously.push(workExperience.companyName);
            }
          }
        });
        if (sentPreviously.length) {
          await Promise.all(sentPreviously.map((company) => dispatch(Actions.GetCurrentCompany, company)));
        }
        commit(Mutations.SetCompanies, state.companies);
      }
    }

    commit(Mutations.SetLoading, false);
  },
  async [Actions.GetSchools](
    { state, commit, rootState, dispatch },
    { page, perpage } = {} as { page?: string; perpage?: string },
  ) {
    // Set leaderboard
    if (state.currentLeaderboard !== 'education') {
      commit(Mutations.SetInitialState);
      commit(Mutations.SetCurrentLeaderboard, 'education');
    }
    // Set store per page
    const perPage = perpage ? Math.max(Math.min(perpage, 50), 20) : 20;
    commit(Mutations.SetUsersPerPage, perPage);
    commit(Mutations.SetCurrentPage, page ? parseInt(page, 10) : 1);

    commit(Mutations.SetLoading, true);

    const response = await this.$axios.post(`${(this.$axios.defaults as any).gatewayBase}/leaderboard/education`, {
      size: state.usersPerPage,
      offset: (state.currentPage - 1) * state.usersPerPage,
    });
    if (!response.data.schools) {
      commit(Mutations.SetSchools, []);
      commit(Mutations.SetAllPages, 1);
    } else {
      const schools = response.data.schools;
      for (let ui = 0; ui < schools.length; ui += 1) {
        if (!schools[ui].total) {
          schools[ui].total = 0;
        }
        if (!schools[ui].average) {
          schools[ui].average = 0;
        }
        schools[ui].rank = (state.currentPage - 1) * state.usersPerPage + ui + 1;
      }
      commit(Mutations.SetSchools, schools);
      commit(Mutations.SetAllPages, Math.ceil(response.data.totalSchools / state.usersPerPage));
    }

    // Assign current education to user
    if (rootState.account.username) {
      // @ts-ignore
      if (rootState.education && rootState.education.educations) {
        // @ts-ignore
        if (rootState.education.educations.length > 0) {
          commit(Mutations.SetHasCurrentRecord, true);
        }
      }

      if (state.hasCurrentRecord) {
        // Find schools user has and is in this page of the leaderboard
        state.schools.forEach((school: any) => {
          // @ts-ignore
          rootState.education.educations.forEach((education: any) => {
            if (education.school === school.name) {
              school.highlight = true;
            }
          });
        });
        // Find schools user has and not in this page of leaderboard (to put them on the top of the page)
        const schoolsToLoad: string[] = [];
        // @ts-ignore
        rootState.education.educations.forEach((education: any) => {
          let isOnPage: boolean = false;
          state.schools.forEach((school: any) => {
            if (education.school === school.name) {
              isOnPage = true;
            }
          });
          if (!isOnPage) {
            schoolsToLoad.push(education.school);
          }
        });
        if (schoolsToLoad.length) {
          await Promise.all(schoolsToLoad.map((school) => dispatch(Actions.GetCurrentSchool, school)));
        }
        commit(Mutations.SetSchools, state.schools);
      }
    }

    commit(Mutations.SetLoading, false);
  },
  async [Actions.GetDevelopers](
    { state, commit, rootState, dispatch, rootGetters },
    { country, city, technology, page, perpage } = {},
  ) {
    // Set leaderboard
    if (state.currentLeaderboard !== 'developer') {
      commit(Mutations.SetInitialState);
      commit(Mutations.SetCurrentLeaderboard, 'developer');
    }
    // Set store per page
    const perPage = perpage ? Math.max(Math.min(perpage, 50), 20) : 20;
    commit(Mutations.SetCountry, country || undefined);
    commit(Mutations.SetCity, city || undefined);
    commit(Mutations.SetTechnology, technology || undefined);
    commit(Mutations.SetDevelopersPerPage, perPage);
    commit(Mutations.SetCurrentPage, page ? parseInt(page, 10) : 1);

    commit(Mutations.SetLoading, true);

    const response = await this.$axios.get(`${(this.$axios.defaults as any).gatewayBase}/leaderboard/developer`, {
      params: {
        country: state.country,
        city: state.city,
        technology: state.technology,
        size: state.developersPerPage,
        offset: (state.currentPage - 1) * state.developersPerPage,
      },
      headers: {
        'X-SESSION-ID': rootGetters.sessionId || '',
      },
    });

    if (!response.data.users) {
      commit(Mutations.SetDevelopers, []);
      commit(Mutations.SetAllPages, 1);
      commit(Mutations.SetAllResults, 0);
    } else {
      const developers = response.data.users;
      for (let ui = 0; ui < developers.length; ui += 1) {
        developers[ui].rank = (state.currentPage - 1) * state.developersPerPage + ui + 1;
        if (!developers[ui].fullname) developers[ui].fullname = developers[ui].username;
      }
      commit(Mutations.SetDevelopers, developers);
      commit(Mutations.SetAllPages, Math.ceil((response.data.totalUsers || 0) / state.developersPerPage));
      commit(Mutations.SetAllResults, response.data.totalUsers || 0);
    }

    if (rootState.account.username) {
      state.developersHasCurrentRecord = false;
      state.developers.forEach((user) => {
        if (user.username && rootState.account.username.toLowerCase() === user.username.toLowerCase()) {
          user.highlight = true;
          state.developersHasCurrentRecord = true;
        }
      });
      if (!state.developersHasCurrentRecord) {
        const user = {
          rank: '-',
          isRegistered: true,
          highlight: true,
          fullname: rootState.profile.fullName,
          username: rootState.profile.username,
          country: rootState.account.country,
          city: rootState.account.city,
          score: rootState.profile.totalScore,
        };

        let scoresForTech: any = null;
        if (state.technology && (rootState.profile.scoreBySkills || rootState.profile.scoreByLibraries)) {
          scoresForTech =
            rootState.profile.scoreBySkills[state.technology] || rootState.profile.scoreByLibraries[state.technology];
        }

        if (state.country && state.city && state.country === user.country && state.city === user.city) {
          if (state.technology && scoresForTech != null) {
            user.rank = await dispatch(Actions.GetCityPositionBySkill, {
              location: state.city,
              skill: state.technology,
              score: scoresForTech.score,
              username: user.username,
            });
          } else {
            user.rank = await dispatch(Actions.GetCityPosition, {
              location: state.city,
              score: user.score,
              username: user.username,
            });
          }
        } else if (state.country && !state.city && state.country === user.country) {
          if (state.technology && scoresForTech != null) {
            user.rank = await dispatch(Actions.GetCountryPositionBySkill, {
              location: state.country,
              skill: state.technology,
              score: scoresForTech.score,
              username: user.username,
            });
          } else {
            user.rank = await dispatch(Actions.GetCountryPosition, {
              location: state.country,
              score: user.score,
              username: user.username,
            });
          }
        } else if (state.country) {
          user.rank = '-';
        } else if (scoresForTech != null) {
          user.rank = await dispatch(Actions.GetGlobalPositionBySkill, {
            skill: state.technology,
            score: scoresForTech.score,
            username: user.username,
          });
        } else {
          user.rank = await dispatch(Actions.GetGlobalPosition, {
            score: user.score,
            username: user.username,
          });
        }

        if (user.rank !== '-') {
          user.rank += 1;
        }

        if (scoresForTech) {
          user.score = scoresForTech.score;
        } else {
          user.score = 0;
        }
        // @ts-ignore
        state.developers.unshift(user);
      }
      commit(Mutations.SetDevelopers, state.developers);
    }

    commit(Mutations.SetLoading, false);
  },
  async [Actions.StartLoading]({ commit }) {
    commit(Mutations.SetLoading, true);
  },
  async [Actions.EndLoading]({ commit }) {
    commit(Mutations.SetLoading, false);
  },
  async [Actions.HasCurrentRecord]({ commit }, hasCurrentRecord: boolean) {
    commit(Mutations.SetHasCurrentRecord, hasCurrentRecord);
  },
  async [Actions.GetCurrentCompany]({ state }, companyName: string) {
    const response = await this.$axios.post(
      `${(this.$axios.defaults as any).gatewayBase}/leaderboard/company/ranking`,
      { companyName },
    );
    if (response.data.company) {
      response.data.company.highlight = true;
      response.data.company.addedTop = true;
      for (let i: number = 0; i < state.companies.length; i += 1) {
        const comp: any = state.companies[i];
        if (comp.addedTop === undefined) {
          if (i > 0) {
            if (state.companies[i - 1].rank === response.data.company.rank) {
              response.data.company.rank += 1;
            }
          }
          state.companies.splice(i, 0, response.data.company);
          break;
        }
        if (comp.rank > response.data.company.rank) {
          state.companies.splice(i, 0, response.data.company);
          break;
        }
      }
    }
  },
  async [Actions.GetCompanyUsers]({ /* state, */ commit }, companyName: string) {
    const response = await this.$axios.post(`${(this.$axios.defaults as any).gatewayBase}/leaderboard/company/users`, {
      companyName,
    });
    if (response.data.users) {
      commit(Mutations.SetCompanyUsers, response.data.users);
    }
  },
  async [Actions.GetCurrentSchool]({ state }, schoolName: string) {
    const response = await this.$axios.post(
      `${(this.$axios.defaults as any).gatewayBase}/leaderboard/education/ranking`,
      { schoolName },
    );
    if (response.data.school) {
      response.data.school.highlight = true;
      response.data.school.addedTop = true;
      for (let i: number = 0; i < state.schools.length; i += 1) {
        const school: any = state.schools[i];
        if (school.addedTop === undefined) {
          if (i > 0) {
            if (state.schools[i - 1].rank === response.data.school.rank) {
              response.data.school.rank += 1;
            }
          }
          state.schools.splice(i, 0, response.data.school);
          break;
        }
        if (school.rank > response.data.school.rank) {
          state.schools.splice(i, 0, response.data.school);
          break;
        }
      }
    }
  },

  async [Actions.GetGlobalPosition](ctx, { score, username } = {}) {
    const response = await this.$axios.post('/scorestorage/scoreStorage/GetGlobalPosition', { score, username });
    return response.data.position;
  },

  async [Actions.GetGlobalPositionBySkill](ctx, { skill, score, username } = {}) {
    const response = await this.$axios.post('/scorestorage/scoreStorage/GetGlobalPositionBySkill', {
      skill,
      score,
      username,
    });
    return response.data.position;
  },

  async [Actions.GetCountryPosition](ctx, { location, score, username } = {}) {
    const response = await this.$axios.post('/scorestorage/scoreStorage/GetCountryPosition', {
      location,
      score,
      username,
    });
    return response.data.position;
  },

  async [Actions.GetCountryPositionBySkill](ctx, { location, skill, score, username } = {}) {
    const response = await this.$axios.post('/scorestorage/scoreStorage/GetCountryPositionBySkill', {
      location,
      skill,
      score,
      username,
    });
    return response.data.position;
  },

  async [Actions.GetCityPosition](ctx, { location, score, username } = {}) {
    const response = await this.$axios.post('/scorestorage/scoreStorage/GetCityPosition', {
      location,
      score,
      username,
    });
    return response.data.position;
  },

  async [Actions.GetCityPositionBySkill](ctx, { location, skill, score, username } = {}) {
    const response = await this.$axios.post('/scorestorage/scoreStorage/GetCityPositionBySkill', {
      location,
      skill,
      score,
      username,
    });
    return response.data.position;
  },
};

export const getters: GetterTree<LeaderboardState, RootState> = {
  [Getters.Developers](state) {
    return state.developers;
  },
  [Getters.DevelopersHasCurrentRecord](state) {
    return state.developersHasCurrentRecord;
  },
  [Getters.Companies](state) {
    return state.companies;
  },
  [Getters.Schools](state) {
    return state.schools;
  },
  [Getters.IsLoading](state) {
    return state.loading;
  },
  [Getters.HasCurrentRecord](state) {
    return state.hasCurrentRecord;
  },
  [Getters.UsersPerPage](state) {
    return state.usersPerPage;
  },
  [Getters.DevelopersPerPage](state) {
    return state.developersPerPage;
  },
  [Getters.AllPages](state) {
    return state.allPages;
  },
  [Getters.AllResults](state) {
    return state.allResults;
  },
  [Getters.CurrentPage](state) {
    return state.currentPage;
  },
  [Getters.CompanyUsers](state) {
    return state.companyUsers;
  },
  [Getters.Country](state) {
    return state.country;
  },
  [Getters.City](state) {
    return state.city;
  },
  [Getters.Technology](state) {
    return state.technology;
  },
};
