














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'opened',
    event: 'change',
  },
})
class CrCollapse extends Vue {
  @Prop({ type: Boolean }) opened: boolean;
  @Prop({ type: String, default: 'div' }) tag: string;
  @Prop({ type: [String, Number] }) id: string | number;

  state: string = this.opened ? 'opened' : 'closed';
  transition: boolean = false;

  @Watch('opened')
  onStateChange(isOpened: boolean, wasOpened: boolean) {
    if (isOpened === wasOpened || (!isOpened && !wasOpened)) return;
    if (wasOpened) {
      this.close(false);
    } else {
      this.open(false);
    }
  }

  mounted() {
    const el = this.$el as HTMLElement;
    el.addEventListener('transitionend', this.onTransitionEnd);
    if (!this.opened) el.style.display = 'none';
    this.$root.$emit('cr::collapse::state', this.id, this.state);
    this.$root.$on('cr::collapse::toggle', this.onRootToggle);
  }

  beforeDestroy() {
    this.$root.$off('cr::collapse::toggle', this.onRootToggle);
  }

  onRootToggle(id: string | number) {
    if (this.id && this.id === id) this.toggle();
  }

  onTransitionEnd(e: any) {
    const el = this.$el as HTMLElement;
    if (e.target !== el) return;
    el.classList.remove('cr-collapse-transition');
    this.transition = false;
    if (this.state === 'opened') {
      el.style.height = '';
      el.style.overflow = '';
      this.$emit('opened');
    } else {
      el.style.display = 'none';
      this.$emit('closed');
    }
  }

  toggle() {
    if (this.state === 'opened') {
      this.close();
    } else {
      this.open();
    }
  }

  open(emitModel = true) {
    if (this.state === 'opened') return;
    const el = this.$el as HTMLElement;
    this.state = 'opened';
    this.transition = true;
    el.classList.add('cr-collapse-transition');
    if (emitModel) this.$emit('change', true);
    this.$emit('open');
    this.$root.$emit('cr::collapse::state', this.id, this.state);
    el.style.display = '';
    el.style.height = `${el.scrollHeight}px`;
    el.style.overflow = 'hidden';
  }

  close(emitModel = true) {
    if (this.state === 'closed') return;
    const el = this.$el as HTMLElement;
    this.state = 'closed';
    this.transition = true;
    el.classList.add('cr-collapse-transition');
    if (emitModel) this.$emit('change', false);
    this.$emit('close');
    this.$root.$emit('cr::collapse::state', this.id, this.state);
    el.style.height = `${el.getBoundingClientRect().height}px`;
    // @ts-ignore
    const reflow = el.offsetHeight; // eslint-disable-line
    el.style.height = '0px';
    el.style.overflow = 'hidden';
  }
}

export default CrCollapse;
