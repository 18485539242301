
























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

let openedModals = 0;

@Component({
  model: {
    prop: 'opened',
    event: 'change',
  },
  watch: {
    opened(nowOpened, wasOpened) {
      if (nowOpened && !wasOpened) {
        // @ts-ignore
        this.open();
      } else if (wasOpened && !nowOpened) {
        // @ts-ignore
        this.close();
      }
    },
  },
})
class CrModal extends Vue {
  @Prop({ default: true, type: Boolean }) header: boolean;
  @Prop({ default: true, type: Boolean }) headerCloseButton: boolean;
  @Prop({ type: Boolean }) footer: boolean;

  @Prop({ default: '' }) title: string;
  @Prop({ default: '' }) body: string;

  @Prop({ type: Boolean }) xs: boolean;
  @Prop({ type: Boolean }) sm: boolean;
  @Prop({ type: Boolean }) md: boolean;
  @Prop({ type: Boolean }) lg: boolean;
  @Prop({ type: Boolean }) xl: boolean;

  @Prop({ type: Boolean, default: true }) closeOnEsc: boolean;
  @Prop({ type: Boolean, default: true }) closeOnBackdrop: boolean;
  @Prop({ type: Boolean, default: false }) confirmToClose: boolean;
  @Prop({ type: String, default: 'Do you want to close this popup and discard changes?' })
  confirmToCloseText: boolean;

  /** For internal compatability with v-model */
  @Prop({ type: Boolean, default: true }) opened: boolean;

  isOpened = false;
  showConfirmModal = false;

  get size() {
    const { xl, lg, md, sm, xs } = this;

    if (xl) return 'xl';
    if (lg) return 'lg';
    if (md) return 'md';
    if (sm) return 'sm';
    if (xs) return 'xs';

    return 'md';
  }

  get hasHeader() {
    return this.header && (this.hasHeaderContent || this.headerCloseButton);
  }

  get hasHeaderContent() {
    return this.title || (this.$slots.header && this.$slots.header.length);
  }

  get hasFooter() {
    return (
      (this.$slots.footer && this.$slots.footer.length) ||
      (this.$slots['footer-left'] && this.$slots['footer-left'].length) ||
      (this.$slots['footer-right'] && this.$slots['footer-right'].length) ||
      this.footer
    );
  }

  mounted() {
    if (!this.opened) return;
    this.open();
  }

  destroyed() {
    if (this.isOpened) this.close();
  }

  onKeyDown(e: any) {
    const keyCode = e.keyCode;
    if (keyCode === 27 && this.closeOnEsc) {
      this.close({ escape: true });
    }
  }

  open() {
    openedModals += 1;
    if (openedModals === 1) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
    }
    document.body.appendChild(this.$el);
    document.addEventListener('keydown', this.onKeyDown);
    this.isOpened = true;
    this.$emit('open');
  }

  openConfirmModal() {
    this.showConfirmModal = true;
  }

  closeConfirmModal(closeMainModal: boolean) {
    this.showConfirmModal = false;
    if (closeMainModal) {
      this.$nextTick(() => {
        this.close();
      });
    }
  }

  close({ backdrop, button, escape } = {} as any) {
    if (!this.isOpened) return;
    if (!this.closeOnBackdrop && backdrop) {
      return;
    }
    if ((backdrop || button || escape) && this.confirmToClose) {
      this.openConfirmModal();
      return;
    }
    this.isOpened = false;
    openedModals -= 1;
    if (openedModals === 0) {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
    document.removeEventListener('keydown', this.onKeyDown);
    this.$emit('change', false);
    this.$emit('close');
  }
}
export default CrModal;
