




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'checked',
    event: 'change',
  },
})
class Toggle extends Vue {
  @Prop({ type: Boolean }) checked: boolean;
  /** Renders as `<label>` */
  @Prop({ type: Boolean }) label: boolean;
  @Prop({ type: Boolean }) disabled: boolean;
  /** Input name, `string` or `number` */
  @Prop() name: string | number;
  /** If enabled will render child `<input:checkbox>` */
  @Prop({ type: Boolean }) input: boolean;
  /** `string` or `number` */
  @Prop() inputId: string | number;
  /** `string` or `number` */
  @Prop() value: string | number;
  @Prop() color: string;

  onChange(e: InputEvent) {
    // @ts-ignore
    this.$emit('change', e.target.checked);
  }

  onClick(e: InputEvent) {
    this.$emit('click', e);
    if (!this.label || !this.input) {
      this.$emit('change', !this.checked);
    }
  }
}
export default Toggle;
