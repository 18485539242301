import axios from 'axios';

axios.defaults.baseURL = process.env.API_BASE_PATH ? process.env.API_BASE_PATH : 'https://api.codersrank.io/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
(axios.defaults as any).gatewayBase = process.env.GATEWAY_BASE_PATH;

// eslint-disable-next-line
export default function ({ $axios }: any) {
  $axios.defaults.baseURL = process.env.API_BASE_PATH ? process.env.API_BASE_PATH : 'https://api.codersrank.io/';
  $axios.defaults.headers.post['Content-Type'] = 'application/json';
  ($axios.defaults as any).gatewayBase = process.env.GATEWAY_BASE_PATH;
}
