export const cannyIdentify = (account: any) => {
  // @ts-ignore
  if (!window.Canny) return;
  const { userId, username, firstName, lastName, email, avatar } = account;

  // Name to "firstName lastName (username)"
  let name = [firstName, lastName].filter((el) => !!el.trim()).join(' ');
  if (!name) name = username;
  else name = `${name} (${username})`;

  // @ts-ignore
  window.Canny('identify', {
    appID: '5ba29d7ac80f75116ae0f920',
    user: {
      // Replace these values with the current user's data
      id: userId,
      name,
      email,

      // These fields are optional, but recommended:
      avatarURL: avatar,
    },
  });
};
