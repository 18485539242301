<template>
  <div id="app" class="app-empty jobboarding">
    <section>
      <div class="jobboarding-container">
        <div class="jobboarding-header">
          <logo full />
        </div>
      </div>
    </section>
    <nuxt />
    <portal-target name="root" />
    <style>
      #beamerSelector,
      #beamerOverlay {
        display: none !important;
      }
    </style>
  </div>
</template>
<script>
  import Logo from '../components/shared/Logo.vue';

  export default {
    components: {
      Logo,
    },
  };
</script>
