/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
/* eslint-disable no-self-assign */
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { RootState } from './index';
import prefixStoreEnums from '../utils/prefix-store-enums';
import { responseError } from '../utils/response-error';

enum Mutations {
  ErrorModalOpen = '[private repo] error modal open',
  ErrorModalClose = '[private repo] error modal close',

  OpenModal = '[private repo] open modal',
  CloseModal = '[private repo] close modal',

  AddRepoList = '[private repo] add repo list',
  AddSingleRepo = '[private repo] add single repo',
  SetProgress = '[private repo] set percentage',

  DeleteSingleRepo = '[private repo] delete single repo',

  FinishFileUpload = '[private repo] remove if the file is uploaded',
}

enum Actions {
  AssignRepos = '[provate repo] assign poll jobs',
  QueryRepoList = '[private repo] query repo list',
  UploadFile = '[private repo] upload file',
  DeleteRepo = '[private repo] delete repo',
}

enum Getters {
  ShouldShowCalculateButton = '[private repo] should show calculate button',
}

export const privaterepo = {
  mutations: prefixStoreEnums('privaterepo', Mutations),
  actions: prefixStoreEnums('privaterepo', Actions),
  getters: prefixStoreEnums('privaterepo', Getters),
};

const newpolljob = (commit: any, reponame: string, sessionId: string) => () =>
  setInterval(() => {
    axios
      .post(`${(axios.defaults as any).gatewayBase}/candidate/privaterepo/FileChangesPollProgress`, {
        sessionId,
        reponame,
      })
      .then((res) => {
        commit(Mutations.SetProgress, { percentage: res.data.percentage, reponame });
      })
      .catch((res) => {
        console.log(res);
        // We don't show error to user because
        // 1) there is nothing user can do
        // 2) there might be an error due to concurrency at backend but it will be fine later
        // commit(Mutations.ErrorModalOpen, (res.data) ? res.data : "Request failed")
      });
  }, 1000);

interface PrivateRepoState {
  errorModalOpen: boolean;
  error: string;
  modalOpen: boolean;
  shouldRefreshProfileOnModalClose: boolean;
  isRepoListLoading: boolean;
  score: number;
  repos: any[];
}

const initialState: PrivateRepoState = {
  errorModalOpen: false,
  error: '',
  modalOpen: false,
  shouldRefreshProfileOnModalClose: false,
  isRepoListLoading: true,
  score: 0,
  repos: [],
};

export const state = () => ({ ...initialState });

export const mutations: MutationTree<PrivateRepoState> = {
  [Mutations.ErrorModalOpen](state, payload: string) {
    state.error = payload;
    state.errorModalOpen = true;
  },
  [Mutations.ErrorModalClose](state) {
    state.error = '';
    state.errorModalOpen = false;
  },

  [Mutations.OpenModal](state) {
    state.modalOpen = true;
  },
  [Mutations.CloseModal](state) {
    state.modalOpen = false;
  },

  [Mutations.AddRepoList](state, payload: any) {
    if (!payload.repositories) return;
    state.repos.forEach((repo: any) => {
      if (repo.isUpdating && repo.polljob) clearInterval(repo.polljob);
    });
    state.repos = payload.repositories.sort(
      (a: any, b: any) => new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime(),
    );
    state.score = payload.totalscore;
  },
  [Mutations.AddSingleRepo](state, payload: any) {
    const index = state.repos.findIndex((item: any) => item.name === payload.name);
    if (index > -1) {
      state.repos[index].isUpdating = true;
      state.repos[index].progress = 0;
      state.repos[index].polljob = payload.polljob();
      state.repos[index].calculatedScoreFromIt = false;
      state.repos = state.repos;
    } else {
      payload.polljob = payload.polljob();
      payload.test = payload.test;
      state.repos.unshift(payload);
      state.repos = state.repos;
    }
  },
  [Mutations.SetProgress](state, { reponame, percentage }: any) {
    state.repos = state.repos.map((item: any) => {
      if (item.name === reponame) {
        item.progress = percentage;

        if (percentage === 100) {
          item.isUpdating = false;
          const now = new Date();
          item.modifiedAt = `${now.getFullYear()}-${(now.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now
            .getHours()
            .toString()
            .padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
          clearInterval(item.polljob);
        }
      }
      return item;
    });
  },
  [Mutations.DeleteSingleRepo](state, reponame: any) {
    state.repos = state.repos.filter((item: { name: any; polljob: any }) => {
      if (item.name === reponame) {
        clearInterval(item.polljob);
        return false;
      }
      return true;
    });
    state.shouldRefreshProfileOnModalClose = true;
  },
  [Mutations.FinishFileUpload](state) {
    state.repos.shift();
    state.repos = state.repos;
  },
};

export const actions: ActionTree<PrivateRepoState, RootState> = {
  [Actions.AssignRepos]({ commit, state, rootGetters }) {
    const repositories = rootGetters.privateProfileSources;
    commit(Mutations.AddRepoList, {
      repositories: (repositories || []).filter((r: any) => r.provider === 'manually_added'),
    });
    (state.repos || []).forEach((repo, index) => {
      if (state.repos[index].isUpdating) {
        state.repos[index].polljob = newpolljob(commit, state.repos[index].name, rootGetters.sessionId)();
      }
    });
    state.repos = state.repos;
  },
  [Actions.QueryRepoList]({ commit, state, rootGetters }) {
    if (!state.isRepoListLoading) return; // If we already have the data don't query it twice
    // eslint-disable-next-line
    return axios
      .post(`${(axios.defaults as any).gatewayBase}/candidate/privaterepo/GetFileChangesRepos`, {
        sessionId: rootGetters.sessionId,
      })
      .then((res) => {
        commit(Mutations.AddRepoList, {
          ...res.data,
          repositories: (res.data.repositories || []).filter((r: any) => r.provider === 'manually_added'),
        });
        (state.repos || []).forEach((repo, index) => {
          if (state.repos[index].isUpdating) {
            state.repos[index].polljob = newpolljob(commit, state.repos[index].name, rootGetters.sessionId)();
          }
        });
        state.repos = state.repos;
      })
      .catch((err) => {
        commit(Mutations.ErrorModalOpen, responseError(err, 'Request failed'));
      });
  },

  [Actions.UploadFile]({ commit, rootGetters }: any, file: File) {
    const form = new FormData();
    form.append('file', file);
    form.set('sessionId', rootGetters.sessionId);
    commit(Mutations.AddSingleRepo, {
      isUploading: true,
      isUpdating: true,
      progress: 0,
      name: 'Uploading file...',
      emails: [],
      polljob: () => {},
    });
    return axios
      .post(`${(axios.defaults as any).gatewayBase}/candidate/privaterepo/UploadFileChanges`, form, {
        timeout: 240000,
      })
      .then((data) => {
        commit(Mutations.FinishFileUpload);
        commit(Mutations.AddSingleRepo, {
          isUploading: false,
          isUpdating: true,
          progress: 0,
          name: data.data.reponame,
          emails: data.data.emails,
          polljob: newpolljob(commit, data.data.reponame, rootGetters.sessionId),
        });
      })
      .catch((err) => {
        commit(Mutations.ErrorModalOpen, responseError(err, 'Request failed'));
        commit(Mutations.FinishFileUpload);
      });
  },

  [Actions.DeleteRepo]({ commit, rootGetters }: any, reponame: any) {
    commit(Mutations.DeleteSingleRepo, reponame);
    return axios
      .post(
        `${(axios.defaults as any).gatewayBase}/candidate/privaterepo/DeleteFileChanges`,
        {
          reponame,
        },
        {
          headers: {
            'X-SESSION-ID': rootGetters.sessionId,
          },
        },
      )
      .catch((err) => {
        commit(Mutations.ErrorModalOpen, responseError(err, 'Request failed'));
      });
  },
};

export const getters: GetterTree<PrivateRepoState, RootState> = {
  [Getters.ShouldShowCalculateButton](state) {
    let isThereRepoWithoutCalculatedScore = false;
    let isAllFinished = true;

    state.repos.forEach((repo: any) => {
      if (!repo.calculatedScoreFromIt) isThereRepoWithoutCalculatedScore = true;
      if (repo.isUpdating) isAllFinished = false;
    });

    return { show: isThereRepoWithoutCalculatedScore && isAllFinished, finished: isAllFinished };
  },
};
