




import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'errorMessage',
    event: 'change',
  },
})
class CrError extends Vue {
  @Prop({ default: '' }) errorMessage: string;
  @Prop() errorTitle: string;

  onClose() {
    this.$emit('change', '');
    this.$emit('close');
  }
}
export default CrError;
