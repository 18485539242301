























































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Cookies from 'js-cookie';
import Logo from './Logo.vue';
import MainHeaderSearch from './MainHeaderSearch.vue';
import { messages } from '../../store/messages';
import Emoji from './Emoji.vue';

@Component({
  components: {
    Logo,
    Emoji,
    MainHeaderSearch,
  },
})
class MainHeader extends Vue {
  showModal = false;
  sessionId = Cookies.get('sessionId');
  menuOpened = false;
  searchOpened = false;
  resourcesDropdownOpened = false;
  userDropdownOpened = false;
  leaderboardsDropdownOpened = false;
  darkModeDropdownOpened = false;

  get isLoggedIn() {
    return this.$store.state.isLoggedIn;
  }

  get unseenThreadsCount() {
    return this.$store.state.messages.threadsCount.unseen;
  }

  get accountName() {
    const { fullName, firstName, lastName, username } = this.$store.state.account;
    if (fullName) return fullName;
    if (firstName && lastName) return `${firstName} ${lastName}`;
    return username;
  }

  get shouldShowHiddenInfo() {
    const { isLoggedIn, $route, $store } = this;
    return (
      isLoggedIn &&
      $store.state.account.hideProfile &&
      ((($route.name === 'user' ||
        $route.name === 'userAsOthersSee' ||
        $route.name === 'userInfo' ||
        $route.name === 'userInfoAsOthersSee') &&
        $store.state.profile.ownProfile) ||
        ($route.name && $route.name.includes('account')) ||
        $route.name === 'invite')
    );
  }

  mounted() {
    this.getMessagesNotifications();
    document.addEventListener('click', this.onDocumentClick);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  setColorScheme(value: any) {
    // @ts-ignore
    window.setColorScheme(value);
    this.darkModeDropdownOpened = false;
  }

  onDocumentClick(e: any) {
    if (!this.userDropdownOpened && !this.leaderboardsDropdownOpened && !this.resourcesDropdownOpened) return;
    const openedDropdownEl = this.$el.querySelector('.main-header-dropdown-opened');
    if (!openedDropdownEl) return;
    let targetEl = e.target;
    let targetElIsInEl = targetEl === openedDropdownEl;
    while (targetEl.parentNode && !targetElIsInEl) {
      targetEl = targetEl.parentNode;
      targetElIsInEl = targetEl === openedDropdownEl;
    }
    if (!targetElIsInEl) {
      if (this.userDropdownOpened && openedDropdownEl.classList.contains('main-header-dropdown-user'))
        this.userDropdownOpened = false;
      else if (this.leaderboardsDropdownOpened) this.leaderboardsDropdownOpened = false;
      else if (this.resourcesDropdownOpened) this.resourcesDropdownOpened = false;
    }
  }

  async getMessagesNotifications() {
    await this.$store.dispatch(messages.actions.GetThreadsCount);
  }

  logout() {
    this.$router.push({ name: 'logout' });
  }

  openProfileMenu() {
    this.menuOpened = true;
  }

  closeProfileMenu() {
    this.menuOpened = false;
  }

  onMenuClick(e: any) {
    if (!this.menuOpened) return;
    if (e.target.tagName === 'A' || e.target.tagName === 'BUTTON') {
      this.menuOpened = false;
    }
  }

  onInviteLinkClick() {
    this.userDropdownOpened = false;
    this.$ga.event('invitationFeature', 'topMenuLinkClick');
  }
}

export default MainHeader;
