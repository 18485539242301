

















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { phoneCountryCodes } from '../utils/phone-country-codes';

@Component({
  model: {
    prop: 'value',
    event: 'change',
  },
})
class CrTelInput extends Vue {
  @Prop({ type: String, default: 'Phone number' }) placeholder: string;

  @Prop({ type: Boolean }) disabled: boolean;
  @Prop({ default: null }) state: boolean | null;
  @Prop({ type: String }) name: string;
  @Prop({ type: String }) ariaLabel: string;

  @Prop({ type: Boolean }) lg: boolean;
  @Prop({ type: Boolean }) md: boolean;
  @Prop({ type: Boolean }) sm: boolean;
  @Prop({ type: Boolean }) xs: boolean;

  @Prop({ type: String }) value: string;

  countryAndCodeValue = this.countryCodeFromString(this.value || '') || ['United States', '1', 0];
  inputValue = this.removeCountryCodeFromString(this.value || '');
  phoneCountryCodes = phoneCountryCodes;

  get size() {
    const { lg, md, sm, xs } = this;

    if (lg) return 'lg';
    if (md) return 'md';
    if (sm) return 'sm';
    if (xs) return 'xs';

    return 'md';
  }

  get color() {
    const { state } = this;

    if (state === true) return 'green';
    if (state === false) return 'red';
    return 'blue';
  }

  get computedValue() {
    let v;
    const { inputValue, countryAndCodeValue } = this;
    if (!inputValue || inputValue === `+${countryAndCodeValue[1]}` || inputValue === '+') {
      v = '';
    } else if (inputValue.indexOf('+') === 0) {
      v = inputValue;
    } else {
      v = `+${countryAndCodeValue[1]}${inputValue}`;
    }
    return v;
  }

  @Watch('value')
  onValueChanged() {
    const newValue = this.value;
    const computedValue = this.computedValue;
    if (newValue === computedValue) return;
    this.inputValue = this.removeCountryCodeFromString(newValue || '');
    this.countryAndCodeValue = this.countryCodeFromString(newValue || '') || ['United States', '1', 0];
  }

  // ['United States (something else)', '1', 0] -> 'United States'
  countryNameFromPhoneCode(phoneCodeValue: string[]): string {
    return phoneCodeValue[0].split('(')[0].trim();
  }

  // '+79081234567' -> '9081234567'
  removeCountryCodeFromString(string: string): string {
    const countryAndCodeValue = this.countryCodeFromString(string || '') || ['United States', '1', 0];
    const code = countryAndCodeValue[1];
    if (string.indexOf(`+${code}`) === 0) {
      return string.replace(`+${code}`, '');
    }
    return string;
  }

  // '+79081234567' -> ['Russia', '7']
  countryCodeFromString(inputValue: string, update?: boolean): (string | number)[] | undefined {
    if (!inputValue || inputValue[0] !== '+') return undefined;
    inputValue = inputValue.replace(/[^0-9+]/gi, '').trim();
    const match = phoneCountryCodes.filter((countryAndCode) => {
      const countryCode = `+${countryAndCode[1]}`;
      return countryCode.indexOf(inputValue) === 0 || inputValue.indexOf(countryCode) === 0;
    });
    let found;
    if (match.length > 0) {
      match.sort((a: any[], b: any[]) => {
        return a[2] - b[2];
      });
      found = match[0];
      if (update && found[1] !== this.countryAndCodeValue[1]) {
        this.countryAndCodeValue = match[0];
      }
    }
    return found;
  }

  onSelectChange(phoneCode: string[]) {
    const oldCode = this.countryAndCodeValue[1];
    this.countryAndCodeValue = phoneCode;
    if (this.inputValue && this.inputValue.indexOf(`+${oldCode}`) === 0) {
      this.inputValue = this.inputValue.replace(`+${oldCode}`, `+${this.countryAndCodeValue[1]}`);
      this.$emit('change', this.computedValue);
    } else if (oldCode !== phoneCode[1]) {
      this.$emit('change', this.computedValue);
    }
  }

  onInput(value: any) {
    // @ts-ignore
    // eslint-disable-next-line
    this.inputValue = value.replace(/[^0-9+]/gi, '');
    // @ts-ignore
    this.$refs.input.$el.value = this.inputValue;
    if (this.inputValue && this.inputValue[0] === '+' && this.inputValue.length > 1) {
      this.countryCodeFromString(this.inputValue, true);
    }
    this.$emit('change', this.computedValue);
  }
}
export default CrTelInput;
