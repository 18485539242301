
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class CrTag extends Vue {
  @Prop({ type: String, default: '' }) text: string;
  @Prop({ type: Boolean, default: false }) removeButton: boolean;
  @Prop({ type: String }) color: string;
  @Prop({ type: Boolean, default: false }) highlighted: boolean;

  remove() {
    this.$emit('remove');
  }
}

export default CrTag;
