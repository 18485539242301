<template>
  <div id="app" class="app-default">
    <MainHeader />
    <nuxt />
    <portal-target name="root" />
    <profile-badge-earned-modal />
    <refresh-notification />
  </div>
</template>
<script>
  import MainHeader from '../components/shared/MainHeader.vue';
  import RefreshNotification from '../components/shared/RefreshNotification.vue';
  import ProfileBadgeEarnedModal from '../components/profile/ProfileBadgeEarnedModal.vue';

  export default {
    components: {
      MainHeader,
      RefreshNotification,
      ProfileBadgeEarnedModal,
    },
  };
</script>
