















































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'value',
    event: 'input',
  },
  data() {
    return {
      // @ts-ignore
      selectValue: this.value,
    };
  },
})
class CrInput extends Vue {
  @Prop() value: any;

  @Prop({ type: Boolean }) lg: boolean;
  @Prop({ type: Boolean }) md: boolean;
  @Prop({ type: Boolean }) sm: boolean;
  @Prop({ type: Boolean }) xs: boolean;

  @Prop({ type: String }) autocomplete: string;

  @Prop({ type: Boolean }) disabled: boolean;
  @Prop({ type: Boolean }) icon: boolean;

  @Prop({ default: null }) state: boolean | null;

  @Prop({ type: String, default: 'text' }) type: string;
  @Prop({ type: String }) name: string;
  @Prop({ type: String }) placeholder: string;

  @Prop({ type: String }) ariaLabel: string;

  focused = false;
  contentEditableValue = '';

  @Watch('value')
  onValueChange(newValue: string, oldValue: string) {
    if (this.type === 'contenteditable') {
      if (!newValue && oldValue) {
        this.clearContentEditable(!this.focused);
      }
    }
    if (this.type === 'select') {
      // @ts-ignore
      this.selectValue = this.value;
    }
  }

  mounted() {
    if (this.type === 'contenteditable') {
      // @ts-ignore
      this.$refs.contenteditable.innerHTML = this.value || '';
    }
  }

  get size() {
    const { lg, md, sm, xs } = this;

    if (lg) return 'lg';
    if (md) return 'md';
    if (sm) return 'sm';
    if (xs) return 'xs';

    return 'md';
  }

  get color() {
    const { state } = this;

    if (state === true) return 'green';
    if (state === false) return 'red';
    return 'blue';
  }

  clearContentEditable(addPlaceholder: boolean = false) {
    // @ts-ignore
    this.$refs.contenteditable.innerHTML = addPlaceholder
      ? `<div class="cr-input-placeholder">${this.placeholder}</div>`
      : '';
  }

  onFocus(e: any) {
    this.focused = true;
    if (this.type === 'contenteditable' && !this.value) {
      this.clearContentEditable();
    }
    this.$emit('focus', e);
  }

  onBlur(e: any) {
    this.focused = false;
    if (this.type === 'contenteditable' && !this.value) {
      this.clearContentEditable(true);
    }
    this.$emit('blur', e);
  }

  onInput(e: any) {
    if (this.type === 'contenteditable') {
      this.$emit('input', e.target.innerHTML);
    } else {
      this.$emit('input', e.target.value);
    }
  }

  onChange(e: any) {
    this.$emit('change', e);
  }

  onPaste(e: any) {
    const text = e.clipboardData.getData('text/plain');
    e.preventDefault();
    document.execCommand('insertText', false, text);
  }
}

export default CrInput;
