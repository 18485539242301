interface NotificationParameters {
  /** Notification element */
  el?: HTMLElement;
  /** Notification text */
  text?: string;
  /** Hold notification duration in ms (default 5000) */
  hold?: number;
  /** Close other (default false) */
  closeOtherNotifications?: boolean;
  /** Render close button (default: true) */
  closeButton?: boolean;
  /** Can't be removed by other notifcations if fixed */
  fixed?: boolean;
  /** On open callback */
  onOpen?: () => void;
  /** On close callback */
  onClose?: () => void;
}

const defaultParameters: NotificationParameters = {
  text: '',
  hold: 5000,
  closeButton: true,
};

function closeNotification(notificationEl: HTMLElement) {
  if (!notificationEl) return;
  if (notificationEl.classList.contains('cr-notification-out')) return;

  // Find next opened notifications and move them down
  const nextNotifications: HTMLElement[] = [];
  let nextElementSibling = <HTMLElement>notificationEl.nextElementSibling;
  while (nextElementSibling) {
    if (nextElementSibling.classList.contains('cr-notification')) {
      nextNotifications.push(nextElementSibling);
    }
    nextElementSibling = <HTMLElement>nextElementSibling.nextElementSibling;
  }
  if (nextNotifications.length) {
    const { offsetHeight } = notificationEl;
    nextNotifications.forEach((nextEl) => {
      // @ts-ignore
      nextEl.style.marginBottom = `${parseInt(nextEl.style.marginBottom, 10) - offsetHeight - 5}px`;
    });
  }

  // Close current notification
  notificationEl.classList.add('cr-notification-out');
  notificationEl.addEventListener('animationend', () => {
    // @ts-ignore
    notificationEl.parentNode.removeChild(notificationEl);
    // @ts-ignore
    if (notificationEl.crNotificationOnClose) {
      // @ts-ignore
      notificationEl.crNotificationOnClose();
      // @ts-ignore
      delete notificationEl.crNotificationOnClose;
    }
  });
}

function openNotification(notificationEl: HTMLElement, onOpen: any, onClose: any) {
  if (!notificationEl) return;

  // @ts-ignore
  const otherNotifications: HTMLElement[] = Array.from(
    document.querySelectorAll('#app > .cr-notification, body > .cr-notification'),
  ).filter((el) => el !== notificationEl);

  // Position new notification on top of previous one
  if (otherNotifications.length) {
    let previousNotificationsHeight = 0;
    otherNotifications
      .filter((el) => !el.classList.contains('cr-notification-out'))
      .forEach((el) => {
        previousNotificationsHeight += el.offsetHeight + 5;
      });

    notificationEl.style.marginBottom = `${previousNotificationsHeight}px`;
  }

  let rootEl = document.getElementById('app');
  if (!rootEl) {
    rootEl = document.body;
  }
  rootEl.appendChild(notificationEl);

  if (onOpen) onOpen();
  if (onClose) {
    // @ts-ignore
    notificationEl.crNotificationOnClose = onClose;
  }

  // Max 5 notifications opened
  if (otherNotifications.length >= 5) {
    for (let i = 0; i < otherNotifications.length - 4; i += 1) {
      if (!otherNotifications[i].classList.contains('cr-notification-fixed')) {
        closeNotification(otherNotifications[i]);
      }
    }
  }
}

export default function notification(parameters = {} as NotificationParameters) {
  const { el, text, hold, closeOtherNotifications, closeButton, onOpen, onClose } = {
    ...defaultParameters,
    ...parameters,
  };

  const innerHTML = `
    <div class="cr-notification-content">${text}</div>
    ${
      closeButton
        ? `
    <a href="#" class="cr-notification-close-button">
      <i class="fas fa-times"></i>
    </a>
    `
        : ''
    }
  `;

  // Close other notifications if required
  if (closeOtherNotifications) {
    const otherNotifications: HTMLElement[] = Array.from(
      document.querySelectorAll('#app > .cr-notification, body > .cr-notification'),
    );
    otherNotifications.forEach((otherEl) => closeNotification(otherEl));
  }

  // Create notification Element
  let notificationEl: HTMLElement;
  if (el) {
    notificationEl = el;
  } else {
    notificationEl = document.createElement('div');
    notificationEl.classList.add('cr-notification');
    notificationEl.innerHTML = innerHTML;
  }

  // Close button
  let closeButtonEl: HTMLElement;

  let holdTimeout: number;

  // On close click
  function handleCloseButtonClick(e: MouseEvent) {
    e.preventDefault();
    closeButtonEl.removeEventListener('click', handleCloseButtonClick);
    clearTimeout(holdTimeout);
    closeNotification(notificationEl);
  }
  if (hold && hold > 0) {
    holdTimeout = window.setTimeout(() => {
      closeNotification(notificationEl);
    }, hold);
  }
  if (closeButton) {
    closeButtonEl = notificationEl.querySelector('.cr-notification-close-button') as HTMLElement;
    closeButtonEl.addEventListener('click', handleCloseButtonClick);
  }

  openNotification(notificationEl, onOpen, onClose);

  // Return notification object
  return {
    el: notificationEl,
    close: () => closeNotification(notificationEl),
  };
}
