import Vue from 'vue';

// Components
import CrCheckbox from './components/Checkbox.vue';
import CrRadio from './components/Radio.vue';
import CrButton from './components/Button.vue';
import CrToggle from './components/Toggle.vue';
import CrSpinner from './components/Spinner.vue';
import CrProgress from './components/Progress.vue';
import CrNotification from './components/Notification.vue';
import CrModal from './components/Modal.vue';
import CrError from './components/Error.vue';
import CrInput from './components/Input.vue';
import CrSelect from './components/Select.vue';
import CrCollapse from './components/Collapse.vue';
import CrEmojiFlag from './components/EmojiFlag.vue';
import CrTooltip from './components/Tooltip.vue';
import CrAreaChart from './components/AreaChart.vue';
import CrPieChart from './components/PieChart.vue';
import CrTelInput from './components/TelInput.vue';
import CrTag from './components/Tag.vue';

// Directives
import VCrCollapseToggle from './directives/CollapseToggle';
import VCrTooltip from './directives/Tooltip';

const components: any = {
  CrCheckbox,
  CrRadio,
  CrButton,
  CrToggle,
  CrSpinner,
  CrProgress,
  CrNotification,
  CrModal,
  CrError,
  CrInput,
  CrSelect,
  CrCollapse,
  CrEmojiFlag,
  CrTooltip,
  CrAreaChart,
  CrPieChart,
  CrTelInput,
  CrTag,
};

const directives: any = {
  CrCollapseToggle: VCrCollapseToggle,
  CrTooltip: VCrTooltip,
};

Object.keys(components).forEach((name) => {
  Vue.component(name, components[name]);
});

Object.keys(directives).forEach((name) => {
  Vue.directive(name, directives[name]);
});
